import axios from "axios";

class restApi {

    static getFunction = (url, token) => {
        return axios({
            method: 'GET',
            url: url,
            headers: {
                'Authorization': `token ${token}`
            }
        }).then(response => {
            return response.data;
        }).catch(error => {
            console.log(error);
        });
    }

    static putFunction = (url, data, token) => {
        return axios({
            method: 'PUT',
            url: url,
            data: data,
            headers: {
                'Authorization': `token ${token}`
            }
        }).then(response => {
            return response.data;
        }).catch(error => {
            console.log(error);
        });
    }

    static postFunction = (url, data, token) => {
        return axios({
            method: 'POST',
            url: url,
            data: data,
            headers: {
                'Authorization': `token ${token}`,
            }
        }).then(response => {
            return response.data;
        }).catch(error => {
            console.log(error);
        });
    }

}

export default restApi;