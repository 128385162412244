import React, {Fragment} from 'react';
import {PageHeader} from "antd";
import UpdateAddress from "./UpdateAddress";
import {useParams} from "react-router-dom";

function Address(props) {
    const {teacherDetails} = props;
    const {teacherID} = useParams();
    return (
        <Fragment>
            <div className="form-group">
                <div className="row">
                    <div className="col-md-10">
                        <PageHeader onBack={() => window.history.back()} title="Address Information"
                                    style={{padding: "10px 0px"}}/>
                    </div>
                    <div className="col-md-2">
                        <button
                            type="button"
                            className="btn btn-outline-success"
                            data-backdrop="static"
                            data-keyboard="false"
                            data-toggle="modal"
                            data-target="#update_address"
                            style={{width: "100%", padding: "5px 0px", marginTop: "7px"}}>
                            Edit
                        </button>
                    </div>
                </div>
            </div>

            <div className="mb-0">
                <div className="details-area">
                    <div className="pt-4">
                        <div className="single">
                            <span className="info-name">area</span>
                            <span className="info-desc"> {teacherDetails?.teacherDetails?.address?.area}</span>
                        </div>
                        <div className="single">
                            <span className="info-name">country</span>
                            <span className="info-desc">  {teacherDetails?.teacherDetails?.address?.country}</span>
                        </div>
                        <div className="single">
                            <span className="info-name">district</span>
                            <span
                                className="info-desc">{teacherDetails?.teacherDetails?.address?.district}</span>
                        </div>
                        <div className="single">
                            <span className="info-name">flatNo</span>
                            <span className="info-desc">{teacherDetails?.teacherDetails?.address?.flatNo}</span>
                        </div>
                        <div className="single">
                            <span className="info-name">houseNo</span>
                            <span className="info-desc"> {teacherDetails?.teacherDetails?.address?.houseNo}</span>
                        </div>
                        <div className="single">
                            <span className="info-name">postCode</span>
                            <span className="info-desc"> {teacherDetails?.teacherDetails?.address?.postCode}</span>
                        </div>
                        <div className="single">
                            <span className="info-name">latitude</span>
                            <span
                                className="info-desc"> {teacherDetails?.teacherDetails?.address?.location?.latitude}</span>
                        </div>
                        <div className="single">
                            <span className="info-name">longitude</span>
                            <span
                                className="info-desc"> {teacherDetails?.teacherDetails?.address?.location?.longitude}</span>
                        </div>
                        <div className="single">
                            <span className="info-name">roadNo</span>
                            <span className="info-desc"> {teacherDetails?.teacherDetails?.address?.roadNo}</span>
                        </div>
                        <div className="single">
                            <span className="info-name">thana</span>
                            <span className="info-desc"> {teacherDetails?.teacherDetails?.address?.thana}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="update_address">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Update Address Information</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <UpdateAddress id={teacherID}
                                               teacher={teacherDetails}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Address;