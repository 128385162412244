import React, {Fragment, useState} from 'react';
import RestApi from "../../api/restApi";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function AddTeacher(props) {
    const [fullName, setFullName] = useState();
    const [mobileNumber, setMobileNumber] = useState();
    const [status, setStatus] = useState();
    const [msg, setMsg] = useState("");

    const addPrograms = () => {
        let data = {
            fullName: fullName,
            mobileNumber: mobileNumber,
            status: status
        };
        let staticURL = AppURL + 'admin/course-quize';
        RestApi.postFunction(staticURL, data, token).then(result => {
            if (result.statusCode === 200) {
                setMsg("Teacher added successfully.");
                setInterval(function () {
                    window.location.replace('teacher');
                }, 2000);
            }
        });
    }

    return (
        <Fragment>
            <div className="container">
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Teacher Name:</strong></div>
                        <div className="col-md-5">
                            <input onChange={(e) => setFullName(e.target.value)} type="text"
                                   placeholder="Teacher name ...."
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Mobile Number:</strong></div>
                        <div className="col-md-5">
                            <input onChange={(e) => setMobileNumber(e.target.value)} type="text"
                                   placeholder="Mobile Number...."
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Status:</strong></div>
                        <div className="col-md-5">
                            <select className="form-control"
                                    onChange={(e) => setStatus(e.target.value)}>
                                <option value="">Select One</option>
                                <option value="true">Active</option>
                                <option value="false">Inactive</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"></div>
                        <div className="col-md-5">
                            <input type="submit" onClick={addPrograms} className="form-control btn btn-outline-success"
                                   value="Save"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-5">
                            <div className="text-center">
                                {msg ?
                                    <div className="alert alert-success alert-dismissable text-center">
                                        <button aria-hidden="true" data-dismiss="alert" className="close"
                                                type="button">&times;</button>
                                        {msg}
                                    </div> : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default AddTeacher;