import React, {Fragment, useEffect, useState} from 'react';
import RestApi from "../../api/restApi";
let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function UpdateChapter(props) {
    const [description, setDescription] = useState();
    const [courseId, setCourseId] = useState();
    const [name, setName] = useState();
    const [isActive, setIsActive] = useState();
    const [msg, setMsg] = useState();
    const {course, chapter} = props;

    useEffect(() => {
        setDescription(chapter.description);
        setName(chapter.name);
        setCourseId(chapter.courseId);
        setIsActive(chapter.isActive);
    }, [chapter.description, chapter.name, chapter.courseId, chapter.isActive]);

    const UpdateChapters = () => {
        let id = props.id;
        let data = {id: id, courseId: courseId, name: name, description: description, isActive: isActive};
        let staticURL = AppURL + 'admin/chapter';
        RestApi.putFunction(staticURL, data, token).then(result => {
            if (result.statusCode === 200) {
                setMsg("Chapter updated successfully.");
                setInterval(function () {
                    window.location.replace('chapter');
                }, 2000);
            }
        });
    }

    return (
        <Fragment>
            <div className="container">
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Course Name:</strong></div>
                        <div className="col-md-5">
                            <select value={courseId} onChange={(e) => setCourseId(e.target.value)}
                                    className="form-control">
                                <option value="">Select One</option>
                                {course?.courses?.map((v_courses, index) => (
                                    <option key={index} value={v_courses.id}>{v_courses.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Name:</strong></div>
                        <div className="col-md-5">
                            <input type="text" defaultValue={name} onChange={(e) => setName(e.target.value)}
                                   placeholder="Name ...."
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Description:</strong></div>
                        <div className="col-md-5">
                            <input type="text"
                                   defaultValue={description}
                                   onChange={(e) => setDescription(e.target.value)}
                                   placeholder="Description ...."
                                   className="form-control"/>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Is Active:</strong></div>
                        <div className="col-md-5">
                            <select value={isActive} onChange={(e) => setIsActive(e.target.value)}
                                    className="form-control">
                                <option value="">Select One</option>
                                <option value="true">True</option>
                                <option value="false">False</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"></div>
                        <div className="col-md-5">
                            <input type="submit" onClick={UpdateChapters}
                                   className="form-control btn btn-outline-success"
                                   value="Save Change"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-5">
                            <div className="text-center">
                                {msg ?
                                    <div className="alert alert-success alert-dismissable text-center">
                                        <button aria-hidden="true" data-dismiss="alert" className="close"
                                                type="button">&times;</button>
                                        {msg}
                                    </div> : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default UpdateChapter;