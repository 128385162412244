import React, {Fragment, useEffect, useState} from 'react';
import '../../../App.css';
import {PageHeader} from "antd";
import {useParams} from "react-router-dom";
import RestApi from "../../../api/restApi";
import ReactPaginate from "react-paginate";
import UploadCourseContent from "../../Course/UploadCourseContent";
import UpdateCourse from "../../Course/UpdateCourse";
import AddCourse from "../../Course/AddCourse";
import CourseDetails from "./CourseDetails";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function CourseManagement(props) {
    const [childCurriculum, setChildCurriculum] = useState();
    const [childDiscipline, setChildDiscipline] = useState();
    const [childProgram, setChildProgram] = useState();
    const [childTeacher, setChildTeacher] = useState();
    const [viewAllCourses, setViewAllCourses] = useState();
    const [courseID, setCourseID] = useState();
    const [editAllCourses, setEditAllCourses] = useState();
    const [id, setId] = useState();
    let {programID} = useParams();

    useEffect(() => {
        let staticURL = AppURL + 'admin/course?programId=' + programID;
        RestApi.getFunction(staticURL, token).then(result => {
            setViewAllCourses(result);
            console.log(result);
        });
    }, [programID]);

    const contentUpload = (props) => {
        setCourseID(props);
    }

    useEffect(() => {
        let staticURL = AppURL + 'admin/curriculum';
        RestApi.getFunction(staticURL, token).then(result => {
            setChildCurriculum(result);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + 'admin/discipline';
        RestApi.getFunction(staticURL, token).then(result => {
            setChildDiscipline(result);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + 'program';
        RestApi.getFunction(staticURL, token).then(result => {
            setChildProgram(result);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + 'teacher';
        RestApi.getFunction(staticURL, token).then(result => {
            setChildTeacher(result);
        });
    }, []);

    const getId = (props) => {
        setId(props);
        let staticURL = AppURL + 'admin/course/details?courseId=' + props;
        RestApi.getFunction(staticURL, token).then(result => {
            setEditAllCourses(result.course);
        });
    }

    const courseDetails = (props) => {
        setId(props);
    }

    const [pageNumber, setPageNumber] = useState(0);
    const usersPerPage = 5;
    const pagesVisited = pageNumber * usersPerPage;
    const view_courses = viewAllCourses?.courses?.slice(pagesVisited, pagesVisited + usersPerPage);
    const displayCourses = view_courses?.map((v_courses, index) => {
        return (
            <tr key={index}>
                <td>{index + 1}</td>
                <td>
                    <span style={{fontSize: 10, marginLeft: 10, cursor: 'pointer'}}
                          data-backdrop="static"
                          data-keyboard="false" data-toggle="modal"
                          data-target="#course_details"
                          onClick={() => courseDetails(v_courses.id)}
                          title="Course Details">
                        {v_courses.name?.substring(0, 20)}
                    </span>
                </td>
                <td>{v_courses.price?.mrp}</td>
                <td>{v_courses.price?.tp}</td>
                <td>{v_courses.price?.discountedPrice}</td>
                <td>{v_courses?.numberOfEnrolled}</td>
                <td>{v_courses.isActive ? "Active" : "Inactive"}</td>
                <td style={{width: '260px'}}>
                    <button style={{fontSize: 10, marginLeft: 10}}
                            className="btn btn-outline-success"
                            data-backdrop="static"
                            data-keyboard="false" data-toggle="modal"
                            data-target="#update_course"
                            onClick={() => getId(v_courses.id)}
                            title="Update emp basic info">
                        <i className="fas fa-edit"></i>
                    </button>
                    <span
                        style={{
                            fontSize: 10,
                            marginLeft: 10,
                            cursor: 'pointer'
                        }}
                        className="btn btn-outline-success"
                        data-backdrop="static"
                        data-keyboard="false" data-toggle="modal"
                        data-target="#content_modal"
                        onClick={() => contentUpload(v_courses.id)}
                        title="Video upload">
                                                            Content Upload
                                                        </span>
                </td>
            </tr>
        );
    });

    const pageCount = Math.ceil(viewAllCourses?.courses?.length / usersPerPage);
    const changePage = ({selected}) => {
        setPageNumber(selected);
    };

    return (
        <Fragment>
            <div className="form-group">
                <div className="row">
                    <div className="col-md-10">
                        <PageHeader onBack={() => window.history.back()} title="Course Management"
                                    style={{padding: "10px 0px"}}/>
                    </div>
                    <div className="col-md-2">
                        <button
                            type="button"
                            className="btn btn-outline-success"
                            data-backdrop="static"
                            data-keyboard="false"
                            data-toggle="modal"
                            data-target="#add_course"
                            style={{width: "100%", padding: "5px 0px", marginTop: "7px"}}>
                            <i className="fas fa-plus"></i> Course
                        </button>
                    </div>
                </div>
            </div>

            <div className="mb-0">
                <div className="details-area">
                    <div className="pt-4">
                        <table className="table table-hover">
                            <thead>
                            <tr>
                                <th>SL NO</th>
                                <th>Course Name</th>
                                <th>MRP Price</th>
                                <th>TP Price</th>
                                <th>Discounted Price</th>
                                <th>Total Enrolled Student</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {displayCourses}
                            </tbody>
                        </table>
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        />
                    </div>
                </div>
            </div>
            <div className="modal fade" id="add_course">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Add Course</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <AddCourse checkStatus={1} curriculum={childCurriculum} teacher={childTeacher}
                                           discipline={childDiscipline}
                                           program={childProgram}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="update_course">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Update Course</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <UpdateCourse checkStatus={1} id={id} teacher={childTeacher}
                                              curriculum={childCurriculum}
                                              discipline={childDiscipline}
                                              program={childProgram} course={editAllCourses}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="course_details">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Course Details</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <CourseDetails courseID={id}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="content_modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Upload Course Content</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <UploadCourseContent checkStatus={1} courseID={courseID}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default CourseManagement;