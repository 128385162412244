import React, {Fragment, useEffect, useState} from 'react';
import {Layout, Menu} from "antd";
import {useParams} from 'react-router-dom';
import {
    SnippetsOutlined,
} from "@ant-design/icons";
import RestApi from "../../api/restApi";
import TeacherInformation from "./content/TeacherInformation";
import Address from "./content/Address";
import Education from "./content/Education";
import Experience from "./content/Experience";
import Skill from "./content/Skill";
import CourseList from "./content/CourseList";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;


const {Sider, Content} = Layout;

function TabPanel(props) {
    const {children, value, index} = props;
    return (
        <div hidden={value != index}>
            {children}
        </div>
    );
}

function TeacherDetailsHome(props) {
    const {teacherID} = useParams();
    const [teacherDetails, setTeacherDetails] = useState();
    const [teacherCourse, setTeacherCourse] = useState();
    const [value, setValue] = useState(1);
    const testFunction = (props) => {
        setValue(props);
    };

    useEffect(() => {
        let staticURL = AppURL + "admin/teacher/details?id=" + teacherID;
        RestApi.getFunction(staticURL, token).then((result) => {
            setTeacherDetails(result.teacher);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + "admin/course?teacherId==" + teacherID + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then((result) => {
            setTeacherCourse(result);
        });
    }, []);


    return (
        <Fragment>
            <div className="content-wrapper">
                <div className="content-header"></div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <section className="col-lg-12">
                                <div className="">
                                    <Layout>
                                        <Content style={{padding: "0 0px"}}>
                                            <Layout
                                                className="site-layout-background"
                                                style={{padding: 0, height: "85vh"}}>
                                                <Sider
                                                    className="site-layout-background"
                                                    width={300}
                                                    style={{
                                                        backgroundColor: "white",
                                                        borderRight: "20px solid #f4f6f9",
                                                    }}>
                                                    <div className="details-layout course-background"></div>
                                                    <div className="b-background">
                                                        <div className="b-icon book-icon"></div>
                                                        <div className="b-body">
                                                            <h6 className="" style={{alignText: ""}}>Discipline Details
                                                            </h6>
                                                        </div>
                                                    </div>

                                                    <Menu mode="inline" defaultSelectedKeys={["1"]}
                                                          style={{backgroundColor: "white"}}>

                                                        <Menu.Item key="1" icon={<SnippetsOutlined/>} onClick={(e) => {
                                                            testFunction(e.key);
                                                        }}>
                                                            Basic Information
                                                        </Menu.Item>

                                                        <Menu.Item key="2" icon={<SnippetsOutlined/>} onClick={(e) => {
                                                            testFunction(e.key);
                                                        }}>
                                                            Address
                                                        </Menu.Item>

                                                        <Menu.Item key="3" icon={<SnippetsOutlined/>} onClick={(e) => {
                                                            testFunction(e.key);
                                                        }}>
                                                            Education
                                                        </Menu.Item>

                                                        <Menu.Item key="4" icon={<SnippetsOutlined/>} onClick={(e) => {
                                                            testFunction(e.key);
                                                        }}>
                                                            Experience
                                                        </Menu.Item>

                                                        <Menu.Item key="5" icon={<SnippetsOutlined/>} onClick={(e) => {
                                                            testFunction(e.key);
                                                        }}>
                                                            Skill
                                                        </Menu.Item>

                                                        <Menu.Item key="6" icon={<SnippetsOutlined/>} onClick={(e) => {
                                                            testFunction(e.key);
                                                        }}>
                                                            Course List
                                                        </Menu.Item>
                                                    </Menu>

                                                </Sider>

                                                <Content style={{
                                                    padding: "0 20px",
                                                    minHeight: 280,
                                                    backgroundColor: "white"
                                                }}>
                                                    <div className="site-layout-background"
                                                         style={{marginLeft: "15px", padding: 24, minHeight: "100%"}}>
                                                        <TabPanel value={value} index="1">
                                                            <TeacherInformation teacherDetails={teacherDetails}/>
                                                        </TabPanel>
                                                        <TabPanel value={value} index="2">
                                                            <Address teacherDetails={teacherDetails}/>
                                                        </TabPanel>
                                                        <TabPanel value={value} index="3">
                                                            <Education teacherDetails={teacherDetails}/>
                                                        </TabPanel>
                                                        <TabPanel value={value} index="4">
                                                            <Experience teacherDetails={teacherDetails}/>
                                                        </TabPanel>
                                                        <TabPanel value={value} index="5">
                                                            <Skill teacherDetails={teacherDetails}/>
                                                        </TabPanel>
                                                        <TabPanel value={value} index="6">
                                                            <CourseList teacherCourse={teacherCourse}/>
                                                        </TabPanel>
                                                    </div>
                                                </Content>
                                            </Layout>
                                        </Content>
                                    </Layout>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </div>
        </Fragment>
    );
}

export default TeacherDetailsHome;