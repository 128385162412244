import React, {Fragment, useEffect, useState} from 'react';
import RestApi from "../../../api/restApi";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function UpdateAddress(props) {
    const [area, setArea] = useState();
    const [country, setCountry] = useState();
    const [district, setDistrict] = useState();
    const [flatNo, setFlatNo] = useState();
    const [houseNo, setHouseNo] = useState();
    const [latitude, setLatitude] = useState();
    const [longitude, setLongitude] = useState();
    const [postCode, setPostCode] = useState();
    const [roadNo, setRoadNo] = useState();
    const [thana, setThana] = useState();
    const [msg, setMsg] = useState("");
    const {teacher} = props;
    let teacherAddress = teacher?.teacherDetails?.address;

    useEffect(() => {
        setArea(teacherAddress?.area);
        setCountry(teacherAddress?.country);
        setDistrict(teacherAddress?.district);
        setFlatNo(teacherAddress?.flatNo);
        setHouseNo(teacherAddress?.houseNo);
        setPostCode(teacherAddress?.postCode);
        setLatitude(teacherAddress?.location?.latitude);
        setLongitude(teacherAddress?.location?.longitude);
        setRoadNo(teacherAddress?.roadNo);
        setThana(teacherAddress?.thana);
    }, [teacherAddress?.area, teacherAddress?.country, teacherAddress?.district, teacherAddress?.flatNo, teacherAddress?.houseNo, teacherAddress?.postCode, teacherAddress?.location?.latitude, teacherAddress?.location?.longitude, teacherAddress?.roadNo, teacherAddress?.thana]);

    const updateTeacher = () => {
        let id = props.id;
        let staticURL = AppURL + 'admin/teacher';
        let data = {
            teacherDetails: {
                address: {
                    area: area,
                    country: country,
                    district: district,
                    flatNo: flatNo,
                    houseNo: houseNo,
                    location: {
                        latitude: latitude,
                        longitude: longitude
                    },
                    postCode: postCode,
                    roadNo: roadNo,
                    thana: thana
                }
            },
            id: id
        };
        RestApi.putFunction(staticURL, data, token).then(result => {
            if (result.statusCode === 200) {
                setMsg("Address updated successfully.");
            }
        });


    }

    return (
        <Fragment>
            <div className="form-group">
                <div className="row">
                    <div className="col-md-4 text-right h6"><strong>Area</strong></div>
                    <div className="col-md-5">
                        <input defaultValue={area}
                               onChange={(e) => setArea(e.target.value)}
                               type="text"
                               placeholder="Area"
                               className="form-control"/>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-md-4 text-right h6"><strong>Country</strong></div>
                    <div className="col-md-5">
                        <input defaultValue={country}
                               onChange={(e) => setCountry(e.target.value)}
                               type="text"
                               placeholder="Country"
                               className="form-control"/>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-md-4 text-right h6"><strong>District</strong></div>
                    <div className="col-md-5">
                        <input defaultValue={district}
                               onChange={(e) => setDistrict(e.target.value)}
                               type="text"
                               placeholder="District"
                               className="form-control"/>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-md-4 text-right h6"><strong>Thana</strong></div>
                    <div className="col-md-5">
                        <input defaultValue={thana}
                               onChange={(e) => setThana(e.target.value)}
                               type="text"
                               placeholder="Thana"
                               className="form-control"/>
                    </div>
                </div>
            </div>

            <div className="form-group">
                <div className="row">
                    <div className="col-md-4 text-right h6"><strong>Flat No</strong></div>
                    <div className="col-md-5">
                        <input defaultValue={flatNo}
                               onChange={(e) => setFlatNo(e.target.value)}
                               type="text"
                               placeholder="Flat No"
                               className="form-control"/>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-md-4 text-right h6"><strong>House No</strong></div>
                    <div className="col-md-5">
                        <input defaultValue={houseNo}
                               onChange={(e) => setHouseNo(e.target.value)}
                               type="text"
                               placeholder="House No"
                               className="form-control"/>
                    </div>
                </div>
            </div>


            <div className="form-group">
                <div className="row">
                    <div className="col-md-4 text-right h6"><strong>Latitude</strong></div>
                    <div className="col-md-5">
                        <input defaultValue={latitude}
                               onChange={(e) => setLatitude(e.target.value)}
                               type="text"
                               placeholder="Latitude"
                               className="form-control"/>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-md-4 text-right h6"><strong>Longitude</strong></div>
                    <div className="col-md-5">
                        <input defaultValue={longitude}
                               onChange={(e) => setLongitude(e.target.value)}
                               type="text"
                               placeholder="Longitude"
                               className="form-control"/>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-md-4 text-right h6"><strong>Post Code</strong></div>
                    <div className="col-md-5">
                        <input defaultValue={postCode}
                               onChange={(e) => setPostCode(e.target.value)}
                               type="text"
                               placeholder="Post Code"
                               className="form-control"/>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-md-4 text-right h6"><strong>roadNo</strong></div>
                    <div className="col-md-5">
                        <input defaultValue={roadNo}
                               onChange={(e) => setRoadNo(e.target.value)}
                               type="text"
                               placeholder="Road No"
                               className="form-control"/>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-md-4 text-right h6"></div>
                    <div className="col-md-5">
                        <input type="submit" onClick={updateTeacher}
                               className="form-control btn btn-outline-success"
                               value="Save Change"/>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-5">
                        <div className="text-center">
                            {msg ?
                                <div className="alert alert-success alert-dismissable text-center">
                                    <button aria-hidden="true" data-dismiss="alert" className="close"
                                            type="button">&times;</button>
                                    {msg}
                                </div> : ""
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default UpdateAddress;