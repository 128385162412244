import React, {Fragment, useState} from 'react';
import RestApi from "../../api/restApi";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function AddBook(props) {
    const [authorName, setAuthorName] = useState();
    const [bookGenre, setBookGenre] = useState();
    const [colorCode, setColorCode] = useState();
    const [description, setDescription] = useState();
    const [language, setLanguage] = useState();
    const [name, setName] = useState();
    const [numberOfDownloads, setNumberOfDownloads] = useState();
    const [numberOfPages, setNumberOfPages] = useState();
    const [price, setPrice] = useState();
    const [publicationDate, setPublicationDate] = useState();
    const [type, setType] = useState();
    const [bannerImageUrl, setBannerImageUrl] = useState();
    const [pdfFileUrl, setPdfFileUrl] = useState();
    const [title, setTitle] = useState();
    const [videoFileUrl, setVideoFileUrl] = useState();
    const [msg, setMsg] = useState();

    const BookUpload = () => {
        let data = {
            authorName: authorName,
            bookGenre: ['a', 'b'],  //[{'a','b','c'}]
            colorCode: colorCode,
            description: description,
            language: language,
            name: name,
            numberOfDownloads: numberOfDownloads,
            numberOfPages: numberOfPages,
            price: price,
            publicationDate: publicationDate,
            type: type,
            uploadBookDetails: {
                bannerImageUrl: bannerImageUrl,
                pdfFileUrl: pdfFileUrl,
                title: title,
                videoFileUrl: videoFileUrl
            }
        };
        let staticURL = AppURL + 'admin/book';
        RestApi.postFunction(staticURL, data, token).then(result => {
            if (result.statusCode === 200) {
                setMsg("Book added successfully.");
                setInterval(function () {
                    window.location.replace('book-upload');
                }, 2000);
            }
        });
    }

    return (
        <Fragment>
            <div className="container">
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Author Name </strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setAuthorName(e.target.value)}
                                   placeholder="Author Name"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Book Genre</strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setBookGenre(e.target.value)}
                                   placeholder="Book Genre"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Color Code </strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setColorCode(e.target.value)}
                                   placeholder="Color Code"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Description </strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setDescription(e.target.value)}
                                   placeholder="Description"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>language </strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setLanguage(e.target.value)}
                                   placeholder="Language"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Name </strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setName(e.target.value)}
                                   placeholder="Name"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Number Of Downloads </strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setNumberOfDownloads(e.target.value)}
                                   placeholder="Number Of Downloads"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Number Of Pages </strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setNumberOfPages(e.target.value)}
                                   placeholder="Number Of Pages"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Price </strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setPrice(e.target.value)}
                                   placeholder="Price"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Publication Date </strong></div>
                        <div className="col-md-5">
                            <input type="date" onChange={(e) => setPublicationDate(e.target.value)}
                                   placeholder="Publication Date"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Type</strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setType(e.target.value)}
                                   placeholder="Type"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Banner Image Url</strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setBannerImageUrl(e.target.value)}
                                   placeholder="Banner Image Url"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>PDF File Url</strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setPdfFileUrl(e.target.value)}
                                   placeholder="PDF File Url"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Title</strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setTitle(e.target.value)}
                                   placeholder="Title"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Video File Url</strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setVideoFileUrl(e.target.value)}
                                   placeholder="Video File Url"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"></div>
                        <div className="col-md-5">
                            <input type="submit" onClick={BookUpload}
                                   className="form-control btn btn-outline-success"
                                   value="Save"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-5">
                            <div className="text-center">
                                {msg ?
                                    <div className="alert alert-success alert-dismissable text-center">
                                        <button aria-hidden="true" data-dismiss="alert" className="close"
                                                type="button">&times;</button>
                                        {msg}
                                    </div> : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default AddBook;