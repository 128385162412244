import React, {Fragment} from 'react';
import {PageHeader} from "antd";

function Education(props) {
    const {teacherDetails} = props;
    return (
        <Fragment>
            <div className="form-group">
                <div className="row">
                    <div className="col-md-10">
                        <PageHeader onBack={() => window.history.back()} title="Education Information"
                                    style={{padding: "10px 0px"}}/>
                    </div>
                    <div className="col-md-2">
                        <button
                            type="button"
                            className="btn btn-outline-success"
                            data-backdrop="static"
                            data-keyboard="false"
                            data-toggle="modal"
                            data-target="#update_discipline"
                            style={{width: "100%", padding: "5px 0px", marginTop: "7px"}}>
                            Add
                        </button>
                    </div>
                </div>
            </div>

            <div className="mb-0">
                <div className="details-area">
                    <div className="pt-4">
                        <table className="table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>SL NO</th>
                                <th>Board</th>
                                <th>GPA</th>
                                <th>Institution Name</th>
                                <th>Grade</th>
                                <th>Result Type</th>
                                <th>Year of Passing</th>
                            </tr>
                            </thead>
                            <tbody>
                            {teacherDetails?.teacherEducations?.map((v_education, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{v_education.teacherWithEducation.board}</td>
                                    <td>{v_education.teacherWithEducation.gpa}</td>
                                    <td>{v_education.teacherWithEducation.instituteName}</td>
                                    <td>{v_education.teacherWithEducation.letterGrade}</td>
                                    <td>{v_education.teacherWithEducation.resultType}</td>
                                    <td>{v_education.teacherWithEducation.yearOfPassing}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Education;