import React, {Fragment, useState} from 'react';
import RestApi from "../../api/restApi";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function AddCourseSyllabus(props) {
    const [courseId, setCourseId] = useState();
    const [description, setDescription] = useState();
    const [name, setName] = useState();
    const [monthName, setMonthName] = useState();
    const [chapterSheet, setChapterSheet] = useState();
    const [monthlyChapter, setMonthlyChapter] = useState();
    const [programId, setProgramId] = useState();
    const [teacherId, setTeacherId] = useState();
    const [requisiteCourseName, setRequisiteCourseName] = useState();
    const [requisiteDescription, setRequisiteDescription] = useState();
    const [msg, setMsg] = useState("");
    const {course, teacher, program} = props;

    const addCourseSyllabus = () => {
        let data = {
            active: "true",
            courseId: courseId,
            description: description,
            monthly: {
                chapterSheet: chapterSheet,
                monthName: monthName,
                monthlyChapter: monthlyChapter
            },
            name: name,
            prerequisite: {
                requisiteCourseName: requisiteCourseName,
                requisiteDescription: requisiteDescription
            },
            programId: programId,
            teacherId: teacherId,
        };
        let staticURL = AppURL + 'admin/program';
        RestApi.postFunction(staticURL, data, token).then(result => {
            if (result.statusCode === 200) {
                setMsg("Course syllabus added successfully.");
                setInterval(function () {
                    window.location.replace('course-syllabus');
                }, 2000);
            }
        });
    }


    return (
        <Fragment>
            <div className="container">
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Course Name:</strong></div>
                        <div className="col-md-5">
                            <select onChange={(e) => setCourseId(e.target.value)} className="form-control">
                                <option value="">Select One</option>
                                {course?.courses?.map((v_course, index) => (
                                    <option key={index} value={v_course.id}>{v_course.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Description:</strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setDescription(e.target.value)}
                                   placeholder="Description"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Name:</strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setName(e.target.value)}
                                   placeholder="Name"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Month Name:</strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setMonthName(e.target.value)}
                                   placeholder="Month Name"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Monthly Chapter</strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setMonthlyChapter(e.target.value)}
                                   placeholder="Monthly Chapter"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Chapter Sheet</strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setChapterSheet(e.target.value)}
                                   placeholder="Chapter Sheet"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Program Name:</strong></div>
                        <div className="col-md-5">
                            <select onChange={(e) => setProgramId(e.target.value)} className="form-control">
                                <option value="">Select One</option>
                                {program?.programs?.map((v_programs, index) => (
                                    <option key={index} value={v_programs.id}>{v_programs.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Teacher Name:</strong></div>
                        <div className="col-md-5">
                            <select onChange={(e) => setTeacherId(e.target.value)} className="form-control">
                                <option value="">Select One</option>
                                {teacher?.teachers?.map((v_teacher, index) => (
                                    <option key={index} value={v_teacher.id}>{v_teacher.fullName}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Requisite Course Name:</strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setRequisiteCourseName(e.target.value)}
                                   placeholder="Requisite Course Name"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Requisite Description:</strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setRequisiteDescription(e.target.value)}
                                   placeholder="Requisite Description"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"></div>
                        <div className="col-md-5">
                            <input type="submit" onClick={addCourseSyllabus}
                                   className="form-control btn btn-outline-success"
                                   value="Save"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-5">
                            <div className="text-center">
                                {msg ?
                                    <div className="alert alert-success alert-dismissable text-center">
                                        <button aria-hidden="true" data-dismiss="alert" className="close"
                                                type="button">&times;</button>
                                        {msg}
                                    </div> : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default AddCourseSyllabus;