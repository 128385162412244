import React, {Fragment, useEffect, useState} from 'react';
import RestApi from "../../api/restApi";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function UpdateDiscipline(props) {
    const [curriculumId, setCurriculumId] = useState();
    const [description, setDescription] = useState();
    const [name, setName] = useState();
    const [programId, setProgramId] = useState();
    const [isActive, setIsActive] = useState();
    const [msg, setMsg] = useState();
    const {curriculum, program, discipline, checkStatus} = props;

    useEffect(() => {
        setCurriculumId(discipline?.curriculumId);
        setDescription(discipline?.description);
        setName(discipline?.name);
        setProgramId(discipline?.programId);
        setIsActive(discipline?.isActive);
    }, [discipline?.curriculumId, discipline?.description, discipline?.name, discipline?.programId, discipline?.isActive]);

    const updateDisciplines = () => {
        let id = props.id;
        let data = {
            id: id,
            curriculumId: curriculumId,
            deleted: 'true',
            description: description,
            isActive: isActive,
            name: name,
            programId: programId
        };
        let staticURL = AppURL + 'admin/discipline';
        RestApi.putFunction(staticURL, data, token).then(result => {
            if (result.statusCode === 200) {
                setMsg("Discipline updated successfully.");
                if (checkStatus === 1) {
                } else {
                    setInterval(function () {
                        window.location.replace('discipline');
                    }, 2000);
                }
            }
        });
    }


    return (
        <Fragment>
            <div className="container">
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Program Name </strong></div>
                        <div className="col-md-5">
                            <select value={programId} onChange={(e) => (setProgramId(e.target.value))}
                                    className="form-control">
                                <option value="">Select One</option>
                                {program?.programs?.map((v_programs, index) => (
                                    <option key={index} value={v_programs.id}>{v_programs.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Curriculum Name </strong></div>
                        <div className="col-md-5">
                            <select value={curriculumId} onChange={(e) => setCurriculumId(e.target.value)}
                                    className="form-control">
                                <option value="">Select One</option>
                                {curriculum?.curriculums?.map((v_curriculum, index) => (
                                    <option key={index} value={v_curriculum.id}>{v_curriculum.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Discipline Name </strong></div>
                        <div className="col-md-5">
                            <input type="text" defaultValue={name} onChange={(e) => setName(e.target.value)}
                                   placeholder="Name ...."
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Status </strong></div>
                        <div className="col-md-5">
                            <select value={isActive} className="form-control"
                                    onChange={(e) => (setIsActive(e.target.value))}>
                                <option value="">Select One</option>
                                <option value="true">Active</option>
                                <option value="false">Inactive</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"></div>
                        <div className="col-md-5">
                            <input type="submit" onClick={updateDisciplines}
                                   className="form-control btn btn-outline-success"
                                   value="Save Change"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-5">
                            <div className="text-center">
                                {msg ?
                                    <div className="alert alert-success alert-dismissable text-center">
                                        <button aria-hidden="true" data-dismiss="alert" className="close"
                                                type="button">&times;</button>
                                        {msg}
                                    </div> : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default UpdateDiscipline;