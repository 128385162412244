import React, {Fragment, useEffect, useState} from 'react';
import RestApi from "../../../api/restApi";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function CourseDetails(props) {
    const [courseDetails, setCourseDetails] = useState();
    let {courseID} = props;

    useEffect(() => {
        let staticURL = AppURL + 'admin/course/details?courseId=' + courseID;
        RestApi.getFunction(staticURL, token).then(result => {
            setCourseDetails(result?.course);
        });
    }, [courseID]);

    return (
        <Fragment>

            <section className="col-lg-12">
                <div className="container">
                    <div className="form-group">
                        <div className="row">
                            <div className="col-md-4 text-right h6"><strong>Program
                                Name </strong></div>
                            <div className="col-md-5">
                                {courseDetails?.program?.name}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-md-4 text-right h6"><strong>Curriculum
                                Name </strong></div>
                            <div className="col-md-5">
                                {courseDetails?.curriculum?.name}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-md-4 text-right h6"><strong>Discipline Name</strong></div>
                            <div className="col-md-5">
                                {courseDetails?.discipline?.name}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-md-4 text-right h6"><strong>Course
                                Name </strong></div>
                            <div className="col-md-5">
                                {courseDetails?.name}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-md-4 text-right h6"><strong>Course
                                Description </strong></div>
                            <div className="col-md-5">
                                {courseDetails?.description}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-md-4 text-right h6"><strong>Teacher
                                Name </strong></div>
                            <div className="col-md-5">
                                {courseDetails?.teachersAssigned?.map((v_teacher, index) => (
                                    <p key={index}>{v_teacher?.fullName}</p>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-md-4 text-right h6"><strong>MRP Price </strong>
                            </div>
                            <div className="col-md-5">
                                {courseDetails?.price?.mrp}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-md-4 text-right h6"><strong>TP Price </strong>
                            </div>
                            <div className="col-md-5">
                                {courseDetails?.price?.tp}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-md-4 text-right h6"><strong>Discounted
                                Price </strong></div>
                            <div className="col-md-5">
                                {courseDetails?.price?.discountedPrice}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-md-4 text-right h6"><strong>Popular
                                Course </strong></div>
                            <div className="col-md-5">
                                {courseDetails?.isPopular ? "Yes" : "No"}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-md-4 text-right h6"><strong>ExclusiveLive
                                Course </strong></div>
                            <div className="col-md-5">
                                {courseDetails?.isExclusiveLiveCourse ? "Yes" : "No"}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-md-4 text-right h6"><strong>Total
                                Lectures </strong></div>
                            <div className="col-md-5">
                                {courseDetails?.numberOfLectures}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-md-4 text-right h6"><strong>Total
                                Notes </strong></div>
                            <div className="col-md-5">
                                {courseDetails?.numberOfNotes}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-md-4 text-right h6"><strong>Total Cheat
                                Sheet </strong></div>
                            <div className="col-md-5">
                                {courseDetails?.numberOfCheatSheet}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-md-4 text-right h6"><strong>Total
                                Quizes </strong></div>
                            <div className="col-md-5">
                                {courseDetails?.numberOfQuiz}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-md-4 text-right h6"><strong>Total
                                Videos </strong></div>
                            <div className="col-md-5">
                                {courseDetails?.numberOfVideos}

                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-md-4 text-right h6"><strong>Duration in
                                Minutes </strong></div>
                            <div className="col-md-5">
                                {courseDetails?.durationInMinutes}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-md-4 text-right h6"><strong>Course Duration in
                                Day </strong></div>
                            <div className="col-md-5">
                                {courseDetails?.courseDurationInDay}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-md-4 text-right h6"><strong>Status </strong>
                            </div>
                            <div className="col-md-5">
                                {courseDetails?.isActive ? "Active" : "Inactive"}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
}

export default CourseDetails;