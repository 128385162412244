import React, {Fragment, useEffect, useState} from 'react';
import RestApi from "../../api/restApi";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function UpdateCourseSyllabus(props) {
    const [courseId, setCourseId] = useState();
    const [description, setDescription] = useState();
    const [name, setName] = useState();
    const [monthName, setMonthName] = useState();
    const [chapterSheet, setChapterSheet] = useState();
    const [monthlyChapter, setMonthlyChapter] = useState();
    const [programId, setProgramId] = useState();
    const [teacherId, setTeacherId] = useState();
    const [requisiteCourseName, setRequisiteCourseName] = useState();
    const [requisiteDescription, setRequisiteDescription] = useState();
    const [msg, setMsg] = useState("");
    const {course, teacher, program, courseSyllabus} = props;

    useEffect(() => {
        setCourseId(courseSyllabus?.courseId);
        setDescription(courseSyllabus?.description);
        setName(courseSyllabus?.name);
        setMonthName(courseSyllabus?.monthly?.monthName);
        setChapterSheet(courseSyllabus?.monthly?.chapterSheet);
        setMonthlyChapter(courseSyllabus?.monthly?.monthlyChapter);
        setProgramId(courseSyllabus?.programId);
        setTeacherId(courseSyllabus?.teacherId);
        setRequisiteCourseName(courseSyllabus?.prerequisite?.requisiteCourseName);
        setRequisiteDescription(courseSyllabus?.prerequisite?.requisiteDescription);
    }, [courseSyllabus?.courseId, courseSyllabus?.description, courseSyllabus?.name, courseSyllabus?.monthly?.monthName, courseSyllabus?.monthly?.chapterSheet, courseSyllabus?.monthly?.monthlyChapter, courseSyllabus?.programId, courseSyllabus?.teacherId, courseSyllabus?.prerequisite?.requisiteCourseName, courseSyllabus?.prerequisite?.requisiteDescription]);

    const updateCourseSyllabus = () => {
        let id = props.id;
        let data = {
            id: id,
            active: "true",
            courseId: courseId,
            description: description,
            monthly: {
                chapterSheet: chapterSheet,
                monthName: monthName,
                monthlyChapter: monthlyChapter
            },
            name: name,
            prerequisite: {
                requisiteCourseName: requisiteCourseName,
                requisiteDescription: requisiteDescription
            },
            programId: programId,
            teacherId: teacherId,
        };
        let staticURL = AppURL + 'admin/course-syllabus';
        RestApi.putFunction(staticURL, data, token).then(result => {
            if (result.statusCode === 200) {
                setMsg("Course-syllabus updated successfully.");
                setInterval(function () {
                    window.location.replace('course-syllabus');
                }, 2000);
            }
        });
    }

    return (
        <Fragment>
            <div className="container">
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Course Name:</strong></div>
                        <div className="col-md-5">
                            <select value={courseId} onChange={(e) => setCourseId(e.target.value)}
                                    className="form-control">
                                <option value="">Select One</option>
                                {course?.courses?.map((v_course, index) => (
                                    <option key={index} value={v_course.id}>{v_course.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Description:</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={description} type="text"
                                   onChange={(e) => setDescription(e.target.value)}
                                   placeholder="Description"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Name:</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={name} type="text" onChange={(e) => setName(e.target.value)}
                                   placeholder="Name"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Month Name:</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={monthName} type="text" onChange={(e) => setMonthName(e.target.value)}
                                   placeholder="Month Name"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Monthly Chapter</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={monthlyChapter} type="text"
                                   onChange={(e) => setMonthlyChapter(e.target.value)}
                                   placeholder="Monthly Chapter"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Chapter Sheet</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={chapterSheet} type="text"
                                   onChange={(e) => setChapterSheet(e.target.value)}
                                   placeholder="Chapter Sheet"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Program Name:</strong></div>
                        <div className="col-md-5">
                            <select value={programId} onChange={(e) => (setProgramId(e.target.value))}
                                    className="form-control">
                                <option value="">Select One</option>
                                {program?.programs?.map((v_programs, index) => (
                                    <option key={index} value={v_programs.id}>{v_programs.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Teacher Name:</strong></div>
                        <div className="col-md-5">
                            <select value={teacherId} onChange={(e) => setTeacherId(e.target.value)}
                                    className="form-control">
                                <option value="">Select One</option>
                                {teacher?.teachers?.map((v_teacher, index) => (
                                    <option key={index} value={v_teacher.id}>{v_teacher.fullName}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Requisite Course Name:</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={requisiteCourseName} type="text"
                                   onChange={(e) => setRequisiteCourseName(e.target.value)}
                                   placeholder="Requisite Course Name"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Requisite Description:</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={requisiteDescription} type="text"
                                   onChange={(e) => setRequisiteDescription(e.target.value)}
                                   placeholder="Requisite Description"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"></div>
                        <div className="col-md-5">
                            <input type="submit" onClick={updateCourseSyllabus}
                                   className="form-control btn btn-outline-success"
                                   value="Save Change"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-5">
                            <div className="text-center">
                                {msg ?
                                    <div className="alert alert-success alert-dismissable text-center">
                                        <button aria-hidden="true" data-dismiss="alert" className="close"
                                                type="button">&times;</button>
                                        {msg}
                                    </div> : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default UpdateCourseSyllabus;