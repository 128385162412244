import React, {Fragment, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import RestApi from "../../api/restApi";
import {Layout, Menu} from "antd";

import {
    UserOutlined,
    SnippetsOutlined,
    FormOutlined,
    DiffOutlined,
} from "@ant-design/icons";

import Active from "./active";
import Inactive from "./inactive";
import Details from "./content/CourseInformation";
import Enrolled from "./content/EnrolledStudents";
import TeacherMange from "./content/TeacherManagement";
import ChapterManagement from "./content/ChapterManagement";

let token = sessionStorage.getItem("authentation");
const AppURL = process.env.REACT_APP_BASE_URL;
const {Sider, Content} = Layout;

function TabPanel(props) {
    const {children, value, index} = props;

    return (
        <div hidden={value != index}>
            {children}
        </div>
    );
}

function CourseDetails(props) {
    const [value, setValue] = useState(1);
    const testFunction = (props) => {
        setValue(props);
    };
    const [courseDetails, setCourseDetails] = useState();
    const [courseEnrolled, setCourseEnrolled] = useState();
    const [courseTeacher, setCourseTeacher] = useState();
    let {courseID} = useParams();

    useEffect(() => {
        let staticURL = AppURL + "admin/course/details?courseId=" + courseID;
        RestApi.getFunction(staticURL, token).then((result) => {
            setCourseDetails(result.course);
        });
    }, [courseID]);

    useEffect(() => {
        let staticURL = AppURL + "admin/course-enrolled?courseId=" + courseID + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then((result) => {
            setCourseEnrolled(result);
        });
    }, [courseID]);

    useEffect(() => {
        let staticURL = AppURL + "admin/course/teachers?courseId=" + courseID;
        RestApi.getFunction(staticURL, token).then((result) => {
            const [teachersList] = result.teachersWithProgramAndCourse;
            setCourseTeacher(teachersList);
        });
    }, [courseID]);

    return (
        <Fragment>
            <div className="content-wrapper">
                <div className="content-header"></div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <section className="col-lg-12">
                                <div className="">
                                    <Layout>
                                        <Content style={{padding: "0 0px"}}>
                                            <Layout
                                                className="site-layout-background"
                                                style={{padding: 0, height: "85vh"}}>
                                                <Sider
                                                    className="site-layout-background"
                                                    width={300}
                                                    style={{
                                                        backgroundColor: "white",
                                                        borderRight: "20px solid #f4f6f9",
                                                    }}>
                                                    <div className="details-layout course-background"></div>
                                                    <div className="b-background">
                                                        <div className="b-icon book-icon"></div>
                                                        <div className="b-body">
                                                            <h6 className="" style={{alignText: ""}}>
                                                                Course Name{" "}
                                                            </h6>

                                                            {courseDetails?.isActive ? (
                                                                <Active/>
                                                            ) : (
                                                                <Inactive/>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <Menu
                                                        mode="inline"
                                                        defaultSelectedKeys={["1"]}
                                                        style={{backgroundColor: "white"}}>
                                                        <Menu.Item
                                                            key="1"
                                                            icon={<SnippetsOutlined/>}
                                                            onClick={(e) => {
                                                                testFunction(e.key);
                                                            }}>
                                                            Course Information
                                                        </Menu.Item>
                                                        <Menu.Item
                                                            key="2"
                                                            icon={<FormOutlined/>}
                                                            onClick={(e) => {
                                                                testFunction(e.key);
                                                            }}>
                                                            Enrolled Students
                                                        </Menu.Item>
                                                        <Menu.Item key="3" icon={<UserOutlined/>} onClick={(e) => {
                                                            testFunction(e.key);
                                                        }}>
                                                            Teacher Management
                                                        </Menu.Item>
                                                        <Menu.Item key="4" icon={<UserOutlined/>} onClick={(e) => {
                                                            testFunction(e.key);
                                                        }}>
                                                            Chapter Management
                                                        </Menu.Item>
                                                    </Menu>
                                                </Sider>
                                                <Content
                                                    style={{
                                                        padding: "0 20px",
                                                        minHeight: 280,
                                                        backgroundColor: "white",
                                                    }}>
                                                    <div
                                                        className="site-layout-background"
                                                        style={{marginLeft: "15px", padding: 24, minHeight: "100%"}}>
                                                        <TabPanel value={value} index="1">
                                                            <Details courseDetails={courseDetails} courseID={courseID}/>
                                                        </TabPanel>
                                                        <TabPanel value={value} index="2">
                                                            <Enrolled courseEnrolled={courseEnrolled}
                                                                      courseID={courseID}/>
                                                        </TabPanel>
                                                        <TabPanel value={value} index="3">
                                                            <TeacherMange courseTeacher={courseTeacher}/>
                                                        </TabPanel>
                                                        <TabPanel value={value} index="4">
                                                            <ChapterManagement/>
                                                        </TabPanel>
                                                    </div>
                                                </Content>
                                            </Layout>
                                        </Content>
                                    </Layout>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </div>
        </Fragment>
    );
}

export default CourseDetails;
