import React, {Fragment, useEffect, useState} from 'react';
import RestApi from "../../api/restApi";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function UpdateTeacher(props) {
    const [fullName, setFullName] = useState();
    const [mobileNumber, setMobileNumber] = useState();
    const [emergencyContactNumber, setEmergencyContactNumber] = useState();
    const [email, setEmail] = useState();
    const [bloodGroup, setBloodGroup] = useState();
    const [dateOfBirth, setDateOfBirth] = useState();
    const [gender, setGender] = useState();
    const [nationalId, setNationalId] = useState();
    const [passportNumber, setPassportNumber] = useState();
    const [profilePictureUrl, setProfilePictureUrl] = useState();

    const [area, setArea] = useState();
    const [country, setCountry] = useState();
    const [district, setDistrict] = useState();
    const [flatNo, setFlatNo] = useState();
    const [houseNo, setHouseNo] = useState();
    const [latitude, setLatitude] = useState();
    const [longitude, setLongitude] = useState();
    const [postCode, setPostCode] = useState();
    const [roadNo, setRoadNo] = useState();
    const [thana, setThana] = useState();


    const [board, setBoard] = useState();
    const [gpa, setGpa] = useState();
    const [instituteName, setInstituteName] = useState();
    const [letterGrade, setLetterGrade] = useState();
    const [qualificationName, setQualificationName] = useState();
    const [resultType, setResultType] = useState();
    const [yearOfPassing, setYearOfPassing] = useState();
    const [endDate, setEndDate] = useState();
    const [startDate, setStartDate] = useState();
    const [instituteName1, setInstituteName1] = useState();
    const [isCurrentlyWorking, setIsCurrentlyWorking] = useState();
    const [teacherJobDescription, setTeacherJobDescription] = useState();
    const [title, setTitle] = useState();
    const [skillDescription, setSkillDescription] = useState();
    const [skillFromZone, setSkillFromZone] = useState();
    const [skillLevel, setSkillLevel] = useState();
    const [skillName, setSkillName] = useState();


    const [msg, setMsg] = useState("");
    const {teacher} = props;
    let teacherAddress = teacher?.teacherDetails?.address;
    useEffect(() => {
        setEmail(teacher?.email);
        setMobileNumber(teacher?.mobileNumber);
        setEmergencyContactNumber(teacher?.emergencyContactNumber);
        setFullName(teacher?.fullName);
        setBloodGroup(teacher?.teacherDetails.bloodGroup);
        setDateOfBirth(teacher?.teacherDetails.dateOfBirth);
        setGender(teacher?.teacherDetails.gender);
        setNationalId(teacher?.teacherDetails.nationalId);
        setPassportNumber(teacher?.teacherDetails.passport);
        setProfilePictureUrl(teacher?.teacherDetails.profilePictureUrl);
        setArea(teacherAddress?.area);
        setCountry(teacherAddress?.country);
        setDistrict(teacherAddress?.district);
        setFlatNo(teacherAddress?.flatNo);
        setHouseNo(teacherAddress?.houseNo);
        setPostCode(teacherAddress?.postCode);
        setLatitude(teacherAddress?.location?.latitude);
        setLongitude(teacherAddress?.location?.longitude);
        setRoadNo(teacherAddress?.roadNo);
        setThana(teacherAddress?.thana);
    }, [teacher?.email, teacher?.mobileNumber, teacher?.fullName, teacher?.teacherDetails.bloodGroup, teacher?.teacherDetails.dateOfBirth, teacher?.teacherDetails.gender, teacher?.teacherDetails.nationalId, teacher?.teacherDetails.passport, teacher?.teacherDetails.profilePictureUrl, teacherAddress?.area, teacherAddress?.country, teacherAddress?.district, teacherAddress?.flatNo, teacherAddress?.houseNo, teacherAddress?.postCode, teacherAddress?.location?.latitude, teacherAddress?.location?.longitude, teacherAddress?.roadNo, teacherAddress?.thana]);


    const updateTeacher = () => {
        let id = props.id;
        let staticURL = AppURL + 'teacher/update-whole-profile';
        let data = {
            email: email,
            fullName: fullName,
            mobileNumber: mobileNumber,
            teacherDetails: {
                address: {
                    area: area,
                    country: country,
                    district: district,
                    flatNo: flatNo,
                    houseNo: houseNo,
                    location: {
                        latitude: latitude,
                        longitude: longitude
                    },
                    postCode: postCode,
                    roadNo: roadNo,
                    thana: thana
                },
                bloodGroup: bloodGroup,
                dateOfBirth: dateOfBirth,
                educationalQualificationList: [
                    {
                        board: board,
                        gpa: gpa,
                        instituteName: instituteName,
                        letterGrade: letterGrade,
                        qualificationName: qualificationName,
                        resultType: resultType,
                        yearOfPassing: yearOfPassing
                    }
                ],
                emergencyContactNumber: emergencyContactNumber,
                experienceList: [
                    {
                        endDate: endDate,
                        instituteName: instituteName,
                        isCurrentlyWorking: isCurrentlyWorking,
                        startDate: startDate,
                        teacherJobDescription: teacherJobDescription,
                        title: title
                    }
                ],
                expertiseList: [
                    {
                        skillDescription: skillDescription,
                        skillLevel: skillLevel,
                        skillName: skillName
                    }
                ],
                gender: gender,
                nationalId: nationalId,
                passport: passportNumber,
                profilePictureUrl: profilePictureUrl
            }
        };
        RestApi.putFunction(staticURL, data, token).then(result => {
            if (result.statusCode === 200) {
                setMsg("Teacher updated successfully.");
                setInterval(function () {
                    window.location.replace('program');
                }, 2000);
            }
        });
    }

    return (
        <Fragment>
            <div className="container">
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-12 text-right h6">
                            <h3 style={{textAlign: 'center'}}>Basic Information</h3>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Teacher Name </strong></div>
                        <div className="col-md-5">
                            <input defaultValue={fullName} onChange={(e) => setFullName(e.target.value)} type="text"
                                   placeholder="Teacher Name"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Mobile Number </strong></div>
                        <div className="col-md-5">
                            <input defaultValue={mobileNumber}
                                   onChange={(e) => setMobileNumber(e.target.value)}
                                   type="text"
                                   placeholder="Mobile Number"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Emergency Contact Number</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={emergencyContactNumber}
                                   onChange={(e) => setEmergencyContactNumber(e.target.value)}
                                   type="text"
                                   placeholder="Emergency Contact Number"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Email </strong></div>
                        <div className="col-md-5">
                            <input defaultValue={email} onChange={(e) => setEmail(e.target.value)}
                                   type="text"
                                   placeholder="Email"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Blood Group </strong></div>
                        <div className="col-md-5">
                            <select value={bloodGroup} className="form-control"
                                    onChange={(e) => setBloodGroup(e.target.value)}>
                                <option value="">Blood Group</option>
                                <option value="O+">O+</option>
                                <option value="O-">O-</option>
                                <option value="A+">A+</option>
                                <option value="A-">A-</option>
                                <option value="B+">B+</option>
                                <option value="B-">B-</option>
                                <option value="AB+">AB+</option>
                                <option value="AB-">AB-</option>
                                <option value="N/A">N/A</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Date of Birth</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={dateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)}
                                   type="date"
                                   placeholder="Date of Birth"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Gender </strong></div>
                        <div className="col-md-5">
                            <select value={gender} className="form-control" onChange={(e) => setGender(e.target.value)}>
                                <option value="">Blood Group</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="N/A">N/A</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>NationalId</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={nationalId}
                                   onChange={(e) => setNationalId(e.target.value)}
                                   type="text"
                                   placeholder="NationalId"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Passport Number </strong></div>
                        <div className="col-md-5">
                            <input defaultValue={passportNumber}
                                   onChange={(e) => setPassportNumber(e.target.value)}
                                   type="text"
                                   placeholder="Passport Number"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Profile Picture URL</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={profilePictureUrl}
                                   onChange={(e) => setProfilePictureUrl(e.target.value)}
                                   type="text"
                                   placeholder="Profile Picture Url"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>


                <div className="form-group">
                    <div className="row">
                        <div className="col-md-12 text-right h6">
                            <h3 style={{textAlign: 'center'}}>Address</h3>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Area</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={area}
                                   onChange={(e) => setArea(e.target.value)}
                                   type="text"
                                   placeholder="Area"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Country</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={country}
                                   onChange={(e) => setCountry(e.target.value)}
                                   type="text"
                                   placeholder="Country"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>District</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={district}
                                   onChange={(e) => setDistrict(e.target.value)}
                                   type="text"
                                   placeholder="District"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Thana</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={thana}
                                   onChange={(e) => setThana(e.target.value)}
                                   type="text"
                                   placeholder="Thana"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Flat No</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={flatNo}
                                   onChange={(e) => setFlatNo(e.target.value)}
                                   type="text"
                                   placeholder="Flat No"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>House No</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={houseNo}
                                   onChange={(e) => setHouseNo(e.target.value)}
                                   type="text"
                                   placeholder="House No"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>


                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Latitude</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={latitude}
                                   onChange={(e) => setLatitude(e.target.value)}
                                   type="text"
                                   placeholder="Latitude"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Longitude</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={longitude}
                                   onChange={(e) => setLongitude(e.target.value)}
                                   type="text"
                                   placeholder="Longitude"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Post Code</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={postCode}
                                   onChange={(e) => setPostCode(e.target.value)}
                                   type="text"
                                   placeholder="Post Code"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Road No</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={roadNo}
                                   onChange={(e) => setRoadNo(e.target.value)}
                                   type="text"
                                   placeholder="Road No"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-md-12 text-right h6">
                            <h3 style={{textAlign: 'center'}}>Education</h3>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Board</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={board}
                                   onChange={(e) => setBoard(e.target.value)}
                                   type="text"
                                   placeholder="Board"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>GPA</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={gpa}
                                   onChange={(e) => setGpa(e.target.value)}
                                   type="text"
                                   placeholder="GPA"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Institute Name</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={instituteName}
                                   onChange={(e) => setInstituteName(e.target.value)}
                                   type="text"
                                   placeholder="Institute Name"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Letter Grade</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={letterGrade}
                                   onChange={(e) => setLetterGrade(e.target.value)}
                                   type="text"
                                   placeholder="Letter Grade"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Qualification Name</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={qualificationName}
                                   onChange={(e) => setQualificationName(e.target.value)}
                                   type="text"
                                   placeholder="Qualification Name"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Result Type</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={resultType}
                                   onChange={(e) => setResultType(e.target.value)}
                                   type="text"
                                   placeholder="Result Type"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Year of Passing</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={yearOfPassing}
                                   onChange={(e) => setYearOfPassing(e.target.value)}
                                   type="text"
                                   placeholder="Year of Passing"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-md-12 text-right h6">
                            <h3 style={{textAlign: 'center'}}>Experience</h3>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Start Date</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={startDate}
                                   onChange={(e) => setStartDate(e.target.value)}
                                   type="text"
                                   placeholder="Start Date"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>End Date</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={endDate}
                                   onChange={(e) => setEndDate(e.target.value)}
                                   type="text"
                                   placeholder="End Date"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Institute Name</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={instituteName1}
                                   onChange={(e) => setInstituteName1(e.target.value)}
                                   type="text"
                                   placeholder="instituteName"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Is Currently Working</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={isCurrentlyWorking}
                                   onChange={(e) => setIsCurrentlyWorking(e.target.value)}
                                   type="text"
                                   placeholder="Is Currently Working"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>teacherJobDescription</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={teacherJobDescription}
                                   onChange={(e) => setTeacherJobDescription(e.target.value)}
                                   type="text"
                                   placeholder="Year of Passing"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Title</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={title}
                                   onChange={(e) => setTitle(e.target.value)}
                                   type="text"
                                   placeholder="Title"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>


                <div className="form-group">
                    <div className="row">
                        <div className="col-md-12 text-right h6">
                            <h3 style={{textAlign: 'center'}}>Skill</h3>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>skillDescription</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={skillDescription}
                                   onChange={(e) => setSkillDescription(e.target.value)}
                                   type="text"
                                   placeholder="Year of Passing"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>skillFromZone</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={skillFromZone}
                                   onChange={(e) => setSkillFromZone(e.target.value)}
                                   type="text"
                                   placeholder="Year of Passing"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>skillLevel</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={skillLevel}
                                   onChange={(e) => setSkillLevel(e.target.value)}
                                   type="text"
                                   placeholder="Year of Passing"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Skill Name</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={skillName}
                                   onChange={(e) => setSkillName(e.target.value)}
                                   type="text"
                                   placeholder="Skill Name"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"></div>
                        <div className="col-md-5">
                            <input type="submit" onClick={updateTeacher}
                                   className="form-control btn btn-outline-success"
                                   value="Save Change"/>
                        </div>
                    </div>
                </div>


                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-5">
                            <div className="text-center">
                                {msg ?
                                    <div className="alert alert-success alert-dismissable text-center">
                                        <button aria-hidden="true" data-dismiss="alert" className="close"
                                                type="button">&times;</button>
                                        {msg}
                                    </div> : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default UpdateTeacher;