import React from 'react';

function Header(props) {

    const logout = () => {
        sessionStorage.setItem('authentation', '');
        setInterval(function () {
            window.location.replace('/');
        }, 1000);
    }

    return (
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <span style={{cursor: 'pointer'}} className="nav-link" data-widget="pushmenu">
                        <i className="fas fa-bars"></i>
                    </span>
                </li>
            </ul>
            <form className="form-inline ml-3">
                <div className="input-group input-group-sm">
                </div>
            </form>
            <ul className="navbar-nav ml-auto">
                <li className="nav-item dropdown">
                    <span style={{cursor: 'pointer'}} className="nav-link" data-toggle="dropdown">
                        <i className="far fa-user"></i>
                    </span>
                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                        <div className="dropdown-divider"></div>
                        <span style={{cursor: 'pointer'}} className="dropdown-item" onClick={logout}>
                            <i className="fas fa-sign-out-alt mr-2"></i> Logout
                        </span>
                        <span style={{cursor: 'pointer'}} className="dropdown-item">
                            <i className="fas fa-key mr-2"></i> Change Password
                        </span>
                    </div>
                </li>
            </ul>
        </nav>
    );
}

export default Header;