import React, {Fragment, useState, useEffect} from 'react';
import {PageHeader} from "antd";
import RestApi from "../../../api/restApi";
import {useParams} from "react-router-dom";
import UpdateProgram from "../UpdateProgram";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function ProgramInformation(props) {
    const [programDetails, setProgramDetails] = useState([]);
    let {programID} = useParams();

    useEffect(() => {
        let staticURL = AppURL + "admin/program/details?id=" + programID;
        RestApi.getFunction(staticURL, token).then((result) => {
            setProgramDetails(result.program);
        });
    }, [programID]);


    return (
        <Fragment>
            <div className="form-group">
                <div className="row">
                    <div className="col-md-10">
                        <PageHeader onBack={() => window.history.back()} title="Program Information"
                                    style={{padding: "10px 0px"}}/>
                    </div>
                    <div className="col-md-2">
                        <button
                            type="button"
                            className="btn btn-outline-success"
                            data-backdrop="static"
                            data-keyboard="false"
                            data-toggle="modal"
                            data-target="#update_program"
                            style={{width: "100%", padding: "5px 0px", marginTop: "7px"}}>
                            Update Program
                        </button>
                    </div>
                </div>
            </div>
            <div className="mb-0">
                <div className="details-area">
                    <div className="pt-4">
                        <div className="single">
                            <span className="info-name">Program Name</span>
                            <span className="info-desc"> {programDetails.name}</span>
                        </div>
                        <div className="single">
                            <span className="info-name">Program Type</span>
                            <span className="info-desc"> {programDetails.type}</span>
                        </div>
                        <div className="single">
                            <span className="info-name">Colour Code</span>
                            <span className="info-desc">{programDetails.colorCode}</span>
                        </div>
                        <div className="single">
                            <span className="info-name">With Course Display</span>
                            <span className="info-desc">{programDetails.withCourseDisplay ? "Yes" : "No"}</span>
                        </div>
                        <div className="single">
                            <span className="info-name">Status</span>
                            <span className="info-desc">{programDetails.isActive ? "Active" : "Inactive"}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="update_program">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Update Program</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <UpdateProgram checkStatus={1} id={programID} program={programDetails}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default ProgramInformation;