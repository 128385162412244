import React, {Fragment, useEffect, useState} from 'react';
import RestApi from "../../api/restApi";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function UpdateCourse(props) {
    const [msg, setMsg] = useState();
    const [courseDurationInDay, setCourseDurationInDay] = useState();
    const [curriculumId, setCurriculumId] = useState(1);
    const [teacherId, setTeacherId] = useState();
    const [description, setDescription] = useState();
    const [disciplineId, setDisciplineId] = useState(1);
    const [durationInMinutes, setDurationInMinutes] = useState();
    const [name, setName] = useState();
    const [numberOfVideos, setNumberOfVideos] = useState();
    const [discountedPrice, setDiscountedPrice] = useState();
    const [mrp, setMrp] = useState();
    const [tp, setTp] = useState();
    const [programId, setProgramId] = useState(1);
    const [numberOfCheatSheet, setNumberOfCheatSheet] = useState();
    const [numberOfLectures, setNumberOfLectures] = useState();
    const [numberOfNotes, setNumberOfNotes] = useState();
    const [numberOfQuiz, setNumberOfQuiz] = useState();
    const [isActive, setIsActive] = useState();
    const [isExclusiveLiveCourse, setIsExclusiveLiveCourse] = useState();
    const [isPopular, setIsPopular] = useState();
    const {curriculum, discipline, program, course, teacher, checkStatus} = props;

    useEffect(() => {
        setCourseDurationInDay(course?.courseDurationInDay);
        setCurriculumId(course?.curriculumId);
        setTeacherId(course?.teacherId);
        setDescription(course?.description);
        setDisciplineId(course?.disciplineId);
        setDurationInMinutes(course?.durationInMinutes);
        setName(course?.name);
        setNumberOfVideos(course?.numberOfVideos);
        setDiscountedPrice(course?.price.discountedPrice);
        setMrp(course?.price.mrp);
        setTp(course?.price.tp);
        setProgramId(course?.programId);
        setNumberOfCheatSheet(course?.numberOfCheatSheet);
        setNumberOfLectures(course?.numberOfLectures);
        setNumberOfNotes(course?.numberOfNotes);
        setNumberOfQuiz(course?.numberOfQuiz);
        setIsActive(course?.isActive);
        setIsExclusiveLiveCourse(course?.isExclusiveLiveCourse);
        setIsPopular(course?.isPopular);
    }, [course?.courseDurationInDay, course?.curriculumId, course?.numberOfVideos, course?.teacherId, course?.description, course?.disciplineId, course?.durationInMinutes, course?.name, course?.price.currencyCode, course?.price.discountedPrice, course?.price.mrp, course?.price.tp, course?.programId, course?.numberOfCheatSheet, course?.numberOfLectures, course?.numberOfNotes, course?.numberOfQuiz, course?.isActive, course?.isExclusiveLiveCourse, course?.isPopular]);

    const UpdateCourses = () => {
        let id = props.id;
        let data = {
            courseDurationInDay: courseDurationInDay,
            curriculumId: curriculumId,
            teacherId: teacherId,
            description: description,
            disciplineId: disciplineId,
            durationInMinutes: durationInMinutes,
            name: name,
            isActive: isActive,
            isExclusiveLiveCourse: isExclusiveLiveCourse,
            isPopular: isPopular,
            numberOfVideos: numberOfVideos,
            id: id,
            price: {
                discountedPrice: discountedPrice,
                mrp: mrp,
                tp: tp
            },
            programId: programId,
            numberOfCheatSheet: numberOfCheatSheet,
            numberOfLectures: numberOfLectures,
            numberOfNotes: numberOfNotes,
            numberOfQuiz: numberOfQuiz
        };
        let staticURL = AppURL + 'admin/course';
        RestApi.putFunction(staticURL, data, token).then(result => {
            if (result.statusCode === 200) {
                setMsg("Course updated successfully.");
                if (checkStatus === 1) {
                } else {
                    setInterval(function () {
                        window.location.replace('course');
                    }, 2000);
                }
            }
        });
    }

    return (
        <Fragment>
            <div className="container">
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Program Name </strong></div>
                        <div className="col-md-5">
                            <select value={programId} onChange={(e) => (setProgramId(e.target.value))}
                                    className="form-control">
                                <option value="">Select One</option>
                                {program?.programs?.map((v_programs, index) => (
                                    <option key={index} value={v_programs.id}>{v_programs.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Curriculum Name </strong></div>
                        <div className="col-md-5">
                            <select value={curriculumId} onChange={(e) => (setCurriculumId(e.target.value))}
                                    className="form-control">
                                <option value="">Select One</option>
                                {curriculum?.curriculums?.map((v_curriculum, index) => (
                                    <option key={index} value={v_curriculum.id}>{v_curriculum.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Discipline Name </strong></div>
                        <div className="col-md-5">
                            <select value={disciplineId} onChange={(e) => (setDisciplineId(e.target.value))}
                                    className="form-control">
                                <option value="">Select One</option>
                                {discipline?.disciplines?.map((v_discipline, index) => (
                                    <option key={index} value={v_discipline.id}>{v_discipline.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Course Name </strong></div>
                        <div className="col-md-5">
                            <input defaultValue={name} type="text" onChange={(e) => setName(e.target.value)}
                                   placeholder="Name"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Course Description </strong></div>
                        <div className="col-md-5">
                            <input defaultValue={description} type="text"
                                   onChange={(e) => setDescription(e.target.value)}
                                   placeholder="Description"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Teacher Name </strong></div>
                        <div className="col-md-5">
                            <select value={teacherId} onChange={(e) => (setTeacherId(e.target.value))}
                                    className="form-control">
                                <option value="">Select One</option>
                                {teacher?.teachers?.map((v_teacher, index) => (
                                    <option key={index} value={v_teacher.id}>{v_teacher.fullName}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>MRP Price </strong></div>
                        <div className="col-md-5">
                            <input defaultValue={mrp} type="text" onChange={(e) => setMrp(e.target.value)}
                                   placeholder="MRP Price"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>TP Price </strong></div>
                        <div className="col-md-5">
                            <input defaultValue={tp} type="text" onChange={(e) => setTp(e.target.value)}
                                   placeholder="TP Price"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Discounted Price </strong></div>
                        <div className="col-md-5">
                            <input defaultValue={discountedPrice} type="text"
                                   onChange={(e) => setDiscountedPrice(e.target.value)}
                                   placeholder="Discounted Price"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Popular Course </strong></div>
                        <div className="col-md-5">
                            <select value={isPopular} onChange={(e) => setIsPopular(e.target.value)}
                                    className="form-control">
                                <option value="">Select One</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>ExclusiveLive Course </strong></div>
                        <div className="col-md-5">
                            <select value={isExclusiveLiveCourse}
                                    onChange={(e) => setIsExclusiveLiveCourse(e.target.value)}
                                    className="form-control">
                                <option value="">Select One</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Total Lectures </strong></div>
                        <div className="col-md-5">
                            <input defaultValue={numberOfLectures} type="text"
                                   onChange={(e) => setNumberOfLectures(e.target.value)}
                                   placeholder="Total Lectures"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Total Notes </strong></div>
                        <div className="col-md-5">
                            <input defaultValue={numberOfNotes} type="text"
                                   onChange={(e) => setNumberOfNotes(e.target.value)}
                                   placeholder="Total Notes"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Total Cheat Sheet </strong></div>
                        <div className="col-md-5">
                            <input defaultValue={numberOfCheatSheet} type="text"
                                   onChange={(e) => setNumberOfCheatSheet(e.target.value)}
                                   placeholder="Total Cheat Sheet"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Total Quizes </strong></div>
                        <div className="col-md-5">
                            <input defaultValue={numberOfQuiz} type="text"
                                   onChange={(e) => setNumberOfQuiz(e.target.value)}
                                   placeholder="Total Notes"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Total Videos </strong></div>
                        <div className="col-md-5">
                            <input defaultValue={numberOfVideos} type="text"
                                   onChange={(e) => setNumberOfVideos(e.target.value)}
                                   placeholder="Total Videos"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Duration in Minutes </strong></div>
                        <div className="col-md-5">
                            <input defaultValue={durationInMinutes} type="text"
                                   onChange={(e) => setDurationInMinutes(e.target.value)}
                                   placeholder="Duration in Minutes"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Course Duration in Day </strong></div>
                        <div className="col-md-5">
                            <input defaultValue={courseDurationInDay} type="text"
                                   onChange={(e) => setCourseDurationInDay(e.target.value)}
                                   placeholder="Course Duration in Day"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Status </strong></div>
                        <div className="col-md-5">
                            <select value={isActive} onChange={(e) => setIsActive(e.target.value)}
                                    className="form-control">
                                <option value="">Select One</option>
                                <option value="true">Active</option>
                                <option value="false">Inactive</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"></div>
                        <div className="col-md-5">
                            <input type="submit" onClick={UpdateCourses}
                                   className="form-control btn btn-outline-success"
                                   value="Save Change"/>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-5">
                            <div className="text-center">
                                {msg ?
                                    <div className="alert alert-success alert-dismissable text-center">
                                        <button aria-hidden="true" data-dismiss="alert" className="close"
                                                type="button">&times;</button>
                                        {msg}
                                    </div> : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default UpdateCourse;