import React, {Fragment, useState} from 'react';
import RestApi from "../../api/restApi";

const AppURL = process.env.REACT_APP_BASE_URL;

function AddCourse(props) {
    const [msg, setMsg] = useState("");
    const [curriculumId, setCurriculumId] = useState();
    const [description, setDescription] = useState();
    const [teacherId, setTeacherId] = useState();
    const [disciplineId, setDisciplineId] = useState();
    const [name, setName] = useState();
    const [discountedPrice, setDiscountedPrice] = useState();
    const [mrp, setMrp] = useState();
    const [tp, setTp] = useState();
    const [programId, setProgramId] = useState();
    const [isExclusiveLiveCourse, setIsExclusiveLiveCourse] = useState();
    const [isPopular, setIsPopular] = useState();

    const {curriculum, discipline, program, teacher, checkStatus} = props;
    let token = sessionStorage.getItem('authentation');

    const AddCourses = () => {
        let data = {
            curriculumId: curriculumId,
            teacherId: teacherId,
            description: description,
            disciplineId: disciplineId,
            name: name,
            isExclusiveLiveCourse: isExclusiveLiveCourse,
            isPopular: isPopular,
            price: {
                discountedPrice: discountedPrice,
                mrp: mrp,
                tp: tp
            },
            programId: programId,
        };
        let staticURL = AppURL + 'admin/course';
        RestApi.postFunction(staticURL, data, token).then(result => {
            if (result.statusCode === 200) {
                setMsg("Course added successfully.");
                if (checkStatus === 1) {
                } else {
                    setInterval(function () {
                        window.location.replace('course');
                    }, 2000);
                }
            }
        });
    }


    return (
        <Fragment>
            <div className="container">
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Program Name </strong></div>
                        <div className="col-md-5">
                            <select onChange={(e) => (setProgramId(e.target.value))} className="form-control">
                                <option value="">Select One</option>
                                {program?.programs?.map((v_programs, index) => (
                                    <option key={index} value={v_programs.id}>{v_programs.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Curriculum Name </strong></div>
                        <div className="col-md-5">
                            <select onChange={(e) => (setCurriculumId(e.target.value))} className="form-control">
                                <option value="">Select One</option>
                                {curriculum?.curriculums?.map((v_curriculum, index) => (
                                    <option key={index} value={v_curriculum.id}>{v_curriculum.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Discipline Name </strong></div>
                        <div className="col-md-5">
                            <select onChange={(e) => (setDisciplineId(e.target.value))} className="form-control">
                                <option value="">Select One</option>
                                {discipline?.disciplines?.map((v_discipline, index) => (
                                    <option key={index} value={v_discipline.id}>{v_discipline.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Course Name </strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setName(e.target.value)}
                                   placeholder="Name"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Course Description </strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setDescription(e.target.value)}
                                   placeholder="Description"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Teacher Name </strong></div>
                        <div className="col-md-5">
                            <select onChange={(e) => (setTeacherId(e.target.value))} className="form-control">
                                <option value="">Select One</option>
                                {teacher?.teachers?.map((v_teacher, index) => (
                                    <option key={index} value={v_teacher.id}>{v_teacher.fullName}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>MRP Price </strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setMrp(e.target.value)}
                                   placeholder="MRP Price"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>TP Price </strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setTp(e.target.value)}
                                   placeholder="TP Price"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Discounted Price </strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setDiscountedPrice(e.target.value)}
                                   placeholder="Discounted Price"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Popular Course </strong></div>
                        <div className="col-md-5">
                            <select onChange={(e) => setIsPopular(e.target.value)} className="form-control">
                                <option value="">Select One</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>ExclusiveLive Course </strong></div>
                        <div className="col-md-5">
                            <select onChange={(e) => setIsExclusiveLiveCourse(e.target.value)} className="form-control">
                                <option value="">Select One</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"></div>
                        <div className="col-md-5">
                            <input type="submit" onClick={AddCourses}
                                   className="form-control btn btn-outline-success"
                                   value="Save"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-5">
                            <div className="text-center">
                                {msg ?
                                    <div className="alert alert-success alert-dismissable text-center">
                                        <button aria-hidden="true" data-dismiss="alert" className="close"
                                                type="button">&times;</button>
                                        {msg}
                                    </div> : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default AddCourse;