import React, {Fragment, useState} from 'react';

import RestApi from "../../api/restApi";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;


function AddCourseEnrolled(props) {
    const [msg, setMsg] = useState("");
    const [courseId, setCourseId] = useState();
    const [dueAmount, setDueAmount] = useState();
    const [paidAmount, setPaidAmount] = useState();
    const [paymentMode, setPaymentMode] = useState();
    const [paymentStatus, setPaymentStatus] = useState();
    const [programId, setProgramId] = useState();
    const [studentId, setStudentId] = useState();
    const {student, course, program, checkStatus} = props;

    const addCourseEnrolled = () => {
        let data = {
            courseId: courseId,
            dueAmount: dueAmount,
            paidAmount: paidAmount,
            paymentMode: paymentMode,
            programId: programId,
            paymentStatus: paymentStatus,
            studentId: studentId
        };
        let staticURL = AppURL + 'admin/course-enrolled';
        RestApi.postFunction(staticURL, data, token).then(result => {
            if (result.statusCode === 200) {
                setMsg("Course enrolled successfully.");
                if (checkStatus === 1) {
                } else {
                    setInterval(function () {
                        window.location.replace('course-enrolled');
                    }, 2000);
                }
            }
        });
    }

    return (
        <Fragment>
            <div className="container">
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Program Name</strong></div>
                        <div className="col-md-5">
                            <select onChange={(e) => (setProgramId(e.target.value))} className="form-control">
                                <option value="">Select One</option>
                                {program?.programs?.map((v_programs, index) => (
                                    <option key={index} value={v_programs.id}>{v_programs.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Course Name</strong></div>
                        <div className="col-md-5">
                            <select onChange={(e) => setCourseId(e.target.value)} className="form-control">
                                <option value="">Select One</option>
                                {course?.courses?.map((v_course, index) => (
                                    <option key={index} value={v_course.id}>{v_course.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Student Name</strong></div>
                        <div className="col-md-5">
                            <select onChange={(e) => (setStudentId(e.target.value))} className="form-control">
                                <option value="">Select One</option>
                                {student?.student?.map((v_student, index) => (
                                    <option key={index} value={v_student.id}>{v_student.fullName}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Enrollment Status</strong></div>
                        <div className="col-md-5">
                            <select onChange={(e) => setPaymentStatus(e.target.value)} className="form-control">
                                <option value="">Select One</option>
                                <option value="true">Paid</option>
                                <option value="false">Free</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Paid Amount</strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setPaidAmount(e.target.value)}
                                   placeholder="Paid Amount"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Due Amount</strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setDueAmount(e.target.value)}
                                   placeholder="Due Amount"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Payment Mode</strong></div>
                        <div className="col-md-5">
                            <select onChange={(e) => setPaymentMode(e.target.value)} className="form-control">
                                <option value="">Payment Mode</option>
                                <option value="bKash">bKash</option>
                                <option value="Nagad">Nagad</option>
                                <option value="SSL">SSL</option>
                                <option value="Visa">Visa</option>
                                <option value="Master">Master</option>
                                <option value="Cash">Cash</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"></div>
                        <div className="col-md-5">
                            <input type="submit" onClick={addCourseEnrolled}
                                   className="form-control btn btn-outline-success"
                                   value="Save"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-5">
                            <div className="text-center">
                                {msg ?
                                    <div className="alert alert-success alert-dismissable text-center">
                                        <button aria-hidden="true" data-dismiss="alert" className="close"
                                                type="button">&times;</button>
                                        {msg}
                                    </div> : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default AddCourseEnrolled;