import React, {Fragment, useEffect, useState} from 'react';
import AddDiscipline from "../../Discipline/AddDiscipline";
import UpdateDiscipline from "../../Discipline/UpdateDiscipline";
import RestApi from "../../../api/restApi";
import UploadDisciplineContent from "../../Discipline/UploadDisciplineContent";
import {NavLink, useParams} from "react-router-dom";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function DisciplineManagement(props) {
    const [viewDiscipline, setViewDiscipline] = useState();
    const [editDiscipline, setEditDiscipline] = useState();
    const [childCurriculum, setChildCurriculum] = useState();
    const [childProgram, setChildProgram] = useState();
    const [filterByProgram, setFilterByProgram] = useState([]);
    const [filterByCurriculum, setFilterByCurriculum] = useState([]);
    const [filterByTrueFalse, setFilterByTrueFalse] = useState([]);
    const [filterByDisciplineName, setFilterByDisciplineName] = useState([]);
    const [disciplineID, setDisciplineID] = useState();
    const [id, setId] = useState();
    let {programID} = useParams();
    useEffect(() => {
        let staticURL = AppURL + 'admin/discipline?programId=' + programID;
        RestApi.getFunction(staticURL, token).then(result => {
            setViewDiscipline(result);
            console.log(result);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + 'admin/curriculum';
        RestApi.getFunction(staticURL, token).then(result => {
            setChildCurriculum(result);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + 'program';
        RestApi.getFunction(staticURL, token).then(result => {
            setChildProgram(result);
        });
    }, []);

    const searchByDisciplineName = () => {
        let staticURL = AppURL + 'admin/discipline?name=' + filterByDisciplineName + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then(result => {
            setViewDiscipline(result);
        });
    }

    useEffect(() => {
        let programId1 = 'programId=' + filterByProgram;
        let curriculumId1 = '&curriculumId=' + filterByCurriculum;
        let isActive1 = '&isActive=' + filterByTrueFalse;
        let staticURL = AppURL + 'admin/discipline?' + programId1 + curriculumId1 + isActive1 + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then(result => {
            setViewDiscipline(result);
        });
    }, [filterByProgram, filterByCurriculum, filterByTrueFalse]);

    const getId = (props) => {
        setId(props);
        let staticURL = AppURL + 'admin/discipline/details?disciplineId=' + props;
        RestApi.getFunction(staticURL, token).then(result => {
            setEditDiscipline(result.discipline);
        });
    }

    const contentUpload = (props) => {
        setDisciplineID(props);
    }

    return (
        <Fragment>


            <div className="row">
                <div className="col-md-2">
                    <div className="input-group">
                        <input onChange={(e) => setFilterByDisciplineName(e.target.value)}
                               className="form-control py-2" type="text"
                               id="example-search-input" placeholder="Discipline Name"/>
                        <span className="input-group-append">
                                                        <button onClick={searchByDisciplineName}
                                                                className="btn btn-outline-success" type="button">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </span>
                    </div>
                </div>

                <div className="col-md-2">
                    <div className="input-group">
                        <select onChange={(e) => setFilterByCurriculum(e.target.value)}
                                className="form-control">
                            <option value="">Curriculum Name</option>
                            {childCurriculum?.curriculums?.map((v_curriculum, index) => (
                                <option key={index}
                                        value={v_curriculum.id}>{v_curriculum.name}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="col-md-2">
                    <div className="input-group">
                        <select className="form-control"
                                onChange={(e) => setFilterByProgram(e.target.value)}>
                            <option value="">Program Name</option>
                            {childProgram?.programs?.map((v_pro, index) => (
                                <option key={index} value={v_pro.id}>{v_pro.name}</option>
                            ))}
                        </select>
                    </div>
                </div>


                <div className="col-md-2">
                    <div className="input-group">
                        <select onChange={(e) => setFilterByTrueFalse(e.target.value)}
                                className="form-control">
                            <option value="">Active/Inactive</option>
                            <option value="true">Active</option>
                            <option value="false">Inactive</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-2">
                    <button
                        type="button"
                        className="btn btn-outline-success"
                        data-backdrop="static"
                        data-keyboard="false"
                        data-toggle="modal"
                        data-target="#add_modal11"
                        style={{width: "100%"}}>
                        <i className="fas fa-plus"></i> Discipline
                    </button>
                </div>
            </div>


            <div className="card-body">
                <table className="table table-hover">
                    <thead>
                    <tr>
                        <th>SL NO</th>
                        <th>Discipline Name</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {viewDiscipline?.disciplines?.map((v_disciplines, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                                <NavLink to={`/discipline-details/${v_disciplines.id}`}>
                                                            <span
                                                                style={{color: 'black'}}>{v_disciplines.name}</span>
                                </NavLink>
                            </td>
                            <td>{v_disciplines.isActive ? "Active" : "Inactive"}</td>
                            <td>
                                                        <span
                                                            style={{fontSize: 10, marginLeft: 10, cursor: 'pointer'}}
                                                            className="btn btn-outline-success"
                                                            data-backdrop="static"
                                                            data-keyboard="false" data-toggle="modal"
                                                            data-target="#content_modal11"
                                                            onClick={() => contentUpload(v_disciplines.id)}
                                                            title="Video upload">
                                                            Content Upload
                                                        </span>

                                <span
                                    style={{fontSize: 10, marginLeft: 10, cursor: 'pointer'}}
                                    className="btn btn-outline-success"
                                    data-backdrop="static"
                                    data-keyboard="false"
                                    data-toggle="modal"
                                    data-target="#update_modal11"
                                    onClick={() => getId(v_disciplines.id)}
                                    title="Update emp basic info">
                                                            <i className="fas fa-edit"></i>
                                                        </span>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>

                <div className="modal fade" id="add_modal11">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="title">Add Discipline</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <div id="load_from">
                                    <AddDiscipline checkStatus={1} curriculum={childCurriculum} program={childProgram}/>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="update_modal11">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="title">Update Discipline</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <div id="load_from">
                                    <UpdateDiscipline checkStatus={1} discipline={editDiscipline} id={id}
                                                      curriculum={childCurriculum}
                                                      program={childProgram}/>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="content_modal11">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="title">Upload Discipline content</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <div id="load_from">
                                    <UploadDisciplineContent checkStatus={1} disciplineID={disciplineID}/>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default DisciplineManagement;