import React, {Fragment} from 'react';
import {PageHeader} from "antd";
import UpdateDiscipline from "../UpdateDiscipline";


function DisciplineInformation(props) {
    const {disciplineDetails, disciplineID, childCurriculum, childProgram} = props;
    return (
        <Fragment>
            <div className="form-group">
                <div className="row">
                    <div className="col-md-10">
                        <PageHeader onBack={() => window.history.back()} title="Discipline Information"
                                    style={{padding: "10px 0px"}}/>
                    </div>
                    <div className="col-md-2">
                        <button
                            type="button"
                            className="btn btn-outline-success"
                            data-backdrop="static"
                            data-keyboard="false"
                            data-toggle="modal"
                            data-target="#update_discipline"
                            style={{width: "100%", padding: "5px 0px", marginTop: "7px"}}>
                            Edit
                        </button>
                    </div>
                </div>
            </div>
            <div className="mb-0">
                <div className="details-area">
                    <div className="pt-4">
                        <div className="single">
                            <span className="info-name">Discipline Name</span>
                            <span className="info-desc">{disciplineDetails?.name}</span>
                        </div>
                        <div className="single">
                            <span className="info-name">Curriculum Name</span>
                            <span className="info-desc">{disciplineDetails?.curriculum?.name}</span>
                        </div>
                        <div className="single">
                            <span className="info-name">Program Name</span>
                            <span className="info-desc">{disciplineDetails?.program?.name}</span>
                        </div>
                        <div className="single">
                            <span className="info-name">Status</span>
                            <span className="info-desc">{disciplineDetails?.isActive ? "Active" : "Inactive"}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="update_discipline">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Update Discipline</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <UpdateDiscipline checkStatus={1} discipline={disciplineDetails} id={disciplineID}
                                                  curriculum={childCurriculum}
                                                  program={childProgram}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default DisciplineInformation;