import React, {Fragment, useEffect, useState} from 'react';
import UpdateTeacher from "./UpdateTeacher";
import AddTeacher from "./AddTeacher";
import RestApi from "../../api/restApi";
import {NavLink} from "react-router-dom";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function TeacherHome(props) {
    const [viewAllTeachers, setViewAllTeachers] = useState();
    const [editAllTeachers, setEditAllTeachers] = useState();
    const [teacherName, setTeacherName] = useState();
    const [mobileNumber, setMobileNumber] = useState();
    const [teacherDetails, setTeacherDetails] = useState();
    const [id, setId] = useState();

    useEffect(() => {
        let staticURL = AppURL + "teacher";
        RestApi.getFunction(staticURL, token).then((result) => {
            setViewAllTeachers(result);
        });
    }, []);


    const getId = (props) => {
        setId(props);
        let staticURL = AppURL + "teacher/details?id=" + props;
        RestApi.getFunction(staticURL, token).then((result) => {
            setEditAllTeachers(result.teacher);
        });
    };

    const searchByTeacherName = () => {
        let staticURL = AppURL + 'teacher?fullName=' + teacherName + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then(result => {
            setViewAllTeachers(result);
        });
    }

    const searchByMobileNumber = () => {
        let staticURL = AppURL + 'teacher?mobileNumber=' + mobileNumber + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then(result => {
            setViewAllTeachers(result);
        });
    }


    return (
        <Fragment>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Teacher</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <section className="col-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <div className="input-group">
                                                    <input onChange={(e) => setTeacherName(e.target.value)}
                                                           className="form-control py-2" type="text"
                                                           id="example-search-input" placeholder="Full Name"/>
                                                    <span className="input-group-append">
                                                        <button onClick={searchByTeacherName}
                                                                className="btn btn-outline-success" type="button">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="input-group">
                                                    <input onChange={(e) => setMobileNumber(e.target.value)}
                                                           className="form-control py-2" type="text"
                                                           id="example-search-input" placeholder="Mobile Number"/>
                                                    <span className="input-group-append">
                                                        <button onClick={searchByMobileNumber}
                                                                className="btn btn-outline-success" type="button">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-2">
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-success"
                                                    data-backdrop="static"
                                                    data-keyboard="false"
                                                    data-toggle="modal"
                                                    data-target="#add_modal"
                                                    style={{width: "100%"}}>
                                                    <i className="fas fa-plus"></i> Teacher
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <table className="table table-hover">
                                            <thead>
                                            <tr>
                                                <th>SL NO</th>
                                                <th>Teacher Name</th>
                                                <th>Mobile Number</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {viewAllTeachers?.teachers?.map((v_teachers, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <NavLink to={`/teacher-details/${v_teachers.id}`}>
                                                            <span
                                                                style={{color: 'black'}}>{v_teachers.fullName}</span>
                                                        </NavLink>
                                                    </td>
                                                    <td>{v_teachers.mobileNumber}</td>
                                                    <td>{v_teachers?.isActive ? "Active" : "Inactive"}</td>
                                                    <td>
                                                         <span
                                                             style={{fontSize: 10, marginLeft: 10, cursor: 'pointer'}}
                                                             className="btn btn-outline-success" data-backdrop="static"
                                                             data-keyboard="false" data-toggle="modal"
                                                             data-target="#update_modal"
                                                             onClick={() => getId(v_teachers.id)}
                                                             title="Upload Profile">
                                                                  Upload Profile
                                                         </span>

                                                        <span
                                                            style={{fontSize: 10, marginLeft: 10, cursor: 'pointer'}}
                                                            className="btn btn-outline-success" data-backdrop="static"
                                                            data-keyboard="false" data-toggle="modal"
                                                            data-target="#update_modal1"
                                                            onClick={() => getId(v_teachers.id)}
                                                            title="Update emp basic info">
                                                            <i className="fas fa-edit"></i>
                                                 </span>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </div>

            <div className="modal fade" id="add_modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Add Teacher</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <AddTeacher/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="update_modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Update Teacher Profile</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <UpdateTeacher id={id}
                                               teacher={editAllTeachers}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default TeacherHome;