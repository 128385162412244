import React, {Fragment} from 'react';
import Header from "../../includes/Header";
import LeftMenu from "../../includes/LeftMenu";
import Footer from "../../includes/Footer";
import QuestionHome from "./QuestionHome";

function Question(props) {
    return (
        <Fragment>
            <Header/>
            <LeftMenu/>
            <QuestionHome/>
            <Footer/>
        </Fragment>
    );
}

export default Question;