import React, {Fragment, useState} from 'react';
import {PageHeader} from "antd";
import RestApi from "../../../api/restApi";
import ReactPaginate from "react-paginate";
import UpdateTeacher from "../../Teacher/UpdateTeacher";
import TeacherDetails from "../../Teacher/TeacherDetails";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function TeacherManagement(props) {
    const {courseTeacher, courseID} = props;
    const [id, setId] = useState();
    const [editAllTeachers, setEditAllTeachers] = useState();
    const [teacherDetails, setTeacherDetails] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const usersPerPage = 5;
    const pagesVisited = pageNumber * usersPerPage;

    const viewTeacher = courseTeacher?.teachers?.slice(pagesVisited, pagesVisited + usersPerPage);
    const pageCount = Math.ceil(courseTeacher?.teachers?.length / usersPerPage);
    const changePage = ({selected}) => {
        setPageNumber(selected);
    };

    const getId = (props) => {
        setId(props);
        let staticURL = AppURL + "teacher/details?id=" + props;
        RestApi.getFunction(staticURL, token).then((result) => {
            setEditAllTeachers(result.teacher);
        });
    };

    const teacherDetailsView = (props) => {
        let staticURL = AppURL + "teacher/profile-details?teacherId=" + props;
        RestApi.getFunction(staticURL, token).then((result) => {
            setTeacherDetails(result.teacherDetails);
        });
    };

    const displayStudent = viewTeacher?.map((v_teachers, index) => {
        return (
            <tr key={index}>
                <td>{index + 1}</td>
                <td>{v_teachers.fullName}</td>
                <td>{v_teachers.mobileNumber}</td>
                <td>
                    <span style={{fontSize: 10, marginLeft: 10, cursor: 'pointer'}} className="btn btn-outline-success"
                          data-backdrop="static" data-keyboard="false" data-toggle="modal"
                          data-target="#details_modal1"
                          onClick={() => teacherDetailsView(v_teachers.id)}
                          title="Teacher Details"> View Details
                     </span>
                </td>
                <td>{v_teachers?.isActive ? "Active" : "Inactive"}</td>
                <td>
                    <span
                        style={{fontSize: 10, marginLeft: 10, cursor: 'pointer'}}
                        className="btn btn-outline-success" data-backdrop="static"
                        data-keyboard="false" data-toggle="modal"
                        data-target="#update_modal1"
                        onClick={() => getId(v_teachers.id)}
                        title="Upload Profile">Upload Profile
                    </span>
                </td>
            </tr>
        );
    });

    return (
        <Fragment>
            <div className="form-group">
                <div className="row">
                    <div className="col-md-12">
                        <PageHeader onBack={() => window.history.back()} title="Teacher Management"
                                    style={{padding: "10px 0px"}}/>
                    </div>
                </div>
            </div>
            <div className="mb-0">
                <div className="details-area">
                    <div className="pt-4">
                        <table className="table table-hover">
                            <thead>
                            <tr>
                                <th>SL NO</th>
                                <th>Teacher Name</th>
                                <th>Mobile Number</th>
                                <th>Teacher Details</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {displayStudent}
                            </tbody>
                        </table>
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        />
                    </div>
                </div>
            </div>
            <div className="modal fade" id="update_modal1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Update Teacher Profile</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <UpdateTeacher id={id}
                                               teacher={editAllTeachers}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="details_modal1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Teacher Details Information</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <TeacherDetails teacherDetails={teacherDetails}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default TeacherManagement;