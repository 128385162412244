import React, {Fragment} from 'react';
import Header from "../../includes/Header";
import LeftMenu from "../../includes/LeftMenu";
import Footer from "../../includes/Footer";
import ProgramDetails from "./ProgramDetails";

function ProgramDetailsHome(props) {
    return (
        <Fragment>
            <Header/>
            <LeftMenu/>
            <ProgramDetails/>
            <Footer/>
        </Fragment>
    );
}

export default ProgramDetailsHome;