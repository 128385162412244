import React, {Fragment} from 'react';
import {Routes, Route} from 'react-router-dom';
import Login from "./Login";
import Dashboard from "./Dashboard";
import Program from "./component/Program/Program";
import Course from "./component/Course/Course";
import Curriculum from "./component/Curriculum/Curriculum";
import Discipline from "./component/Discipline/Discipline";
import Content from "./component/Content/Content";
import Chapter from "./component/Chapter/Chapter";
import Teacher from "./component/Teacher/Teacher";
import CourseSyllabus from "./component/CourseSyllabus/CourseSyllabus";
import CourseEnrolled from "./component/CourseEnrolled/CourseEnrolled";
import Student from "./component/Student/Student";
import Question from "./component/Question/Question";
import Quiz from "./component/Quiz/Quiz";
import CourseDetailsHome from "./component/Course/CourseDetailsHome";
import BookUpload from "./component/BookUpload/BookUpload";
import SMSNotification from "./component/Notification/sms/SMSNotification";
import EmailNotification from "./component/Notification/email/EmailNotification";
import PushNotification from "./component/Notification/push/PushNotification";
import ProgramDetailsHome from "./component/Program/ProgramDetailsHome";
import CurriculumDetailsHome from "./component/Curriculum/CurriculumDetailsHome";
import DisciplineDetails from "./component/Discipline/DisciplineDetails";
import TeacherDetails from "./component/Teacher/TeacherDetails";

function AppRoute(props) {
    let token = sessionStorage.getItem('authentation');
    if (token) {
        return (
            <Fragment>
                <Routes>
                    <Route path="/" element={<Login/>}/>
                    <Route path="admin-dashboard" element={<Dashboard/>}/>
                    <Route path="program" element={<Program/>}/>
                    <Route path="curriculum" element={<Curriculum/>}/>
                    <Route path="course" element={<Course/>}/>
                    <Route path="discipline" element={<Discipline/>}/>
                    <Route path="content" element={<Content/>}/>
                    <Route path="chapter" element={<Chapter/>}/>
                    <Route path="teacher" element={<Teacher/>}/>
                    <Route path="course-syllabus" element={<CourseSyllabus/>}/>
                    <Route path="course-enrolled" element={<CourseEnrolled/>}/>
                    <Route path="student" element={<Student/>}/>
                    <Route path="question" element={<Question/>}/>
                    <Route path="quiz" element={<Quiz/>}/>
                    <Route path="book-upload" element={<BookUpload/>}/>
                    <Route path="sms-notification" element={<SMSNotification/>}/>
                    <Route path="email-notification" element={<EmailNotification/>}/>
                    <Route path="push-notification" element={<PushNotification/>}/>
                    <Route path="course-details/:courseID" element={<CourseDetailsHome/>}/>
                    <Route path="program-details/:programID" element={<ProgramDetailsHome/>}/>
                    <Route path="curriculum-details/:curriculumID" element={<CurriculumDetailsHome/>}/>
                    <Route path="discipline-details/:disciplineID" element={<DisciplineDetails/>}/>
                    <Route path="teacher-details/:teacherID" element={<TeacherDetails/>}/>
                </Routes>
            </Fragment>
        );
    } else {
        return (
            <Fragment>
                <Routes>
                    <Route path="*" element={<Login/>}/>
                </Routes>
            </Fragment>
        );
    }
}

export default AppRoute;