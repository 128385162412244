import React, {Fragment, useState} from 'react';
import axios from "axios";
import {useForm} from "react-hook-form";

const AppURL = process.env.REACT_APP_AUTH_URL;

function Login() {
    const [message, setMessage] = useState();
    const {register, handleSubmit} = useForm();

    const loginVerification = (data) => {
        let staticURL = AppURL + 'admin/login';
        const {email, password} = data;
        if (email && password) {
            axios({
                method: 'POST',
                url: staticURL,
                data: {email: email, password: password}
            }).then(response => {
                if (response.data.statusCode === 200) {
                    sessionStorage.setItem('authentation', response.data.token);
                    setInterval(function () {
                        window.location.replace('/admin-dashboard');
                    }, 1000);
                } else {
                    setInterval(function () {
                        window.location.replace('/');
                    }, 1000);
                }
            }).catch(error => {
                setMessage("Your email or password invalid");
            });
        } else {
            setMessage("Your email and password are required!");
        }
    }

    return (
        <Fragment>
            <html>
            <head>
                <title>E-learning | Log in</title>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <link rel="stylesheet" href="/admin_assets/plugins/fontawesome-free/css/all.min.css"/>
                <link rel="stylesheet"
                      href="/admin_assets/plugins/icheck-bootstrap/icheck-bootstrap.min.css"/>
                <link rel="stylesheet" href="/admin_assetsdist/css/adminlte.min.css"/>
            </head>
            <body>
            <form onSubmit={handleSubmit(loginVerification)}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-md-1"></div>
                                <div className="col-md-8">
                                    <img alt="logo"
                                         src="https://admin.jachai-staging.com/static/media/logo.1c44a2d1.svg"
                                         style={{height: 45, marginTop: 45}}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-1"></div>
                                <div className="col-md-8">
                                    <p style={{fontSize: 30, marginTop: 100}}>
                                        <b>Log in</b>
                                    </p>
                                </div>
                            </div>

                            <div className="row" style={{marginTop: 35}}>
                                <div className="col-md-1"></div>
                                <div className="col-md-10">
                                    <label>Email Address</label>
                                    <input type="email" {...register("email")}
                                           placeholder="eg@gmail.com" className="form-control"/>
                                </div>
                            </div>

                            <div className="row" style={{marginTop: 30}}>
                                <div className="col-md-1"></div>
                                <div className="col-md-10">
                                    <label>Password</label>
                                    <input type="password" {...register("password")} placeholder="Enter your password"
                                           className="form-control"/>
                                </div>
                            </div>
                            <div className="row" style={{marginTop: 30}}>
                                <div className="col-md-1"></div>
                                <div className="col-md-10">
                                    <input style={{width: '100%'}} type="submit"
                                           value="Log in"
                                           className="btn btn-danger"/>
                                </div>
                            </div>
                            <div className="row" style={{marginTop: 10}}>
                                <div className="col-md-1"></div>
                                <div className="col-md-10" style={{textAlign: 'center'}}>
                                    <p style={{color: 'red', textAlign: 'center'}}>{message}</p>
                                </div>
                            </div>
                            <div className="row" style={{marginTop: 30}}>
                                <div className="col-md-1"></div>
                                <div className="col-md-10" style={{textAlign: 'center'}}>
                                    Don't have an account ? <span>Sign up</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8" style={{backgroundColor: '#E0E7FF'}}>
                            <div className="row" style={{marginTop: '20%'}}>
                                <div className="col-md-5">
                                    <img alt="logo" style={{marginLeft: '100%'}} src="login-logo.PNG"/>
                                </div>
                            </div>
                            <div className="row" style={{marginTop: '18%'}}></div>
                        </div>
                    </div>
                </div>
            </form>
            <script src="/admin_assets/plugins/jquery/jquery.min.js"></script>
            <script src="/admin_assets/plugins/bootstrap/js/bootstrap.bundle.min.js"></script>
            <script src="/admin_assets/dist/js/adminlte.min.js"></script>
            </body>
            </html>
        </Fragment>
    );
}

export default Login;