import React, {Fragment, useState} from 'react';
import RestApi from "../../api/restApi";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function AddContent(props) {
    const [chapterId, setChapterId] = useState();
    const [description, setDescription] = useState();
    const [name, setName] = useState();
    const [type, setType] = useState();
    const [url, setUrl] = useState();
    const [msg, setMsg] = useState();
    const {chapter} = props;

    const AddContents = () => {
        let data = {chapterId: chapterId, description: description, name: name, url: url, type: type};
        let staticURL = AppURL + 'admin/content';
        RestApi.postFunction(staticURL, data, token).then(result => {
            if (result.statusCode === 200) {
                setMsg("Content added successfully.");
                setInterval(function () {
                    window.location.replace('content');
                }, 2000);
            }
        });
    }

    return (
        <Fragment>
            <div className="container">
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Chapter Name:</strong></div>
                        <div className="col-md-5">
                            <select onChange={(e) => (setChapterId(e.target.value))} className="form-control">
                                <option value="">Select One</option>
                                {chapter?.chapters?.map((v_chapters, index) => (
                                    <option key={index} value={v_chapters.id}>{v_chapters.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Description:</strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => (setDescription(e.target.value))}
                                   placeholder="Description ...."
                                   className="form-control"/>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Name:</strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => (setName(e.target.value))}
                                   placeholder="Name ...."
                                   className="form-control"/>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Content Type:</strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => (setType(e.target.value))}
                                   placeholder="Content Type ...."
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Content Url:</strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => (setUrl(e.target.value))}
                                   placeholder="Url ...."
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"></div>
                        <div className="col-md-5">
                            <input type="submit" onClick={AddContents}
                                   className="form-control btn btn-outline-success"
                                   value="Save"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-5">
                            <div className="text-center">
                                {msg ?
                                    <div className="alert alert-success alert-dismissable text-center">
                                        <button aria-hidden="true" data-dismiss="alert" className="close"
                                                type="button">&times;</button>
                                        {msg}
                                    </div> : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default AddContent;