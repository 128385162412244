import React, {Fragment, useEffect, useState} from 'react';
import {PageHeader} from 'antd';
import RestApi from "../../../api/restApi";
import UpdateCourse from "../UpdateCourse";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function Details(props) {
    const {courseDetails, courseID} = props;
    const [childCurriculum, setChildCurriculum] = useState();
    const [childDiscipline, setChildDiscipline] = useState();
    const [childProgram, setChildProgram] = useState();
    const [childTeacher, setChildTeacher] = useState();

    useEffect(() => {
        let staticURL = AppURL + 'admin/curriculum';
        RestApi.getFunction(staticURL, token).then(result => {
            setChildCurriculum(result);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + 'admin/discipline';
        RestApi.getFunction(staticURL, token).then(result => {
            setChildDiscipline(result);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + 'program';
        RestApi.getFunction(staticURL, token).then(result => {
            setChildProgram(result);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + 'teacher';
        RestApi.getFunction(staticURL, token).then(result => {
            setChildTeacher(result);
        });
    }, []);

    return (
        <Fragment>
            <div className="form-group">
                <div className="row">
                    <div className="col-md-10">
                        <PageHeader className="site-page-header" onBack={() => window.history.back()}
                                    title="Course Management"
                                    style={{padding: "10px 0px"}}/>
                    </div>
                    <div className="col-md-2">
                        <button
                            type="button"
                            className="btn btn-outline-success"
                            data-backdrop="static"
                            data-keyboard="false"
                            data-toggle="modal"
                            data-target="#update_modal1"
                            style={{width: "100%", padding: "5px 0px", marginTop: "7px"}}>
                            Course Update
                        </button>
                    </div>
                </div>
            </div>
            <div className="mb-0">
                <div className="single">
                    <span className="info-name">Program Name</span>
                    <span className="info-desc"> {courseDetails?.program?.name}</span>
                </div>
                <div className="single">
                    <span className="info-name">Curriculum Name</span>
                    <span className="info-desc">{courseDetails?.curriculum?.name}</span>
                </div>
                <div className="single">
                    <span className="info-name">Discipline Name</span>
                    <span className="info-desc">{courseDetails?.discipline?.name}</span>
                </div>
                <div className="single">
                    <span className="info-name">Course Name</span>
                    <span className="info-desc">{courseDetails?.name}</span>
                </div>
                <div className="single">
                    <span className="info-name">Course Description</span>
                    <span className="info-desc">{courseDetails?.description}</span>
                </div>
                <div className="single">
                    <span className="info-name">Teacher Name</span>
                    <span className="info-desc">
                          {courseDetails?.teachersAssigned?.map((v_teacher, index) => (
                              <p key={index}>{v_teacher?.fullName}</p>
                          ))}
                    </span>
                </div>
                <div className="single">
                    <span className="info-name">MRP Price</span>
                    <span className="info-desc">{courseDetails?.price?.mrp}</span>
                </div>
                <div className="single">
                    <span className="info-name">TP Price </span>
                    <span className="info-desc">{courseDetails?.price?.tp}</span>
                </div>
                <div className="single">
                    <span className="info-name">Discounted Price</span>
                    <span className="info-desc"> {courseDetails?.price?.discountedPrice}</span>
                </div>

                <div className="single">
                    <span className="info-name">Popular Course</span>
                    <span className="info-desc">{courseDetails?.isPopular ? "Yes" : "No"}</span>
                </div>
                <div className="single">
                    <span className="info-name">ExclusiveLive Course</span>
                    <span className="info-desc">{courseDetails?.isExclusiveLiveCourse ? "Yes" : "No"}</span>
                </div>
                <div className="single">
                    <span className="info-name">Total Lectures</span>
                    <span className="info-desc">{courseDetails?.numberOfLectures}</span>
                </div>
                <div className="single">
                    <span className="info-name">Total Notes</span>
                    <span className="info-desc">{courseDetails?.numberOfNotes}</span>
                </div>

                <div className="single">
                    <span className="info-name">Total Cheat Sheet</span>
                    <span className="info-desc">{courseDetails?.numberOfCheatSheet}</span>
                </div>
                <div className="single">
                    <span className="info-name">Total Quizes</span>
                    <span className="info-desc">{courseDetails?.numberOfQuiz}</span>
                </div>
                <div className="single">
                    <span className="info-name">Total Videos</span>
                    <span className="info-desc">{courseDetails?.numberOfVideos}</span>
                </div>
                <div className="single">
                    <span className="info-name">Duration in Minutes </span>
                    <span className="info-desc">{courseDetails?.durationInMinutes}</span>
                </div>
                <div className="single">
                    <span className="info-name">Course Duration in Day</span>
                    <span className="info-desc">{courseDetails?.courseDurationInDay}</span>
                </div>
                <div className="single">
                    <span className="info-name">Status</span>
                    <span className="info-desc">{courseDetails?.isActive ? "Active" : "Inactive"}</span>
                </div>
            </div>

            <div className="modal fade" id="update_modal1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Update Course</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <UpdateCourse checkStatus={1} id={courseID} teacher={childTeacher}
                                              curriculum={childCurriculum}
                                              discipline={childDiscipline}
                                              program={childProgram} course={courseDetails}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Details;