import React, {Fragment, useEffect, useState} from 'react';
import AddBook from "./AddBook";
import RestApi from "../../api/restApi";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function BookUploadHome(props) {
    const [viewBook, setViewBook] = useState();
    const [id, setId] = useState();

    useEffect(() => {
        let staticURL = AppURL + "admin/book?page=0&limit=20";
        RestApi.getFunction(staticURL, token).then((result) => {
            setViewBook(result);
        });
    }, []);

    const getId = (props) => {
        setId(props);
    };

    return (
        <Fragment>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Book Upload</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <section className="col-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2"></div>
                                                <div className="col-md-2"></div>
                                                <div className="col-md-2"></div>
                                                <div className="col-md-2"></div>
                                                <div className="col-md-2"></div>
                                                <div className="col-md-2">
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-success"
                                                        data-backdrop="static"
                                                        data-keyboard="false"
                                                        data-toggle="modal"
                                                        data-target="#add_modal"
                                                        style={{width: "100%"}}>
                                                        <i className="fas fa-plus"></i> Book Upload
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <table className="table  table-hover">
                                            <thead>
                                            <tr>
                                                <th>SL NO</th>
                                                <th>Author Name</th>
                                                <th>Description</th>
                                                <th>language</th>
                                                <th>name</th>
                                                <th>numberOfDownloads</th>
                                                <th>numberOfPages</th>
                                                <th>price</th>
                                                <th>Action</th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            {viewBook?.bookResponses?.map((v_book, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{v_book?.authorName}</td>
                                                    <td>{v_book?.description}</td>
                                                    <td>{v_book?.language}</td>
                                                    <td>{v_book?.name}</td>
                                                    <td>{v_book?.numberOfDownloads}</td>
                                                    <td>{v_book?.numberOfPages}</td>
                                                    <td>{v_book?.price}</td>
                                                    <td>
                                                        <button
                                                            style={{fontSize: 10, marginLeft: 10, cursor: 'pointer'}}
                                                            className="btn btn-outline-success"
                                                            data-backdrop="static"
                                                            data-keyboard="false" data-toggle="modal"
                                                            data-target="#update_modal"
                                                            onClick={() => getId(v_book.id)}
                                                            title="Update Book">
                                                            <i className="fas fa-edit"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </div>

            <div className="modal fade" id="add_modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Book Upload</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <AddBook/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>


        </Fragment>
    );
}

export default BookUploadHome;