import React, {Fragment, useEffect, useState} from 'react';
import AddCourse from "../../Course/AddCourse";
import UpdateCourse from "../../Course/UpdateCourse";
import RestApi from "../../../api/restApi";
import {NavLink} from "react-router-dom";
import UploadCourseContent from "../../Course/UploadCourseContent";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function CourseManagement(props) {
    const [childCurriculum, setChildCurriculum] = useState();
    const [childDiscipline, setChildDiscipline] = useState();
    const [childProgram, setChildProgram] = useState();
    const [childTeacher, setChildTeacher] = useState();
    const [viewAllCourses, setViewAllCourses] = useState();
    const [editAllCourses, setEditAllCourses] = useState();
    const [courseID, setCourseID] = useState();
    const [courseName, setCourseName] = useState("");
    const [filterByTrueFalse, setFilterByTrueFalse] = useState([]);
    const [filterByPopular, setFilterByPopular] = useState([]);
    const [isExclusiveLiveCourse, setIsExclusiveLiveCourse] = useState([]);
    const [programId, setProgramId] = useState([]);
    const [curriculumId, setCurriculumId] = useState([]);
    const [teacherId, setTeacherId] = useState([]);
    const [disciplineId, setDisciplineId] = useState([]);
    const [id, setId] = useState();

    useEffect(() => {
        let staticURL = 'https://elearning.jachai-staging.com:443/api/v1/program/contents-by-type?type=course';
        RestApi.getFunction(staticURL, token).then(result => {
            setViewAllCourses(result);
            console.log(result);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + 'admin/curriculum';
        RestApi.getFunction(staticURL, token).then(result => {
            setChildCurriculum(result);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + 'admin/discipline';
        RestApi.getFunction(staticURL, token).then(result => {
            setChildDiscipline(result);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + 'program';
        RestApi.getFunction(staticURL, token).then(result => {
            setChildProgram(result);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + 'teacher';
        RestApi.getFunction(staticURL, token).then(result => {
            setChildTeacher(result);
        });
    }, []);

    const contentUpload = (props) => {
        setCourseID(props);
    }

    const getId = (props) => {
        setId(props);
        let staticURL = AppURL + 'admin/course/details?courseId=' + props;
        RestApi.getFunction(staticURL, token).then(result => {
            setEditAllCourses(result.course);
        });
    }

    const searchByCourseName = () => {
        let staticURL = AppURL + 'admin/course?name=' + courseName + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then(result => {
            setViewAllCourses(result);
        });
    }


    useEffect(() => {
        let isActive1 = 'isActive=' + filterByTrueFalse;
        let filterByPopular1 = '&isPopular=' + filterByPopular;
        let isExclusiveLiveCourse1 = '&isExclusiveLiveCourse=' + isExclusiveLiveCourse;
        let programId1 = '&programId=' + programId;
        let curriculumId1 = '&curriculumId=' + curriculumId;
        let disciplineId1 = '&disciplineId=' + disciplineId;
        let teacherId1 = '&teacherId=' + teacherId;
        let staticURL = AppURL + 'admin/course?' + isActive1 + filterByPopular1 + isExclusiveLiveCourse1 + programId1 + curriculumId1 + disciplineId1 + teacherId1 + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then((result) => {
            setViewAllCourses(result);
        });
    }, [filterByTrueFalse, filterByPopular, isExclusiveLiveCourse, programId, curriculumId, disciplineId, teacherId]);


    return (
        <Fragment>


            <div className="form-group">
                <div className="row">
                    <div className="col-md-2">
                        <div className="input-group">
                            <input onChange={(e) => setCourseName(e.target.value)}
                                   className="form-control py-2" type="text"
                                   id="example-search-input" placeholder="Course Name"/>
                            <span className="input-group-append">
                                                        <button onClick={searchByCourseName}
                                                                className="btn btn-outline-success" type="button">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </span>
                        </div>
                    </div>

                    <div className="col-md-2">
                        <div className="input-group">
                            <select onChange={(e) => setFilterByTrueFalse(e.target.value)}
                                    className="form-control">
                                <option value="">Active/Inactive</option>
                                <option value="true">Active</option>
                                <option value="false">Inactive</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-md-2">
                        <div className="input-group">
                            <select onChange={(e) => setFilterByPopular(e.target.value)}
                                    className="form-control">
                                <option value="">Popular Courses</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-md-2">
                        <div className="input-group">
                            <select
                                onChange={(e) => setIsExclusiveLiveCourse(e.target.value)}
                                className="form-control">
                                <option value="">Exclusive Live Course</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-md-2">
                        <div className="input-group">
                            <select onChange={(e) => setProgramId(e.target.value)}
                                    className="form-control">
                                <option value="">Program Name</option>
                                {childProgram?.programs?.map((v_program, index) => (
                                    <option key={index}
                                            value={v_program.id}>{v_program.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="col-md-2">
                        <div className="input-group">
                            <select onChange={(e) => setCurriculumId(e.target.value)}
                                    className="form-control">
                                <option value="">Curriculum Name</option>
                                <option value="">Select One</option>
                                {childCurriculum?.curriculums?.map((v_curriculum, index) => (
                                    <option key={index}
                                            value={v_curriculum.id}>{v_curriculum.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-md-2">
                        <div className="input-group">
                            <select onChange={(e) => setTeacherId(e.target.value)}
                                    className="form-control">
                                <option value="">Teacher Name</option>
                                <option value="">Select One</option>
                                {childTeacher?.teachers?.map((v_teacher, index) => (
                                    <option key={index}
                                            value={v_teacher.id}>{v_teacher.fullName}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="input-group">
                            <select onChange={(e) => setDisciplineId(e.target.value)}
                                    className="form-control">
                                <option value="">Discipline Name</option>
                                <option value="">Select One</option>
                                {childDiscipline?.disciplines?.map((v_discipline, index) => (
                                    <option key={index}
                                            value={v_discipline.id}>{v_discipline.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-2"></div>
                    <div className="col-md-2"></div>
                    <div className="col-md-2">
                        <button
                            type="button"
                            className="btn btn-outline-success"
                            data-backdrop="static"
                            data-keyboard="false"
                            data-toggle="modal"
                            data-target="#add_modal"
                            style={{width: "100%"}}>
                            <i className="fas fa-plus"></i> Course
                        </button>
                    </div>
                </div>
            </div>

            <div className="card-body">
                <table className="table table-hover">
                    <thead>
                    <tr>
                        <th>SL NO</th>
                        <th>Course Name</th>
                        <th>MRP Price</th>
                        <th>TP Price</th>
                        <th>Discounted Price</th>
                        <th>Total Enrolled Student</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {viewAllCourses?.programWithContentsResponseList?.map((v_courses, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                                <NavLink to={`/course-details/${v_courses.id}`}>
                                                            <span
                                                                style={{color: 'black'}}>{v_courses.name?.substring(0, 60)}</span>
                                </NavLink>
                            </td>
                            <td>{v_courses.price?.mrp}</td>
                            <td>{v_courses.price?.tp}</td>
                            <td>{v_courses.price?.discountedPrice}</td>
                            <td>{v_courses?.numberOfEnrolled}</td>
                            <td>{v_courses.isActive ? "Active" : "Inactive"}</td>
                            <td style={{width: '260px'}}>
                                <button style={{fontSize: 10, marginLeft: 10}}
                                        className="btn btn-outline-success"
                                        data-backdrop="static"
                                        data-keyboard="false" data-toggle="modal"
                                        data-target="#update_modal"
                                        onClick={() => getId(v_courses.id)}
                                        title="Update emp basic info">
                                    <i className="fas fa-edit"></i>
                                </button>

                                <span
                                    style={{
                                        fontSize: 10,
                                        marginLeft: 10,
                                        cursor: 'pointer'
                                    }}
                                    className="btn btn-outline-success"
                                    data-backdrop="static"
                                    data-keyboard="false" data-toggle="modal"
                                    data-target="#content_modal"
                                    onClick={() => contentUpload(v_courses.id)}
                                    title="Video upload">
                                                            Content Upload
                                                        </span>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className="modal fade" id="add_modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Add Course</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <AddCourse checkStatus={0} curriculum={childCurriculum} teacher={childTeacher}
                                           discipline={childDiscipline}
                                           program={childProgram}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="update_modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Update Course</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <UpdateCourse checkStatus={0} id={id} teacher={childTeacher}
                                              curriculum={childCurriculum}
                                              discipline={childDiscipline}
                                              program={childProgram} course={editAllCourses}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="content_modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Upload Course Content</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <UploadCourseContent checkStatus={0} courseID={courseID}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default CourseManagement;