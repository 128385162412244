import React, {Fragment, useEffect, useState} from 'react';
import {PageHeader} from "antd";
import {useParams} from "react-router-dom";
import RestApi from "../../../api/restApi";
import UpdateCurriculum from "../UpdateCurriculum";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function CurriculumInformation(props) {
    const [childProgram, setChildProgram] = useState();
    const [curriculumDetails, setCurriculumDetails] = useState([]);
    let {curriculumID} = useParams();

    useEffect(() => {
        let staticURL = AppURL + "admin/curriculum/details?curriculumId=" + curriculumID;
        RestApi.getFunction(staticURL, token).then((result) => {
            setCurriculumDetails(result.curriculum);
        });
    }, [curriculumID]);

    useEffect(() => {
        let staticURL = AppURL + 'program';
        RestApi.getFunction(staticURL, token).then(result => {
            setChildProgram(result);
        });
    }, []);

    return (
        <Fragment>
            <div className="form-group">
                <div className="row">
                    <div className="col-md-10">
                        <PageHeader onBack={() => window.history.back()} title="Curriculum Information"
                                    style={{padding: "10px 0px"}}/>
                    </div>
                    <div className="col-md-2">
                        <button
                            type="button"
                            className="btn btn-outline-success"
                            data-backdrop="static"
                            data-keyboard="false"
                            data-toggle="modal"
                            data-target="#update_curriculum"
                            style={{width: "100%", padding: "5px 0px", marginTop: "7px"}}>
                            Edit Curriculum
                        </button>
                    </div>
                </div>
            </div>
            <div className="mb-0">
                <div className="details-area">
                    <div className="pt-4">
                        <div className="single">
                            <span className="info-name">Curriculum Name</span>
                            <span className="info-desc">{curriculumDetails?.name}</span>
                        </div>
                        <div className="single">
                            <span className="info-name">Curriculum Type</span>
                            <span className="info-desc">{curriculumDetails.type}</span>
                        </div>
                        <div className="single">
                            <span className="info-name">Description</span>
                            <span className="info-desc">{curriculumDetails.description}</span>
                        </div>
                        <div className="single">
                            <span className="info-name">Status</span>
                            <span className="info-desc">{curriculumDetails.isActive ? "Active" : "Inactive"}</span>
                        </div>

                    </div>
                </div>
            </div>
            <div className="modal fade" id="update_curriculum">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Update Curriculum</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <UpdateCurriculum checkStatus={1} id={curriculumID} program={childProgram}
                                                  curriculum={curriculumDetails}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default CurriculumInformation;