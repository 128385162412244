import React, {Fragment, useEffect, useState} from "react";
import AddProgram from "./AddProgram";
import UpdateProgram from "./UpdateProgram";
import RestApi from "../../api/restApi";
import UploadProgramContent from "./UploadProgramContent";
import {NavLink} from "react-router-dom";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function ProgramHome(props) {
    const [viewProgram, setViewProgram] = useState([]);
    const [editProgram, setEditProgram] = useState([]);
    const [filterByTrueFalse, setFilterByTrueFalse] = useState([]);
    const [program, setProgram] = useState("");
    const [id, setId] = useState();
    const [programID, setProgramID] = useState();


    useEffect(() => {
        let staticURL = AppURL + 'admin/program?page=0&limit=20';
        RestApi.getFunction(staticURL, token).then((result) => {
            setViewProgram(result);
            console.log(result);
        });
    }, []);


    useEffect(() => {
        let isActive1 = 'isActive=' + filterByTrueFalse;
        let staticURL = AppURL + 'admin/program?' + isActive1 + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then((result) => {
            setViewProgram(result);
        });
    }, [filterByTrueFalse]);


    const searchByProgramName = () => {
        let staticURL = AppURL + 'admin/program?name=' + program + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then(result => {
            setViewProgram(result);
        });
    }

    const contentUpload = (props) => {
        setProgramID(props);
    }

    const getId = (props) => {
        setId(props);
        let staticURL = AppURL + "admin/program/details?id=" + props;
        RestApi.getFunction(staticURL, token).then((result) => {
            setEditProgram(result.program);
        });
    };

    return (
        <Fragment>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Program List</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <section className="col-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <div className="input-group">
                                                    <input onChange={(e) => setProgram(e.target.value)}
                                                           className="form-control py-2" type="text"
                                                           id="example-search-input" placeholder="Program Name"/>
                                                    <span className="input-group-append">
                                                        <button onClick={searchByProgramName}
                                                                className="btn btn-outline-success" type="button">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="input-group">
                                                    <select onChange={(e) => setFilterByTrueFalse(e.target.value)}
                                                            className="form-control">
                                                        <option value="">Active/Inactive</option>
                                                        <option value="true">Active</option>
                                                        <option value="false">Inactive</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-2">
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-success"
                                                    data-backdrop="static"
                                                    data-keyboard="false"
                                                    data-toggle="modal"
                                                    data-target="#add_modal"
                                                    style={{width: "100%"}}>
                                                    <i className="fas fa-plus"></i> Add Program
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="card-body"
                                        style={{height: "65vh", overflowY: "overlay"}}>
                                        <table className="table  table-hover">
                                            <thead>
                                            <tr>
                                                <th>SL NO</th>
                                                <th>Program Name</th>
                                                <th>Program Type</th>
                                                <th>Colour Code</th>
                                                <th>With Course Display</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {viewProgram?.programResp?.map((v_program, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <NavLink to={`/program-details/${v_program.id}`}>
                                                            <span
                                                                style={{color: 'black'}}>{v_program.name}</span>
                                                        </NavLink>
                                                    </td>
                                                    <td>{v_program.type}</td>
                                                    <td>{v_program.colorCode}</td>
                                                    <td>{v_program.withCourseDisplay ? "Yes" : "No"}</td>
                                                    <td>{v_program.isActive ? "Active" : "Inactive"}</td>
                                                    <td>
                                                        <span
                                                            style={{fontSize: 10, marginLeft: 10, cursor: 'pointer'}}
                                                            className="btn btn-outline-success"
                                                            data-backdrop="static"
                                                            data-keyboard="false" data-toggle="modal"
                                                            data-target="#content_modal"
                                                            onClick={() => contentUpload(v_program.id)}
                                                            title="Video upload">
                                                            Content Upload
                                                        </span>

                                                        <span
                                                            style={{fontSize: 10, marginLeft: 10, cursor: 'pointer'}}
                                                            className="btn btn-outline-success"
                                                            data-backdrop="static"
                                                            data-keyboard="false"
                                                            data-toggle="modal"
                                                            data-target="#update_modal"
                                                            onClick={() => getId(v_program.id)}
                                                            title="Update emp basic info">
                                                            <i className="fas fa-edit"></i>
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </div>

            <div className="modal fade" id="add_modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Add Program</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <AddProgram/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="update_modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Update Program</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <UpdateProgram checkStatus={0} id={id} program={editProgram}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="content_modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Upload program content</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <UploadProgramContent programID={programID}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default ProgramHome;
