import React from 'react';
import AppRoute from "./AppRoute";
import {BrowserRouter} from "react-router-dom";
import './App.css';
import 'antd/dist/antd.css';


function App(props) {
    return (
        <div>
            <BrowserRouter>
                <AppRoute/>
            </BrowserRouter>
        </div>
    );
}

export default App;