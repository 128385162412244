import React, {Fragment} from 'react';
import Header from "../../../includes/Header";
import LeftMenu from "../../../includes/LeftMenu";
import Footer from "../../../includes/Footer";
import SMSNotificationHome from "./SMSNotificationHome";

function SMSNotification(props) {
    return (
        <Fragment>
            <Header/>
            <LeftMenu/>
            <SMSNotificationHome/>
            <Footer/>
        </Fragment>
    );
}

export default SMSNotification;