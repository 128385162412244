import React, {Fragment, useState} from 'react';
import RestApi from "../../api/restApi";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function AddQuiz(props) {
    const [chapterId, setChapterId] = useState();
    const [contentId, setContentId] = useState();
    const [courseId, setCourseId] = useState();
    const [isActive, setIsActive] = useState();
    const [programId, setProgramId] = useState();
    const [questionId, setQuestionId] = useState();
    const [quizEndStatus, setQuizEndStatus] = useState();
    const [quizHeadline, setQuizHeadline] = useState();
    const [quizStartStatus, setQuizStartStatus] = useState();
    const [quizTime, setQuizTime] = useState();
    const [type, setType] = useState();
    const [msg, setMsg] = useState("");
    const {question, chapter, content, course, program} = props;

    const addQuiz = () => {
        let data = {
            chapterId: chapterId,
            contentId: contentId,
            courseId: courseId,
            isActive: isActive,
            programId: programId,
            questionId: questionId,
            quizEndStatus: quizEndStatus,
            quizHeadline: quizHeadline,
            quizStartStatus: quizStartStatus,
            quizTime: quizTime,
            type: type,
        };
        let staticURL = AppURL + 'admin/course-quize';
        RestApi.postFunction(staticURL, data, token).then(result => {
            if (result.statusCode === 200) {
                setMsg("Quiz added successfully.");
                setInterval(function () {
                    window.location.replace('quiz');
                }, 2000);
            }
        });
    }

    return (
        <Fragment>
            <div className="container">
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Chapter Name:</strong></div>
                        <div className="col-md-5">
                            <select onChange={(e) => setChapterId(e.target.value)} className="form-control">
                                <option value="">Select One</option>
                                {chapter?.chapters?.map((v_chapter, index) => (
                                    <option key={index} value={v_chapter.id}>{v_chapter.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Content Name:</strong></div>
                        <div className="col-md-5">
                            <select onChange={(e) => setContentId(e.target.value)} className="form-control">
                                <option value="">Select One</option>
                                {content?.contents?.map((v_contents, index) => (
                                    <option key={index} value={v_contents.id}>{v_contents.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Course Name:</strong></div>
                        <div className="col-md-5">
                            <select onChange={(e) => setCourseId(e.target.value)} className="form-control">
                                <option value="">Select One</option>
                                {course?.courses?.map((v_courses, index) => (
                                    <option key={index} value={v_courses.id}>{v_courses.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Program Name:</strong></div>
                        <div className="col-md-5">
                            <select onChange={(e) => (setProgramId(e.target.value))} className="form-control">
                                <option value="">Select One</option>
                                {program?.programs?.map((v_programs, index) => (
                                    <option key={index} value={v_programs.id}>{v_programs.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Question Name:</strong></div>
                        <div className="col-md-5">
                            <select onChange={(e) => setQuestionId(e.target.value)} className="form-control">
                                <option value="">Select One</option>
                                {question?.courseQuestion?.map((v_courseQuestion, index) => (
                                    <option key={index}
                                            value={v_courseQuestion.id}>{v_courseQuestion.questionName}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Is Active:</strong></div>
                        <div className="col-md-5">
                            <select onChange={(e) => setIsActive(e.target.value)} className="form-control">
                                <option value="">Select One</option>
                                <option value="true">True</option>
                                <option value="false">False</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Quiz End Status:</strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setQuizEndStatus(e.target.value)}
                                   placeholder="Quiz End Status"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Quiz Head line:</strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setQuizHeadline(e.target.value)}
                                   placeholder="Quiz Head line"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Quiz Start Status:</strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setQuizStartStatus(e.target.value)}
                                   placeholder="Quiz Start Status"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Quiz Time:</strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setQuizTime(e.target.value)}
                                   placeholder="Quiz Time"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Type:</strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setType(e.target.value)}
                                   placeholder="Type"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"></div>
                        <div className="col-md-5">
                            <input type="submit" onClick={addQuiz}
                                   className="form-control btn btn-outline-success"
                                   value="Save"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-5">
                            <div className="text-center">
                                {msg ?
                                    <div className="alert alert-success alert-dismissable text-center">
                                        <button aria-hidden="true" data-dismiss="alert" className="close"
                                                type="button">&times;</button>
                                        {msg}
                                    </div> : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default AddQuiz;