import React, {Fragment, useState} from 'react';
import RestApi from "../../api/restApi";
let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function AddCurriculum(props) {
    const [description, setDescription] = useState();
    const [name, setName] = useState();
    const [programId, setProgramId] = useState();
    const [type, setType] = useState();
    const [msg, setMsg] = useState("");
    const getPrograms = props.program;

    const AddCurriculums = () => {
        let data = {description: description, name: name, programId: programId, type: type}
        let staticURL = AppURL + 'admin/curriculum';
        RestApi.postFunction(staticURL, data, token).then(result => {
            if (result.statusCode === 200) {
                setMsg("Curriculum added successfully.");
                setInterval(function () {
                    window.location.replace('curriculum');
                }, 2000);
            }
        });
    }

    return (
        <Fragment>
            <div className="container">
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Program Name </strong></div>
                        <div className="col-md-5">
                            <select className="form-control" onChange={(e) => setProgramId(e.target.value)}>
                                <option value="">Select One</option>
                                {getPrograms?.programs?.map((v_pro, index) => (
                                    <option key={index} value={v_pro.id}>{v_pro.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Curriculum Name </strong></div>
                        <div className="col-md-5">
                            <input onChange={(e) => setName(e.target.value)} type="text"
                                   placeholder="Name"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Curriculum Description </strong></div>
                        <div className="col-md-5">
                            <input onChange={(e) => setDescription(e.target.value)} type="text"
                                   placeholder="Description"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Curriculum Type </strong></div>
                        <div className="col-md-5">
                            <select onChange={(e) => setType(e.target.value)}
                                    className="form-control">
                                <option value="">Select One</option>
                                <option value="course">Course</option>
                                <option value="discipline">Discipline</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"></div>
                        <div className="col-md-5">
                            <input type="submit" onClick={AddCurriculums}
                                   className="form-control btn btn-outline-success"
                                   value="Save"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-5">
                            <div className="text-center">
                                {msg ?
                                    <div className="alert alert-success alert-dismissable text-center">
                                        <button aria-hidden="true" data-dismiss="alert" className="close"
                                                type="button">&times;</button>
                                        {msg}
                                    </div> : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default AddCurriculum;