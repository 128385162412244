import React, {Fragment, useEffect, useState} from 'react';
import UpdateStudent from "./UpdateStudent";
import AddStudent from "./AddStudent";
import RestApi from "../../api/restApi";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function StudentHome(props) {
    const [viewStudent, setViewStudent] = useState();
    useEffect(() => {
        let staticURL = AppURL + 'admin/course-enrolled?page=0&limit=20';
        RestApi.getFunction(staticURL, token).then(result => {
            setViewStudent(result);
        });
    }, []);

    return (
        <Fragment>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Student</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <section className="col-lg-12">
                                <div className="card">
                                    <div className="card-header">

                                        <h3 className="card-title">
                                            <button type="button"
                                                    className="btn btn-outline-success"
                                                    data-backdrop="static"
                                                    data-keyboard="false" data-toggle="modal"
                                                    data-target="#add_modal"><i
                                                className="fas fa-plus"></i>Add Student
                                            </button>
                                        </h3>
                                    </div>
                                    <div className="card-body">
                                        <table className="table  table-hover">
                                            <thead>
                                            <tr>
                                                <th>SL NO</th>
                                                <th>Name</th>
                                                <th>Mobile Number</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {viewStudent?.student?.map((v_student, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{v_student.fullName}</td>
                                                    <td>{v_student.mobileNumber}</td>
                                                    <td>{v_student.status}</td>
                                                    <td>
                                                        <button style={{fontSize: 10, marginLeft: 10}}
                                                                className="btn btn-outline-success"
                                                                data-backdrop="static"
                                                                data-keyboard="false" data-toggle="modal"
                                                                data-target="#update_modal"
                                                                title="Update">
                                                            <i className="fas fa-edit"></i>
                                                        </button>

                                                        <span
                                                            style={{
                                                                fontSize: 10,
                                                                marginLeft: 10,
                                                                cursor: 'pointer'
                                                            }}
                                                            className="btn btn-outline-success"
                                                            data-backdrop="static"
                                                            data-keyboard="false" data-toggle="modal"
                                                            data-target="#content_modal"
                                                            title="Video upload">
                                                            Course Details
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </div>
            <div className="modal fade" id="add_modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Add Program</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <AddStudent/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="update_modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Update Program</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <UpdateStudent/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default StudentHome;