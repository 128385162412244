import React from 'react';
import {NavLink} from "react-router-dom";
import {
    AreaChartOutlined,
    AppstoreOutlined,
    BookOutlined,
    ProfileOutlined,
    SolutionOutlined,
    FileTextOutlined,
    TeamOutlined,
    ReadOutlined,
    SelectOutlined,
    UserOutlined,
    ContainerOutlined,
    ReconciliationOutlined,
    ExceptionOutlined
} from "@ant-design/icons";

function LeftMenu(props) {
    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4" style={{backgroundColor: "#323a5c"}}>
            <a href="index3.html" className="brand-link">
                <img src="/admin_assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo"
                     className="brand-image"/>
                <span className="brand-text font-weight-light">E-Learning</span>
            </a>
            <div className="sidebar"
                 style={{padding: 20, backgroundColor: "#2a2f5b", borderRadius: 20, margin: 12, marginBottom: 20}}>
                <div className="user-panel d-flex" style={{borderBottom: "none"}}>
                </div>
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                        data-accordion="false">
                        <li className="nav-item ">
                            <NavLink to="/admin-dashboard" className="nav-link ">
                                <AreaChartOutlined style={{fontSize: 18, marginRight: 10}}/>
                                <i className="nav-icon"></i>
                                <p style={{verticalAlign: "text-top"}}>Dashboard</p>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/program" className="nav-link">
                                <AppstoreOutlined style={{fontSize: 18, marginRight: 10}}/>
                                <i className="nav-icon "></i>
                                <p style={{verticalAlign: "text-top"}}>All program</p>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/curriculum" className="nav-link">
                                <BookOutlined style={{fontSize: 18, marginRight: 10}}/>
                                <i className="nav-icon "></i>
                                <p style={{verticalAlign: "text-top"}}>Curriculum</p>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/discipline" className="nav-link">
                                <ProfileOutlined style={{fontSize: 18, marginRight: 10}}/>
                                <i className="nav-icon "></i>
                                <p style={{verticalAlign: "text-top"}}>Discipline</p>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/course" className="nav-link">
                                <SolutionOutlined style={{fontSize: 18, marginRight: 10}}/>
                                <i className="nav-icon "></i>
                                <p style={{verticalAlign: "text-top"}}>Course</p>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/teacher" className="nav-link">
                                <TeamOutlined style={{fontSize: 18, marginRight: 10}}/>
                                <i className="nav-icon"></i>
                                <p style={{verticalAlign: "text-top"}}>Teacher List</p>
                            </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink to="/chapter" className="nav-link">
                                <FileTextOutlined style={{fontSize: 18, marginRight: 10}}/>
                                <i className="nav-icon"></i>
                                <p style={{verticalAlign: "text-top"}}>Chapters</p>
                            </NavLink>
                        </li>


                        <li className="nav-item">
                            <NavLink to="/course-enrolled" className="nav-link">
                                <SelectOutlined style={{fontSize: 18, marginRight: 10}}/>
                                <i className="nav-icon "></i>
                                <p style={{verticalAlign: "text-top"}}>Course Enrolled</p>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/student" className="nav-link">
                                <UserOutlined style={{fontSize: 18, marginRight: 10}}/>
                                <i className="nav-icon "></i>
                                <p style={{verticalAlign: "text-top"}}>Student List</p>
                            </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink to="/book-upload" className="nav-link">
                                <ExceptionOutlined style={{fontSize: 18, marginRight: 10}}/>
                                <i className="nav-icon"></i>
                                <p style={{verticalAlign: "text-top"}}>Book Upload </p>
                            </NavLink>
                        </li>


                        <li className="nav-item has-treeview">
                            <span className="nav-link">
                                <i className="nav-icon fas fa-copy"></i>
                                <p style={{color: 'white'}}>Notification
                                    <i className="fas fa-angle-left right"></i>
                                </p>
                            </span>
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <NavLink to="/sms-notification" className="nav-link">
                                        <ExceptionOutlined style={{fontSize: 18, marginRight: 10}}/>
                                        <i className="nav-icon"></i>
                                        <p style={{verticalAlign: "text-top"}}>SMS Notification</p>
                                    </NavLink>
                                </li>

                                <li className="nav-item">
                                    <NavLink to="/email-notification" className="nav-link">
                                        <ExceptionOutlined style={{fontSize: 18, marginRight: 10}}/>
                                        <i className="nav-icon"></i>
                                        <p style={{verticalAlign: "text-top"}}>Email Notification</p>
                                    </NavLink>
                                </li>

                                <li className="nav-item">
                                    <NavLink to="/push-notification" className="nav-link">
                                        <ExceptionOutlined style={{fontSize: 18, marginRight: 10}}/>
                                        <i className="nav-icon"></i>
                                        <p style={{verticalAlign: "text-top"}}>Push Notification</p>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>


                        <li className="nav-item">
                            <NavLink to="/course-syllabus" className="nav-link">
                                <ReadOutlined style={{fontSize: 18, marginRight: 10}}/>
                                <i className="nav-icon"></i>
                                <p style={{verticalAlign: "text-top"}}>Course Syllabus</p>
                            </NavLink>
                        </li>


                        <li className="nav-item">
                            <NavLink to="/content" className="nav-link">
                                <ContainerOutlined style={{fontSize: 18, marginRight: 10}}/>
                                <i className="nav-icon"></i>
                                <p style={{verticalAlign: "text-top"}}>Content</p>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/question" className="nav-link">
                                <ReconciliationOutlined style={{fontSize: 18, marginRight: 10}}/>
                                <i className="nav-icon f"></i>
                                <p style={{verticalAlign: "text-top"}}>Question</p>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/quiz" className="nav-link">
                                <ExceptionOutlined style={{fontSize: 18, marginRight: 10}}/>
                                <i className="nav-icon"></i>
                                <p style={{verticalAlign: "text-top"}}>Course Quiz</p>
                            </NavLink>
                        </li>


                        {/* <li className="nav-item has-treeview">

                            <a href="#" className="nav-link">
                                <i className="nav-icon fas fa-copy"></i>
                                <p>
                                    Level 1
                                    <i className="right fas fa-angle-left"></i>
                                </p>
                            </a>

                            <ul className="nav nav-treeview">
                                <li className="nav-item has-treeview">
                                    <a href="#" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>
                                            Level 2
                                            <i className="right fas fa-angle-left"></i>
                                        </p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">
                                                <i className="far fa-dot-circle nav-icon"></i>
                                                <p>Level 3</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">
                                                <i className="far fa-dot-circle nav-icon"></i>
                                                <p>Level 3</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">
                                                <i className="far fa-dot-circle nav-icon"></i>
                                                <p>Level 3</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li> */}
                    </ul>
                </nav>
            </div>
        </aside>
    );
}

export default LeftMenu;