import React, {Fragment, useState} from 'react';
import RestApi from "../../api/restApi";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function AddChapter(props) {
    const [programId, setProgramId] = useState();
    const [curriculumId, setCurriculumId] = useState();
    const [disciplineId, setDisciplineId] = useState();
    const [courseId, setCourseId] = useState();
    const [name, setName] = useState();
    const [msg, setMsg] = useState();
    const {course, curriculum, discipline, program} = props;


    const AddChapters = () => {
        let data = {
            courseId: courseId,
            programId: programId,
            curriculumId: curriculumId,
            disciplineId: disciplineId,
            name: name,
            isActive: "true"
        };
        let staticURL = AppURL + 'admin/chapter';
        RestApi.postFunction(staticURL, data, token).then(result => {
            if (result.statusCode === 200) {
                setMsg("Chapter added successfully.");
                setInterval(function () {
                    window.location.replace('chapter');
                }, 2000);
            }
        });
    }

    return (
        <Fragment>
            <div className="container">
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Program Name </strong></div>
                        <div className="col-md-5">
                            <select onChange={(e) => (setProgramId(e.target.value))} className="form-control">
                                <option value="">Select One</option>
                                {program?.programs?.map((v_programs, index) => (
                                    <option key={index} value={v_programs.id}>{v_programs.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Curriculum Name </strong></div>
                        <div className="col-md-5">
                            <select onChange={(e) => (setCurriculumId(e.target.value))} className="form-control">
                                <option value="">Select One</option>
                                {curriculum?.curriculums?.map((v_curriculum, index) => (
                                    <option key={index} value={v_curriculum.id}>{v_curriculum.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Discipline Name </strong></div>
                        <div className="col-md-5">
                            <select onChange={(e) => (setDisciplineId(e.target.value))} className="form-control">
                                <option value="">Select One</option>
                                {discipline?.disciplines?.map((v_discipline, index) => (
                                    <option key={index} value={v_discipline.id}>{v_discipline.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Course Name:</strong></div>
                        <div className="col-md-5">
                            <select onChange={(e) => (setCourseId(e.target.value))} className="form-control">
                                <option value="">Select One</option>
                                {course?.courses?.map((v_courses, index) => (
                                    <option key={index} value={v_courses.id}>{v_courses.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Chapter Name:</strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => (setName(e.target.value))}
                                   placeholder="Name ...."
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"></div>
                        <div className="col-md-5">
                            <input type="submit" onClick={AddChapters}
                                   className="form-control btn btn-outline-success"
                                   value="Save"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-5">
                            <div className="text-center">
                                {msg ?
                                    <div className="alert alert-success alert-dismissable text-center">
                                        <button aria-hidden="true" data-dismiss="alert" className="close"
                                                type="button">&times;</button>
                                        {msg}
                                    </div> : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default AddChapter;