import React, {Fragment, useEffect, useState} from 'react';
import RestApi from "../../api/restApi";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function UpdateProgram(props) {
    const [programName, setProgramName] = useState();
    const [type, setType] = useState();
    const [colorCode, setColorCode] = useState();
    const [withCourseDisplay, setWithCourseDisplay] = useState();
    const [isActive, setIsActive] = useState();
    const [msg, setMsg] = useState("");
    const {program, checkStatus} = props;

    useEffect(() => {
        setProgramName(program?.name);
        setType(program?.type);
        setIsActive(program?.isActive);
        setColorCode(program?.colorCode);
        setWithCourseDisplay(program?.withCourseDisplay);
    }, [program?.name, program?.type, program?.isActive, program?.withCourseDisplay, program?.colorCode]);

    const updatePrograms = () => {
        let id = props.id;
        let data = {
            id: id,
            colorCode: colorCode,
            withCourseDisplay: withCourseDisplay,
            isActive: isActive,
            name: programName,
            type: type
        };
        let staticURL = AppURL + 'admin/program';
        RestApi.putFunction(staticURL, data, token).then(result => {
            if (result.statusCode === 200) {
                setMsg("Program updated successfully.");
                if (checkStatus === 1) {
                } else {
                    setInterval(function () {
                        window.location.replace('program');
                    }, 2000);
                }
            }
        });
    }

    return (
        <Fragment>
            <div className="container">
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Program Name:</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={programName} onChange={(e) => setProgramName(e.target.value)}
                                   type="text"
                                   placeholder="Program name ...."
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Program Type:</strong></div>
                        <div className="col-md-5">
                            <select value={type} className="form-control" onChange={(e) => setType(e.target.value)}>
                                <option value="">Select One</option>
                                <option value="course">Course</option>
                                <option value="curriculum">Curriculum</option>
                                <option value="discipline">Discipline</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Color Code:</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={colorCode} onChange={(e) => setColorCode(e.target.value)} type="text"
                                   placeholder="Color Code ...."
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Course Display:</strong></div>
                        <div className="col-md-5">
                            <select value={withCourseDisplay} className="form-control"
                                    onChange={(e) => setWithCourseDisplay(e.target.value)}>
                                <option value="">Select One</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Status:</strong></div>
                        <div className="col-md-5">
                            <select value={isActive} className="form-control"
                                    onChange={(e) => setIsActive(e.target.value)}>
                                <option value="">Select One</option>
                                <option value="true">Active</option>
                                <option value="false">Inactive</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"></div>
                        <div className="col-md-5">
                            <input type="submit" onClick={updatePrograms}
                                   className="form-control btn btn-outline-success"
                                   value="Save Change"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-5">
                            <div className="text-center">
                                {msg ?
                                    <div className="alert alert-success alert-dismissable text-center">
                                        <button aria-hidden="true" data-dismiss="alert" className="close"
                                                type="button">&times;</button>
                                        {msg}
                                    </div> : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default UpdateProgram;