import React, {Fragment, useEffect, useState} from 'react';
import {PageHeader} from "antd";
import RestApi from "../../../api/restApi";
import UploadDisciplineContent from "../../Discipline/UploadDisciplineContent";
import UpdateDiscipline from "../../Discipline/UpdateDiscipline";
import AddDiscipline from "../../Discipline/AddDiscipline";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function DisciplineManagement(props) {
    const [viewDiscipline, setViewDiscipline] = useState();
    const [disciplineID, setDisciplineID] = useState();
    const [editDiscipline, setEditDiscipline] = useState();
    const [childCurriculum, setChildCurriculum] = useState();
    const [childProgram, setChildProgram] = useState();
    const [id, setId] = useState();

    useEffect(() => {
        let staticURL = 'https://elearning.jachai-staging.com:443/api/v1/program/contents-by-type?type=discipline';
        RestApi.getFunction(staticURL, token).then(result => {
            setViewDiscipline(result);
            console.log(result);
        });
    }, []);

    const getId = (props) => {
        setId(props);
        let staticURL = AppURL + 'admin/discipline/details?disciplineId=' + props;
        RestApi.getFunction(staticURL, token).then(result => {
            setEditDiscipline(result.discipline);
        });
    }

    useEffect(() => {
        let staticURL = AppURL + 'admin/curriculum';
        RestApi.getFunction(staticURL, token).then(result => {
            setChildCurriculum(result);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + 'program';
        RestApi.getFunction(staticURL, token).then(result => {
            setChildProgram(result);
        });
    }, []);

    const contentUpload = (props) => {
        setDisciplineID(props);
    }

    return (
        <Fragment>
            <div className="form-group">
                <div className="row">
                    <div className="col-md-10">
                        <PageHeader onBack={() => window.history.back()} title="Discipline Management"
                                    style={{padding: "10px 0px"}}/>
                    </div>
                    <div className="col-md-2">
                        <button
                            type="button"
                            className="btn btn-outline-success"
                            data-backdrop="static"
                            data-keyboard="false"
                            data-toggle="modal"
                            data-target="#add_modal1"
                            style={{width: "100%", padding: "5px 0px", marginTop: "7px"}}>
                            Add Discipline
                        </button>
                    </div>
                </div>
            </div>
            <div className="mb-0">
                <div className="details-area">
                    <div className="pt-4">
                        <div className="card-body">
                            <table className="table table-hover">
                                <thead>
                                <tr>
                                    <th>SL NO</th>
                                    <th>Program Name</th>
                                    <th>Curriculum Name</th>
                                    <th>Discipline Name</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {viewDiscipline?.programWithContentsResponseList?.map((v_disciplines, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{v_disciplines?.program?.name}</td>
                                        <td>{v_disciplines?.curriculum?.name}</td>
                                        <td>{v_disciplines.name}</td>
                                        <td>{v_disciplines.isActive ? "Active" : "Inactive"}</td>
                                        <td>
                                                        <span
                                                            style={{fontSize: 10, marginLeft: 10, cursor: 'pointer'}}
                                                            className="btn btn-outline-success"
                                                            data-backdrop="static"
                                                            data-keyboard="false" data-toggle="modal"
                                                            data-target="#content_modal1"
                                                            onClick={() => contentUpload(v_disciplines.id)}
                                                            title="Video upload">
                                                            Content Upload
                                                        </span>

                                            <span
                                                style={{fontSize: 10, marginLeft: 10, cursor: 'pointer'}}
                                                className="btn btn-outline-success"
                                                data-backdrop="static"
                                                data-keyboard="false"
                                                data-toggle="modal"
                                                data-target="#update_modal1"
                                                onClick={() => getId(v_disciplines.id)}
                                                title="Update emp basic info">
                                                            <i className="fas fa-edit"></i>
                                                        </span>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="content_modal1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Upload Discipline Content</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <UploadDisciplineContent checkStatus={1} disciplineID={disciplineID}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="update_modal1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Update Discipline</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <UpdateDiscipline checkStatus={1} discipline={editDiscipline} id={id}
                                                  curriculum={childCurriculum}
                                                  program={childProgram}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="add_modal1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Add Discipline</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <AddDiscipline checkStatus={1} curriculum={childCurriculum} program={childProgram}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default DisciplineManagement;