import React, {Fragment, useEffect, useState} from 'react';
import UpdateCourseSyllabus from "./UpdateCourseSyllabus";
import AddCourseSyllabus from "./AddCourseSyllabus";
import RestApi from "../../api/restApi";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function CourseSyllabusHome(props) {
    const [viewAllCourseSyllabus, setViewAllCourseSyllabus] = useState();
    const [editCourseSyllabus, setEditCourseSyllabus] = useState();
    const [childCourses, setChildCourses] = useState();
    const [childProgram, setChildProgram] = useState();
    const [childTeacher, setChildTeacher] = useState();

    const [syllabusName, setSyllabusName] = useState([]);
    const [filterByTrueFalse, setFilterByTrueFalse] = useState([]);
    const [programId, setProgramId] = useState([]);
    const [courseId, setCourseId] = useState([]);
    const [teacherId, setTeacherId] = useState([]);


    const [id, setId] = useState();

    useEffect(() => {
        let staticURL = AppURL + "admin/course-syllabus?page=0&limit=20";
        RestApi.getFunction(staticURL, token).then((result) => {
            setViewAllCourseSyllabus(result);
        });
    }, []);


    useEffect(() => {
        let staticURL = AppURL + "admin/course";
        RestApi.getFunction(staticURL, token).then((result) => {
            setChildCourses(result);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + "program";
        RestApi.getFunction(staticURL, token).then((result) => {
            setChildProgram(result);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + "teacher";
        RestApi.getFunction(staticURL, token).then((result) => {
            setChildTeacher(result);
        });
    }, []);


    const getId = (props) => {
        setId(props);
        let staticURL = AppURL.commonUrl + "admin/course-syllabus/syllabus-details?id=" + props;
        RestApi.getFunction(staticURL, token).then((result) => {
            setEditCourseSyllabus(result.syllabus);
        });
    };

    const searchBySyllabusName = () => {
        let staticURL = AppURL + 'admin/course-syllabus?name=' + syllabusName + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then(result => {
            setViewAllCourseSyllabus(result);
        });
    }

    const searchFilterByTrueFalse = () => {
        let staticURL = AppURL + 'admin/course-syllabus?isActive=' + filterByTrueFalse + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then((result) => {
            setViewAllCourseSyllabus(result);
        });
    }

    const searchByProgramId = () => {
        let staticURL = AppURL + 'admin/course-syllabus?programId=' + programId + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then((result) => {
            setViewAllCourseSyllabus(result);
        });
    }

    const searchByCourseId = () => {
        let staticURL = AppURL + 'admin/course-syllabus?courseId=' + courseId + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then((result) => {
            setViewAllCourseSyllabus(result);
        });
    }

    const searchByTeacherId = () => {
        let staticURL = AppURL + 'admin/course-syllabus/search?teacherId=' + teacherId + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then((result) => {
            setViewAllCourseSyllabus(result);
        });
    }


    return (
        <Fragment>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark"> Course Syllabus</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <section className="col-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <div className="input-group">
                                                        <input onChange={(e) => setSyllabusName(e.target.value)}
                                                               className="form-control py-2" type="text"
                                                               id="example-search-input" placeholder="Quiz Name"/>
                                                        <span className="input-group-append">
                                                        <button onClick={searchBySyllabusName}
                                                                className="btn btn-outline-success" type="button">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="input-group">
                                                        <select onChange={(e) => setFilterByTrueFalse(e.target.value)}
                                                                className="form-control">
                                                            <option value="">Active/Inactive</option>
                                                            <option value="true">Active</option>
                                                            <option value="false">Inactive</option>
                                                        </select>
                                                        <span className="input-group-append">
                                                        <button onClick={searchFilterByTrueFalse}
                                                                className="btn btn-outline-success" type="button">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                    </div>
                                                </div>

                                                <div className="col-md-2">
                                                    <div className="input-group">
                                                        <select onChange={(e) => setProgramId(e.target.value)}
                                                                className="form-control">
                                                            <option value="">Program Name</option>
                                                            {childProgram?.programs?.map((v_program, index) => (
                                                                <option key={index}
                                                                        value={v_program.id}>{v_program.name}</option>
                                                            ))}
                                                        </select>
                                                        <span className="input-group-append">
                                                        <button onClick={searchByProgramId}
                                                                className="btn btn-outline-success" type="button">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="input-group">
                                                        <select onChange={(e) => setCourseId(e.target.value)}
                                                                className="form-control">
                                                            <option value="">Course Name</option>
                                                            {childCourses?.courses?.map((v_courses, index) => (
                                                                <option key={index}
                                                                        value={v_courses.id}>{v_courses.name}</option>
                                                            ))}
                                                        </select>
                                                        <span className="input-group-append">
                                                        <button onClick={searchByCourseId}
                                                                className="btn btn-outline-success" type="button">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="input-group">
                                                        <select onChange={(e) => setTeacherId(e.target.value)}
                                                                className="form-control">
                                                            <option value="">Teacher Name</option>
                                                            {childTeacher?.teachers?.map((v_teachers, index) => (
                                                                <option key={index}
                                                                        value={v_teachers.id}>{v_teachers.fullName}</option>
                                                            ))}
                                                        </select>
                                                        <span className="input-group-append">
                                                        <button onClick={searchByTeacherId}
                                                                className="btn btn-outline-success" type="button">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-success"
                                                        data-backdrop="static"
                                                        data-keyboard="false"
                                                        data-toggle="modal"
                                                        data-target="#add_modal"
                                                        style={{width: "100%"}}>
                                                        <i className="fas fa-plus"></i> Course Syllabus
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <table className="table table-hover">
                                            <thead>
                                            <tr>
                                                <th>SL NO</th>
                                                <th>Name</th>
                                                <th>Description</th>
                                                <th>Monthly Chapter</th>
                                                <th>Month Name</th>
                                                <th>Chapter Sheet</th>
                                                <th>Program Name</th>
                                                <th>Requisite Course Name</th>
                                                <th>Requisite Description</th>
                                                <th>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {viewAllCourseSyllabus?.courseSyllabus?.map((v_courseSyllabus, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{v_courseSyllabus?.name}</td>
                                                    <td>{v_courseSyllabus?.description}</td>
                                                    <td>{v_courseSyllabus?.monthly?.monthlyChapter}</td>
                                                    <td>{v_courseSyllabus?.monthly?.monthName}</td>
                                                    <td>{v_courseSyllabus?.monthly?.chapterSheet}</td>
                                                    <td>{v_courseSyllabus?.program?.name}</td>
                                                    <td>{v_courseSyllabus?.prerequisite?.requisiteCourseName}</td>
                                                    <td>{v_courseSyllabus?.prerequisite?.requisiteDescription}</td>
                                                    <td>
                                                        <button style={{fontSize: 10}}
                                                                className="btn btn-outline-success"
                                                                data-backdrop="static"
                                                                data-keyboard="false" data-toggle="modal"
                                                                data-target="#update_modal"
                                                                onClick={() => getId(v_courseSyllabus.id)}
                                                                title="Update emp basic info">
                                                            <i className="fas fa-edit"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </div>
            <div className="modal fade" id="add_modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Add Course Syllabus</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <AddCourseSyllabus course={childCourses} program={childProgram} teacher={childTeacher}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="update_modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Update Course Syllabus</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <UpdateCourseSyllabus id={id} course={childCourses} program={childProgram}
                                                      teacher={childTeacher} courseSyllabus={editCourseSyllabus}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default CourseSyllabusHome;