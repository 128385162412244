import React, {Fragment, useEffect, useState} from 'react';
import AddCurriculum from "./AddCurriculum";
import UpdateCurriculum from "./UpdateCurriculum";
import RestApi from "../../api/restApi";
import UploadCurriculumContent from "./UploadCurriculumContent";
import {NavLink} from "react-router-dom";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function CurriculumHome(props) {
    const [childProgram, setChildProgram] = useState();
    const [viewCurriculum, setViewCurriculum] = useState();
    const [editCurriculum, setEditCurriculum] = useState();
    const [filterByType, setFilterByType] = useState([]);
    const [filterByTrueFalse, setFilterByTrueFalse] = useState([]);
    const [filterByProgram, setFilterByProgram] = useState([]);
    const [filterByCurriculumName, setFilterByCurriculumName] = useState([]);
    const [curriculumID, setCurriculumID] = useState();
    const [id, setId] = useState();


    useEffect(() => {
        let staticURL = AppURL + 'admin/curriculum';
        RestApi.getFunction(staticURL, token).then(result => {
            setViewCurriculum(result);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + 'program';
        RestApi.getFunction(staticURL, token).then(result => {
            setChildProgram(result);
        });
    }, []);


    useEffect(() => {
        let programId1 = 'programId=' + filterByProgram;
        let type1 = '&type=' + filterByType;
        let isActive1 = '&isActive=' + filterByTrueFalse;
        let staticURL = AppURL + 'admin/curriculum?' + programId1 + type1 + isActive1 + '&page=0&limit=20';
        console.log(staticURL);
        RestApi.getFunction(staticURL, token).then(result => {
            setViewCurriculum(result);
        });
    }, [filterByProgram, filterByType, filterByTrueFalse]);


    const searchByCurriculumName = () => {
        let staticURL = AppURL + 'admin/curriculum?name=' + filterByCurriculumName + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then(result => {
            setViewCurriculum(result);
        });
    }


    const getId = (props) => {
        setId(props);
        let staticURL = AppURL + 'admin/curriculum/details?curriculumId=' + props;
        RestApi.getFunction(staticURL, token).then(result => {
            setEditCurriculum(result.curriculum);
        });
    }
    const contentUpload = (props) => {
        setCurriculumID(props);
    }

    return (
        <Fragment>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Curriculum</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <section className="col-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <div className="input-group">
                                                    <select onChange={(e) => setFilterByType(e.target.value)}
                                                            className="form-control">
                                                        <option value="">Type</option>
                                                        <option value="Course">Course</option>
                                                        <option value="Discipline">Discipline</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="input-group">
                                                    <select className="form-control"
                                                            onChange={(e) => setFilterByProgram(e.target.value)}>
                                                        <option value="">Select Program</option>
                                                        {childProgram?.programs?.map((v_pro, index) => (
                                                            <option key={index} value={v_pro.id}>{v_pro.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="input-group">
                                                    <input onChange={(e) => setFilterByCurriculumName(e.target.value)}
                                                           className="form-control py-2" type="text"
                                                           id="example-search-input" placeholder="Curriculum Name"/>
                                                    <span className="input-group-append">
                                                        <button onClick={searchByCurriculumName}
                                                                className="btn btn-outline-success" type="button">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="input-group">
                                                    <select onChange={(e) => setFilterByTrueFalse(e.target.value)}
                                                            className="form-control">
                                                        <option value="">Active/Inactive</option>
                                                        <option value="true">Active</option>
                                                        <option value="false">Inactive</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-2">
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-success"
                                                    data-backdrop="static"
                                                    data-keyboard="false"
                                                    data-toggle="modal"
                                                    data-target="#add_modal"
                                                    style={{width: "100%"}}>
                                                    <i className="fas fa-plus"></i> Curriculum
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <table className="table table-hover">
                                            <thead>
                                            <tr>
                                                <th>SL NO</th>
                                                <th>Program Name</th>
                                                <th>Curriculum Name</th>
                                                <th>Curriculum Type</th>
                                                <th>Description</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {viewCurriculum?.curriculums?.map((v_curriculum, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <NavLink to={`/curriculum-details/${v_curriculum.id}`}>
                                                            <span
                                                                style={{color: 'black'}}>{v_curriculum.program?.name}</span>
                                                        </NavLink>
                                                    </td>
                                                    <td>{v_curriculum.name}</td>
                                                    <td>{v_curriculum.type}</td>
                                                    <td>{v_curriculum.description}</td>
                                                    <td>{v_curriculum.isActive ? "Active" : "Inactive"}</td>
                                                    <td>
                                                        <span
                                                            style={{fontSize: 10, marginLeft: 10, cursor: 'pointer'}}
                                                            className="btn btn-outline-success"
                                                            data-backdrop="static"
                                                            data-keyboard="false" data-toggle="modal"
                                                            data-target="#content_modal"
                                                            onClick={() => contentUpload(v_curriculum.id)}
                                                            title="Video upload">
                                                            Content Upload
                                                        </span>

                                                        <span
                                                            style={{fontSize: 10, marginLeft: 10, cursor: 'pointer'}}
                                                            className="btn btn-outline-success"
                                                            data-backdrop="static"
                                                            data-keyboard="false"
                                                            data-toggle="modal"
                                                            data-target="#update_modal"
                                                            onClick={() => getId(v_curriculum.id)}
                                                            title="Update emp basic info">
                                                            <i className="fas fa-edit"></i>
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </div>

            <div className="modal fade" id="add_modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Add Curriculum</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <AddCurriculum program={childProgram}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="update_modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Update Curriculum</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <UpdateCurriculum checkStatus={0} id={id} program={childProgram}
                                                  curriculum={editCurriculum}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="content_modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Upload Curriculum Content</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <UploadCurriculumContent curriculumID={curriculumID}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    );
}

export default CurriculumHome;