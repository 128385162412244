import React, {Fragment} from 'react';
import Header from "../../includes/Header";
import LeftMenu from "../../includes/LeftMenu";
import Footer from "../../includes/Footer";
import CourseSyllabusHome from "./CourseSyllabusHome";

function CourseSyllabus(props) {
    return (
        <Fragment>
            <Header/>
            <LeftMenu/>
            <CourseSyllabusHome/>
            <Footer/>
        </Fragment>
    );
}

export default CourseSyllabus;