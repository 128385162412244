import React, {Fragment, useEffect, useState} from 'react';
import AddQuiz from "./AddQuiz";
import UpdateQuiz from "./UpdateQuiz";
import RestApi from "../../api/restApi";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function QuizHome(props) {
    const [viewQuiz, setViewQuiz] = useState([]);
    const [editQuiz, setEditQuiz] = useState([]);
    const [childChapter, setChildChapter] = useState([]);
    const [childContent, setChildContent] = useState([]);
    const [childCourse, setChildCourse] = useState([]);
    const [childProgram, setChildProgram] = useState([]);
    const [childQuestion, setChildQuestion] = useState([]);

    const [quizHeadline, setQuizHeadline] = useState([]);
    const [filterByType, setFilterByType] = useState([]);
    const [filterByTrueFalse, setFilterByTrueFalse] = useState([]);
    const [programId, setProgramId] = useState([]);
    const [chapterId, setChapterId] = useState([]);
    const [contentId, setContentId] = useState([]);
    const [courseId, setCourseId] = useState([]);
    const [questionId, setQuestionId] = useState([]);
    const [id, setId] = useState();

    useEffect(() => {
        let staticURL = AppURL + 'admin/course-quize?page=0&limit=20';
        RestApi.getFunction(staticURL, token).then(result => {
            setViewQuiz(result);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + 'admin/course-question';
        RestApi.getFunction(staticURL, token).then(result => {
            setChildQuestion(result);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + 'program';
        RestApi.getFunction(staticURL, token).then(result => {
            setChildProgram(result);
        });
    }, []);


    useEffect(() => {
        let staticURL = AppURL + 'admin/course';
        RestApi.getFunction(staticURL, token).then(result => {
            setChildCourse(result);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + 'admin/content';
        RestApi.getFunction(staticURL, token).then(result => {
            setChildContent(result);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + 'admin/chapter';
        RestApi.getFunction(staticURL, token).then(result => {
            setChildChapter(result);
        });
    }, []);

    const getId = (props) => {
        setId(props);
        let staticURL = AppURL + 'admin/course-quize/quiz-details?id=' + props;
        RestApi.getFunction(staticURL, token).then(result => {
            setEditQuiz(result.quiz);
        });
    }

    const searchByQuizHeadline = () => {
        let staticURL = AppURL + 'admin/course-quize?quizHeadline=' + quizHeadline + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then(result => {
            setViewQuiz(result);
        });
    }

    const searchFilterByTrueFalse = () => {
        let staticURL = AppURL + 'admin/course-quize?isActive=' + filterByTrueFalse + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then((result) => {
            setViewQuiz(result);
        });
    }

    const searchFilterByType = () => {
        let staticURL = AppURL + 'admin/course-quize?type=' + filterByType + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then((result) => {
            setViewQuiz(result);
        });
    }

    const searchByProgramId = () => {
        let staticURL = AppURL + 'admin/course-quize/search?programId=' + programId + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then((result) => {
            setViewQuiz(result);
        });
    }
    const searchByChapterId = () => {
        let staticURL = AppURL + 'admin/course-quize?chapterId=' + chapterId + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then((result) => {
            setViewQuiz(result);
        });
    }
    const searchByContentId = () => {
        let staticURL = AppURL + 'admin/course-quize?contentId=' + contentId + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then((result) => {
            setViewQuiz(result);
        });
    }

    const searchByCourseId = () => {
        let staticURL = AppURL + 'admin/course-quize?courseId=' + courseId + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then((result) => {
            setViewQuiz(result);
        });
    }

    const searchByQuestionId = () => {
        let staticURL = AppURL + 'admin/course-quize?questionId=' + questionId + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then((result) => {
            setViewQuiz(result);
        });
    }


    return (<Fragment>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Quiz</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <section className="col-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <div className="input-group">
                                                        <input onChange={(e) => setQuizHeadline(e.target.value)}
                                                               className="form-control py-2" type="text"
                                                               id="example-search-input" placeholder="Quiz Name"/>
                                                        <span className="input-group-append">
                                                        <button onClick={searchByQuizHeadline}
                                                                className="btn btn-outline-success" type="button">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="input-group">
                                                        <select onChange={(e) => setFilterByTrueFalse(e.target.value)}
                                                                className="form-control">
                                                            <option value="">Active/Inactive</option>
                                                            <option value="true">Active</option>
                                                            <option value="false">Inactive</option>
                                                        </select>
                                                        <span className="input-group-append">
                                                        <button onClick={searchFilterByTrueFalse}
                                                                className="btn btn-outline-success" type="button">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="input-group">
                                                        <select onChange={(e) => setFilterByType(e.target.value)}
                                                                className="form-control">
                                                            <option value="">Active/Inactive</option>
                                                            <option value="true">Active</option>
                                                            <option value="false">Inactive</option>
                                                        </select>
                                                        <span className="input-group-append">
                                                        <button onClick={searchFilterByType}
                                                                className="btn btn-outline-success" type="button">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="input-group">
                                                        <select onChange={(e) => setProgramId(e.target.value)}
                                                                className="form-control">
                                                            <option value="">Program Name</option>
                                                            {childProgram?.programs?.map((v_program, index) => (
                                                                <option key={index}
                                                                        value={v_program.id}>{v_program.name}</option>
                                                            ))}
                                                        </select>
                                                        <span className="input-group-append">
                                                        <button onClick={searchByProgramId}
                                                                className="btn btn-outline-success" type="button">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="input-group">
                                                        <select onChange={(e) => setChapterId(e.target.value)}
                                                                className="form-control">
                                                            <option value="">Chapter Name</option>
                                                            {childChapter?.chapters?.map((v_chapters, index) => (
                                                                <option key={index}
                                                                        value={v_chapters.id}>{v_chapters.name}</option>
                                                            ))}
                                                        </select>
                                                        <span className="input-group-append">
                                                        <button onClick={searchByChapterId}
                                                                className="btn btn-outline-success" type="button">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="input-group">
                                                        <select onChange={(e) => setContentId(e.target.value)}
                                                                className="form-control">
                                                            <option value="">Content Name</option>
                                                            {childContent?.contents?.map((v_contents, index) => (
                                                                <option key={index}
                                                                        value={v_contents.id}>{v_contents.name}</option>
                                                            ))}
                                                        </select>
                                                        <span className="input-group-append">
                                                        <button onClick={searchByContentId}
                                                                className="btn btn-outline-success" type="button">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <div className="input-group">
                                                        <select onChange={(e) => setCourseId(e.target.value)}
                                                                className="form-control">
                                                            <option value="">Course Name</option>
                                                            {childCourse?.courses?.map((v_courses, index) => (
                                                                <option key={index}
                                                                        value={v_courses.id}>{v_courses.name}</option>
                                                            ))}
                                                        </select>
                                                        <span className="input-group-append">
                                                        <button onClick={searchByCourseId}
                                                                className="btn btn-outline-success" type="button">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="input-group">
                                                        <select onChange={(e) => setQuestionId(e.target.value)}
                                                                className="form-control">
                                                            <option value="">Course Name</option>
                                                            {childQuestion?.courseQuestion?.map((v_question, index) => (
                                                                <option key={index}
                                                                        value={v_question.id}>{v_question.questionName}</option>
                                                            ))}
                                                        </select>
                                                        <span className="input-group-append">
                                                        <button onClick={searchByQuestionId}
                                                                className="btn btn-outline-success" type="button">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-2"></div>
                                                <div className="col-md-2"></div>
                                                <div className="col-md-2"></div>
                                                <div className="col-md-2">
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-success"
                                                        data-backdrop="static"
                                                        data-keyboard="false"
                                                        data-toggle="modal"
                                                        data-target="#add_modal"
                                                        style={{width: "100%"}}>
                                                        <i className="fas fa-plus"></i> Quiz
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <table className="table table-hover">
                                            <thead>
                                            <tr>
                                                <th>SL NO</th>
                                                <th>Course Name</th>
                                                <th>Quiz End Status</th>
                                                <th>Quiz Head Line</th>
                                                <th>Quiz Start Status</th>
                                                <th>Quiz Time</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {viewQuiz?.courseQuiz?.map((v_courseQuiz, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{v_courseQuiz?.course?.name}</td>
                                                    <td>{v_courseQuiz?.quizEndStatus}</td>
                                                    <td>{v_courseQuiz?.quizHeadline}</td>
                                                    <td>{v_courseQuiz?.quizStartStatus}</td>
                                                    <td>{v_courseQuiz?.quizTime}</td>
                                                    <td>{v_courseQuiz.isActive ? "True" : "False"}</td>
                                                    <td>
                                                        <span
                                                            style={{fontSize: 10, marginLeft: 10, cursor: 'pointer'}}
                                                            className="btn btn-outline-success" data-backdrop="static"
                                                            data-keyboard="false" data-toggle="modal"
                                                            data-target="#update_modal"
                                                            onClick={() => getId(v_courseQuiz.id)}
                                                            title="Update emp basic info">
                                                            <i className="fas fa-edit"></i>
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </div>

            <div className="modal fade" id="add_modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Add Quiz</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <AddQuiz content={childContent} course={childCourse} program={childProgram}
                                         chapter={childChapter} question={childQuestion}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="update_modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Update Quiz</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <UpdateQuiz id={id} quiz={editQuiz} content={childContent}
                                            course={childCourse} program={childProgram} chapter={childChapter}
                                            question={childQuestion}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    );
}

export default QuizHome;