import React, {Fragment} from 'react';
import Header from "../../includes/Header";
import LeftMenu from "../../includes/LeftMenu";
import Footer from "../../includes/Footer";
import ProgramHome from "./ProgramHome";

function Program(props) {
    return (
        <Fragment>
            <Header/>
            <LeftMenu/>
            <ProgramHome/>
            <Footer/>
        </Fragment>
    );
}

export default Program;