import React, {Fragment, useEffect, useState} from 'react';
import UpdateContent from "./UpdateContent";
import AddContent from "./AddContent";
import VideoUpload from "./VideoUpload";
import RestApi from "../../api/restApi";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function ContentHome(props) {
    const [viewContent, setViewContent] = useState();
    const [editContent, setEditContent] = useState();
    const [childChapter, setChildChapter] = useState();
    const [contentName, setContentName] = useState();
    const [contentType, setContentType] = useState();
    const [chapterId, setChapterId] = useState();
    const [filterTrueFalse, setFilterByTrueFalse] = useState();
    const [id, setId] = useState();
    const [contentID, setContentID] = useState();

    useEffect(() => {
        let staticURL = AppURL + "admin/chapter";
        RestApi.getFunction(staticURL, token).then((result) => {
            setChildChapter(result);
        });
    }, []);


    useEffect(() => {
        let staticURL = AppURL + "admin/content";
        RestApi.getFunction(staticURL, token).then((result) => {
            setViewContent(result);
        });
    }, []);

    const videoUpload = (props) => {
        setContentID(props);
    }

    const getId = (props) => {
        setId(props);
        let staticURL = AppURL + "admin/content/details?contentId=" + props;
        RestApi.getFunction(staticURL, token).then((result) => {
            setEditContent(result.program);
        });
    };

    const filterByContentName = () => {
        let staticURL = AppURL + 'admin/content?name=' + contentName + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then(result => {
            setViewContent(result);
        });
    }

    const filterByContentType = () => {
        let staticURL = AppURL + 'admin/content?type=' + contentType + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then(result => {
            setViewContent(result);
        });
    }

    const filterByChapterId = () => {
        let staticURL = AppURL + 'admin/content?chapterId=' + chapterId + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then(result => {
            setViewContent(result);
        });
    }

    const filterByTrueFalse = () => {
        let staticURL = AppURL + 'admin/content?isActive=' + filterTrueFalse + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then(result => {
            setViewContent(result);
        });
    }

    return (
        <Fragment>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Content</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <section className="col-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <div className="input-group">
                                                        <input onChange={(e) => setContentName(e.target.value)}
                                                               className="form-control py-2" type="text"
                                                               id="example-search-input" placeholder="Content Name"/>
                                                        <span className="input-group-append">
                                                        <button onClick={filterByContentName}
                                                                className="btn btn-outline-success" type="button">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                    </div>
                                                </div>

                                                <div className="col-md-2">
                                                    <div className="input-group">
                                                        <input onChange={(e) => setContentType(e.target.value)}
                                                               className="form-control py-2" type="text"
                                                               id="example-search-input" placeholder="Content Type"/>
                                                        <span className="input-group-append">
                                                        <button onClick={filterByContentType}
                                                                className="btn btn-outline-success" type="button">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="input-group">
                                                        <select
                                                            onChange={(e) => setFilterByTrueFalse(e.target.value)}
                                                            className="form-control">
                                                            <option value="">Active/Inactive</option>
                                                            <option value="true">Active</option>
                                                            <option value="false">Inactive</option>
                                                        </select>
                                                        <span className="input-group-append">
                                                        <button onClick={filterByTrueFalse}
                                                                className="btn btn-outline-success" type="button">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="input-group">
                                                        <select
                                                            onChange={(e) => setChapterId(e.target.value)}
                                                            className="form-control">
                                                            <option value="">Chapter Name</option>
                                                            {childChapter?.chapters?.map((v_chapters, index) => (
                                                                <option key={index}
                                                                        value={v_chapters.id}>{v_chapters.name}</option>
                                                            ))}
                                                        </select>
                                                        <span className="input-group-append">
                                                        <button onClick={filterByChapterId}
                                                                className="btn btn-outline-success" type="button">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-2"></div>
                                                <div className="col-md-2">
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-success"
                                                        data-backdrop="static"
                                                        data-keyboard="false"
                                                        data-toggle="modal"
                                                        data-target="#add_modal"
                                                        style={{width: "100%"}}>
                                                        <i className="fas fa-plus"></i> Content
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <table className="table  table-hover">
                                            <thead>
                                            <tr>
                                                <th>SL NO</th>
                                                <th>Chapter Name</th>
                                                <th>Description</th>
                                                <th>Name</th>
                                                <th>Url</th>
                                                <th>Type</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {viewContent?.contents?.map((v_contents, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{v_contents.id}</td>
                                                    <td>{v_contents.description}</td>
                                                    <td>{v_contents.name}</td>
                                                    <td>
                                                        {v_contents.contentDetails?.map((v_video, index) => (
                                                            <video key={index} width="320" height="240" controls
                                                                   controlsList="nodownload">
                                                                <source src={v_video.contentWithDetails.videoUrl}
                                                                        type="video/mp4"/>
                                                            </video>
                                                        ))}
                                                    </td>
                                                    <td>{v_contents.type}</td>
                                                    <td>{v_contents.isActive ? "True" : "False"}</td>
                                                    <td style={{width: '200px'}}>
                                                        <span
                                                            style={{fontSize: 10, marginLeft: 10, cursor: 'pointer'}}
                                                            className="btn btn-outline-success"
                                                            data-backdrop="static"
                                                            data-keyboard="false" data-toggle="modal"
                                                            data-target="#content_modal"
                                                            onClick={() => videoUpload(v_contents.id)}
                                                            title="Video upload">
                                                            Content Upload
                                                        </span>

                                                        <button style={{fontSize: 10, margin: '5px'}}
                                                                className="btn btn-outline-success"
                                                                data-backdrop="static"
                                                                data-keyboard="false" data-toggle="modal"
                                                                data-target="#update_modal"
                                                                onClick={() => getId(v_contents.id)}
                                                                title="Update emp basic info">
                                                            <i className="fas fa-edit"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </div>

            <div className="modal fade" id="add_modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Add Content</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <AddContent chapter={childChapter}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="update_modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Update Content</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <UpdateContent id={id} content={editContent} chapter={childChapter}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="content_modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Video Upload</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <VideoUpload contentID={contentID}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    );
}

export default ContentHome;