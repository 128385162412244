import React, {Fragment, useEffect, useState} from 'react';
import UpdateChapter from "./UpdateChapter";
import AddChapter from "./AddChapter";
import RestApi from "../../api/restApi";
import UploadChapterContent from "./UploadChapterContent";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function ChapterHome(props) {
    const [childCurriculum, setChildCurriculum] = useState();
    const [childDiscipline, setChildDiscipline] = useState();
    const [childProgram, setChildProgram] = useState();
    const [viewChapter, setViewChapter] = useState([]);
    const [editChapter, setEditChapter] = useState([]);
    const [childCourses, setChildCourses] = useState([]);
    const [chapterName, setChapterName] = useState("");
    const [filterByTrueFalse, setFilterByTrueFalse] = useState([]);
    const [courseId, setCourseId] = useState([]);
    const [chapterID, setChapterID] = useState();
    const [chapterNameGet, setChapterNameGet] = useState();
    const [id, setId] = useState();

    useEffect(() => {
        let staticURL = AppURL + "admin/chapter";
        RestApi.getFunction(staticURL, token).then((result) => {
            setViewChapter(result);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + 'admin/curriculum';
        RestApi.getFunction(staticURL, token).then(result => {
            setChildCurriculum(result);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + 'admin/discipline';
        RestApi.getFunction(staticURL, token).then(result => {
            setChildDiscipline(result);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + 'program';
        RestApi.getFunction(staticURL, token).then(result => {
            setChildProgram(result);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + "admin/course";
        RestApi.getFunction(staticURL, token).then((result) => {
            setChildCourses(result);
        });
    }, []);

    const getId = (props) => {
        setId(props);
        let staticURL = AppURL + "admin/chapter/details?chapterId=" + props;
        RestApi.getFunction(staticURL, token).then((result) => {
            setEditChapter(result.chapter);
        });
    };

    const searchByChapterName = () => {
        let staticURL = AppURL + 'admin/chapter?name=' + chapterName + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then(result => {
            setViewChapter(result);
        });
    }

    useEffect(() => {
        let isActive1 = 'isActive=' + filterByTrueFalse;
        let courseId1 = '&courseId=' + courseId;
        let staticURL = AppURL + 'admin/chapter?' + isActive1 + courseId1 + '&page=0&limit=20';
        console.log(staticURL);
        RestApi.getFunction(staticURL, token).then((result) => {
            setViewChapter(result);
        });
    }, [filterByTrueFalse, courseId]);

    const contentUpload = (...params) => {
        let [id, name] = params;
        setChapterID(id);
        setChapterNameGet(name);
    }

    return (
        <Fragment>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Chapter</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <section className="col-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <div className="input-group">
                                                        <input onChange={(e) => setChapterName(e.target.value)}
                                                               className="form-control py-2" type="text"
                                                               id="example-search-input" placeholder="Course Name"/>
                                                        <span className="input-group-append">
                                                        <button onClick={searchByChapterName}
                                                                className="btn btn-outline-success" type="button">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                    </div>
                                                </div>

                                                <div className="col-md-2">
                                                    <div className="input-group">
                                                        <select
                                                            onChange={(e) => setFilterByTrueFalse(e.target.value)}
                                                            className="form-control">
                                                            <option value="">Active/Inactive</option>
                                                            <option value="true">Active</option>
                                                            <option value="false">Inactive</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="input-group">
                                                        <select
                                                            onChange={(e) => setCourseId(e.target.value)}
                                                            className="form-control">
                                                            <option value="">Course Name</option>
                                                            {childCourses?.courses?.map((v_courses, index) => (
                                                                <option key={index}
                                                                        value={v_courses.id}>{v_courses.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-2"></div>
                                                <div className="col-md-2"></div>
                                                <div className="col-md-2">
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-success"
                                                        data-backdrop="static"
                                                        data-keyboard="false"
                                                        data-toggle="modal"
                                                        data-target="#add_modal"
                                                        style={{width: "100%"}}>
                                                        <i className="fas fa-plus"></i> Chapter
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <table className="table  table-hover">
                                            <thead>
                                            <tr>
                                                <th>SL NO</th>
                                                <th>Chapter Name</th>
                                                <th>Course Name</th>
                                                <th>Total Video</th>
                                                <th>Total Content</th>
                                                <th>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {viewChapter?.chapters?.map((v_chapter, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{v_chapter.name}</td>
                                                    <td>{v_chapter?.course?.name}</td>
                                                    <td>total video api</td>
                                                    <td>total content api</td>
                                                    <td>
                                                          <span
                                                              style={{fontSize: 10, marginLeft: 10, cursor: 'pointer'}}
                                                              className="btn btn-outline-success"
                                                              data-backdrop="static"
                                                              data-keyboard="false" data-toggle="modal"
                                                              data-target="#content_modal"
                                                              onClick={() => contentUpload(v_chapter.id, v_chapter.name)}
                                                              title="Video upload">
                                                            Content Upload
                                                        </span>

                                                        <button
                                                            style={{fontSize: 10, marginLeft: 10, cursor: 'pointer'}}
                                                            className="btn btn-outline-success"
                                                            data-backdrop="static"
                                                            data-keyboard="false" data-toggle="modal"
                                                            data-target="#update_modal"
                                                            onClick={() => getId(v_chapter.id)}
                                                            title="Update emp basic info">
                                                            <i className="fas fa-edit"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </div>

            <div className="modal fade" id="add_modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Add Chapter</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <AddChapter curriculum={childCurriculum} course={childCourses}
                                            discipline={childDiscipline}
                                            program={childProgram}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="update_modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Update Chapter</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <UpdateChapter id={id} course={childCourses} chapter={editChapter}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="content_modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Upload Chapter Content</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <UploadChapterContent chapterID={chapterID} chapterName={chapterNameGet}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default ChapterHome;