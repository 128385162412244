import React from 'react';
import {CheckCircleOutlined} from '@ant-design/icons';


function Active(props) {
    return (
        <div className="mb-0">
            <div>
                              <span className="radius-btn active-btn">
                                <span style={{fontSize: ""}}>
                                    <CheckCircleOutlined style={{verticalAlign: "text-bottom", marginRight: 3}}/>
                                 <h7>Active</h7>
                                </span>
                              </span>
            </div>
        </div>
    );
}

export default Active;