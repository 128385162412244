import React, {Fragment, useEffect, useState} from 'react';
import AddQuestion from "./AddQuestion";
import UpdateQuestion from "./UpdateQuestion";
import RestApi from "../../api/restApi";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function QuestionHome(props) {
    const [viewQuestion, setViewQuestion] = useState([]);
    const [editQuestion, setEditQuestion] = useState([]);
    const [childChapter, setChildChapter] = useState([]);
    const [childContent, setChildContent] = useState([]);
    const [childCourse, setChildCourse] = useState([]);
    const [childProgram, setChildProgram] = useState([]);
    const [childTeacher, setChildTeacher] = useState([]);
    const [questionName, setQuestionName] = useState();
    const [filterByTrueFalse, setFilterByTrueFalse] = useState([]);
    const [programId, setProgramId] = useState([]);
    const [chapterId, setChapterId] = useState([]);
    const [contentId, setContentId] = useState([]);
    const [courseId, setCourseId] = useState([]);
    const [id, setId] = useState();

    useEffect(() => {
        let staticURL = AppURL + 'teacher';
        RestApi.getFunction(staticURL, token).then(result => {
            setChildTeacher(result);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + 'program';
        RestApi.getFunction(staticURL, token).then(result => {
            setChildProgram(result);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + '/admin/chapter';
        RestApi.getFunction(staticURL, token).then(result => {
            setChildChapter(result);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + 'admin/course';
        RestApi.getFunction(staticURL, token).then(result => {
            setChildCourse(result);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + 'admin/content';
        RestApi.getFunction(staticURL, token).then(result => {
            setChildContent(result);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + 'admin/course-question?page=0&limit=20';
        RestApi.getFunction(staticURL, token).then(result => {
            setViewQuestion(result);
        });
    }, []);

    const getId = (props) => {
        setId(props);
        let staticURL = AppURL + 'admin/course-question/question-details?id=' + props;
        RestApi.getFunction(staticURL, token).then(result => {
            setEditQuestion(result.question);
        });
    }

    const searchByQuestionName = () => {
        let staticURL = AppURL + 'admin/course-question?questionName=' + questionName + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then(result => {
            setViewQuestion(result);
        });
    }
    const searchFilterByTrueFalse = () => {
        let staticURL = AppURL + 'admin/course-question?isActive=' + filterByTrueFalse + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then((result) => {
            setViewQuestion(result);
        });
    }

    const searchByProgramId = () => {
        let staticURL = AppURL + 'admin/course-question?programId=' + programId + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then((result) => {
            setViewQuestion(result);
        });
    }
    const searchByChapterId = () => {
        let staticURL = AppURL + 'admin/course-question?chapterId=' + chapterId + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then((result) => {
            setViewQuestion(result);
        });
    }
    const searchByContentId = () => {
        let staticURL = AppURL + 'admin/course-question?contentId=' + contentId + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then((result) => {
            setViewQuestion(result);
        });
    }
    const searchByCourseId = () => {
        let staticURL = AppURL + 'admin/course-question?courseId=' + courseId + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then((result) => {
            setViewQuestion(result);
        });
    }


    return (
        <Fragment>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark"> Question</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <section className="col-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <div className="input-group">
                                                        <input onChange={(e) => setQuestionName(e.target.value)}
                                                               className="form-control py-2" type="text"
                                                               id="example-search-input" placeholder="Question Name"/>
                                                        <span className="input-group-append">
                                                        <button onClick={searchByQuestionName}
                                                                className="btn btn-outline-success" type="button">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="input-group">
                                                        <select onChange={(e) => setProgramId(e.target.value)}
                                                                className="form-control">
                                                            <option value="">Program Name</option>
                                                            {childProgram?.programs?.map((v_program, index) => (
                                                                <option key={index}
                                                                        value={v_program.id}>{v_program.name}</option>
                                                            ))}
                                                        </select>
                                                        <span className="input-group-append">
                                                        <button onClick={searchByProgramId}
                                                                className="btn btn-outline-success" type="button">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="input-group">
                                                        <select onChange={(e) => setChapterId(e.target.value)}
                                                                className="form-control">
                                                            <option value="">Chapter Name</option>
                                                            {childChapter?.chapters?.map((v_chapters, index) => (
                                                                <option key={index}
                                                                        value={v_chapters.id}>{v_chapters.name}</option>
                                                            ))}
                                                        </select>
                                                        <span className="input-group-append">
                                                        <button onClick={searchByChapterId}
                                                                className="btn btn-outline-success" type="button">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="input-group">
                                                        <select onChange={(e) => setContentId(e.target.value)}
                                                                className="form-control">
                                                            <option value="">Content Name</option>
                                                            {childContent?.contents?.map((v_contents, index) => (
                                                                <option key={index}
                                                                        value={v_contents.id}>{v_contents.name}</option>
                                                            ))}
                                                        </select>
                                                        <span className="input-group-append">
                                                        <button onClick={searchByContentId}
                                                                className="btn btn-outline-success" type="button">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="input-group">
                                                        <select onChange={(e) => setCourseId(e.target.value)}
                                                                className="form-control">
                                                            <option value="">Course Name</option>
                                                            {childCourse?.courses?.map((v_courses, index) => (
                                                                <option key={index}
                                                                        value={v_courses.id}>{v_courses.name}</option>
                                                            ))}
                                                        </select>
                                                        <span className="input-group-append">
                                                        <button onClick={searchByCourseId}
                                                                className="btn btn-outline-success" type="button">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-1">
                                                    <div className="input-group">
                                                        <select onChange={(e) => setFilterByTrueFalse(e.target.value)}
                                                                className="form-control">
                                                            <option value="">Active/Inactive</option>
                                                            <option value="true">Active</option>
                                                            <option value="false">Inactive</option>
                                                        </select>
                                                        <span className="input-group-append">
                                                        <button onClick={searchFilterByTrueFalse}
                                                                className="btn btn-outline-success" type="button">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-1">
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-success"
                                                        data-backdrop="static"
                                                        data-keyboard="false"
                                                        data-toggle="modal"
                                                        data-target="#add_modal"
                                                        style={{width: "100%"}}>
                                                        <i className="fas fa-plus"></i> Question
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <table className="table table-hover">
                                            <thead>
                                            <tr>
                                                <th>SL NO</th>
                                                <th>Question Answer</th>
                                                <th>Question Name</th>
                                                <th>Option One</th>
                                                <th>Option Two</th>
                                                <th>Option Three</th>
                                                <th>Option Four</th>
                                                <th>Question Value</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {viewQuestion?.courseQuestion?.map((v_courseQuestion, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{v_courseQuestion.questionAnswer}</td>
                                                    <td>{v_courseQuestion.questionName}</td>
                                                    <td>{v_courseQuestion.questionOption?.optionOne}</td>
                                                    <td>{v_courseQuestion.questionOption?.optionTwo}</td>
                                                    <td>{v_courseQuestion.questionOption?.optionThree}</td>
                                                    <td>{v_courseQuestion.questionOption?.optionFour}</td>
                                                    <td>{v_courseQuestion.questionValue}</td>
                                                    <td>{v_courseQuestion.isActive ? "True" : "False"}</td>
                                                    <td>
                                                        <span
                                                            style={{fontSize: 10, marginLeft: 10, cursor: 'pointer'}}
                                                            className="btn btn-outline-success" data-backdrop="static"
                                                            data-keyboard="false" data-toggle="modal"
                                                            data-target="#update_modal"
                                                            onClick={() => getId(v_courseQuestion.id)}
                                                            title="Update emp basic info">
                                                            <i className="fas fa-edit"></i>
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </div>

            <div className="modal fade" id="add_modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Add Question</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <AddQuestion content={childContent} course={childCourse} program={childProgram}
                                             teacher={childTeacher}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="update_modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Update Question</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <UpdateQuestion id={id} question={editQuestion} content={childContent}
                                                course={childCourse} program={childProgram} teacher={childTeacher}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default QuestionHome;