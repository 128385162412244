import React, {Fragment, useState} from 'react';
import axios from "axios";

const AppURL = process.env.REACT_APP_BASE_URL;

function UploadDisciplineContent(props) {
    const [image, setImage] = useState();
    const [bannerImage, setBannerImage] = useState();
    const [contentVideo, setContentVideo] = useState();
    const [msg, setMsg] = useState("");
    const {checkStatus} = props;

    const onChangeImage = (e) => {
        if (e.target.files[0]) {
            setImage(e.target.files[0]);
        }
    };

    const onChangeBannerImage = (e) => {
        if (e.target.files[0]) {
            setBannerImage(e.target.files[0]);
        }
    };

    const onChangeContentVideo = (e) => {
        if (e.target.files[0]) {
            setContentVideo(e.target.files[0]);
        }
    };

    const addContent = (event) => {
        let staticURL = AppURL + 'admin/document/upload/discipline_upload-image-video';
        let disciplineID = props.disciplineID;
        const fd = new FormData();
        fd.append('title', 'discipline');
        fd.append('image', image);
        fd.append('bannerImage', bannerImage);
        fd.append('videoFile', contentVideo);
        fd.append('disciplineId', disciplineID);
        axios({
            method: 'POST',
            url: staticURL,
            data: fd,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            if (response.data.statusCode === 200) {
                setMsg("Discipline upload successfully.")
                if (checkStatus === 1) {
                } else {
                    setInterval(function () {
                        window.location.replace('program');
                    }, 2000);
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }

    return (
        <Fragment>
            <div className="container">
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Discipline Image</strong></div>
                        <div className="col-md-5">
                            <input type="file"
                                   onChange={onChangeImage}
                                   className="txt-input"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Discipline Banner Image</strong></div>
                        <div className="col-md-5">
                            <input type="file"
                                   onChange={onChangeBannerImage}
                                   className="txt-input"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Discipline Video</strong></div>
                        <div className="col-md-5">
                            <input type="file"
                                   onChange={onChangeContentVideo}
                                   className="txt-input"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"></div>
                        <div className="col-md-5">
                            <input type="submit" onClick={addContent}
                                   className="form-control btn btn-outline-success"
                                   value="Save"/>
                        </div>
                    </div>
                </div>


                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-5">
                            <div className="text-center">
                                {msg ?
                                    <div className="alert alert-success alert-dismissable text-center">
                                        <button aria-hidden="true" data-dismiss="alert" className="close"
                                                type="button">&times;</button>
                                        {msg}
                                    </div> : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default UploadDisciplineContent;