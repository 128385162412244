import React, {Fragment, useEffect, useState} from 'react';
import AddCourseEnrolled from "./AddCourseEnrolled";
import UpdateCourseEnrolled from "./UpdateCourseEnrolled";
import RestApi from "../../api/restApi";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;


function CourseEnrolledHome(props) {
    const [viewCourseEnrolled, setViewCourseEnrolled] = useState([]);
    const [editCourseEnrolled, setEditCourseEnrolled] = useState([]);
    const [childProgram, setChildProgram] = useState([]);
    const [childCourse, setChildCourse] = useState([]);
    const [childStudent, setChildStudent] = useState([]);

    const [filterByTrueFalse, setFilterByTrueFalse] = useState([]);
    const [programId, setProgramId] = useState([]);
    const [courseId, setCourseId] = useState([]);
    const [studentId, setStudentId] = useState([]);

    const [id, setId] = useState();


    useEffect(() => {
        let staticURL = AppURL + "admin/course-enrolled/?page=0&limit=20";
        RestApi.getFunction(staticURL, token).then((result) => {
            console.log(result);
            setViewCourseEnrolled(result);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + "admin/course";
        RestApi.getFunction(staticURL, token).then((result) => {
            setChildCourse(result);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + "student/api/v1/all";
        RestApi.getFunction(staticURL, token).then((result) => {
            setChildStudent(result);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + "program";
        RestApi.getFunction(staticURL, token).then((result) => {
            setChildProgram(result);
        });
    }, []);

    const getId = (props) => {
        setId(props);
        let staticURL = AppURL + "admin/course-enrolled/enrolled-details?id=" + props;
        RestApi.getFunction(staticURL, token).then((result) => {
            setEditCourseEnrolled(result.enrolled);
        });
    };

    const searchFilterByTrueFalse = () => {
        let staticURL = AppURL + 'admin/course-enrolled/search?isActive=' + filterByTrueFalse + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then((result) => {
            setViewCourseEnrolled(result);
        });
    }

    const searchByProgramId = () => {
        let staticURL = AppURL + 'admin/course-enrolled/search?programId=' + programId + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then((result) => {
            setViewCourseEnrolled(result);
        });
    }

    const searchByCourseId = () => {
        let staticURL = AppURL + 'admin/course-enrolled/search?courseId=' + courseId + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then((result) => {
            setViewCourseEnrolled(result);
        });
    }

    const searchByStudentId = () => {
        let staticURL = AppURL + 'admin/course-enrolled/search?studentId=' + studentId + '&page=0&limit=20';
        RestApi.getFunction(staticURL, token).then((result) => {
            setViewCourseEnrolled(result);
        });
    }

    return (
        <Fragment>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Course Enrolled</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <section className="col-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="form-group">
                                            <div className="row">

                                                <div className="col-md-2">
                                                    <div className="input-group">
                                                        <select onChange={(e) => setStudentId(e.target.value)}
                                                                className="form-control">
                                                            <option value="">Student Name</option>
                                                            {childStudent?.student?.map((v_student, index) => (
                                                                <option key={index}
                                                                        value={v_student.id}>{v_student.fullName}</option>
                                                            ))}
                                                        </select>
                                                        <span className="input-group-append">
                                                        <button onClick={searchByStudentId}
                                                                className="btn btn-outline-success" type="button">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                    </div>
                                                </div>

                                                <div className="col-md-2">
                                                    <div className="input-group">
                                                        <select onChange={(e) => setFilterByTrueFalse(e.target.value)}
                                                                className="form-control">
                                                            <option value="">Active/Inactive</option>
                                                            <option value="true">Active</option>
                                                            <option value="false">Inactive</option>
                                                        </select>
                                                        <span className="input-group-append">
                                                        <button onClick={searchFilterByTrueFalse}
                                                                className="btn btn-outline-success" type="button">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                    </div>
                                                </div>

                                                <div className="col-md-2">
                                                    <div className="input-group">
                                                        <select onChange={(e) => setProgramId(e.target.value)}
                                                                className="form-control">
                                                            <option value="">Program Name</option>
                                                            {childProgram?.programs?.map((v_program, index) => (
                                                                <option key={index}
                                                                        value={v_program.id}>{v_program.name}</option>
                                                            ))}
                                                        </select>
                                                        <span className="input-group-append">
                                                        <button onClick={searchByProgramId}
                                                                className="btn btn-outline-success" type="button">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="input-group">
                                                        <select onChange={(e) => setCourseId(e.target.value)}
                                                                className="form-control">
                                                            <option value="">Course Name</option>
                                                            {childCourse?.courses?.map((v_courses, index) => (
                                                                <option key={index}
                                                                        value={v_courses.id}>{v_courses.name}</option>
                                                            ))}
                                                        </select>
                                                        <span className="input-group-append">
                                                        <button onClick={searchByCourseId}
                                                                className="btn btn-outline-success" type="button">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-2"></div>
                                                <div className="col-md-2">
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-success"
                                                        data-backdrop="static"
                                                        data-keyboard="false"
                                                        data-toggle="modal"
                                                        data-target="#add_modal"
                                                        style={{width: "100%"}}>
                                                        <i className="fas fa-plus"></i> Course Enrolled
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <table className="table  table-hover">
                                            <thead>
                                            <tr>
                                                <th>SL NO</th>
                                                <th>Student ID</th>
                                                <th>Course ID</th>
                                                <th>Due Amount</th>
                                                <th>Paid Amount</th>
                                                <th>Payment Mode</th>
                                                <th>Payment Status</th>
                                                <th>Program Name</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {viewCourseEnrolled?.courseEnrolled?.map((v_courseEnrolled, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{v_courseEnrolled?.student?.fullName}</td>
                                                    <td>{v_courseEnrolled?.course?.name}</td>
                                                    <td>{v_courseEnrolled?.dueAmount}</td>
                                                    <td>{v_courseEnrolled?.paidAmount}</td>
                                                    <td>{v_courseEnrolled?.paymentMode}</td>
                                                    <td>{v_courseEnrolled?.paymentStatus}</td>
                                                    <td>{v_courseEnrolled?.program?.name}</td>
                                                    <td>{v_courseEnrolled.isActive ? "True" : "False"}</td>
                                                    <td>
                                                        <button style={{fontSize: 10}}
                                                                className="btn btn-outline-success"
                                                                data-backdrop="static"
                                                                data-keyboard="false" data-toggle="modal"
                                                                data-target="#update_modal"
                                                                onClick={() => getId(v_courseEnrolled.id)}
                                                                title="Update emp basic info">
                                                            <i className="fas fa-edit"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </div>
            <div className="modal fade" id="add_modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Add Course Enrolled</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <AddCourseEnrolled checkStatus={0} program={childProgram} course={childCourse}
                                                   student={childStudent}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="update_modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Update Enrolled Course</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <UpdateCourseEnrolled checkStatus={0} id={id} program={childProgram}
                                                      course={childCourse}
                                                      student={childStudent} courseEnrolled={editCourseEnrolled}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default CourseEnrolledHome;