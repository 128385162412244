import React, {Fragment} from 'react';
import Header from "../../../includes/Header";
import LeftMenu from "../../../includes/LeftMenu";
import Footer from "../../../includes/Footer";
import EmailNotificationHome from "./EmailNotificationHome";

function EmailNotification(props) {
    return (
        <Fragment>
            <Header/>
            <LeftMenu/>
            <EmailNotificationHome/>
            <Footer/>
        </Fragment>
    );
}

export default EmailNotification;