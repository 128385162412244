import React, {Fragment} from 'react';
import {PageHeader} from "antd";
import UpdateBasic from "./UpdateBasic";
import {useParams} from 'react-router-dom';

function TeacherInformation(props) {
    const {teacherDetails} = props;
    console.log(teacherDetails?.teacherDetails);
    const {teacherID} = useParams();
    return (
        <Fragment>
            <div className="form-group">
                <div className="row">
                    <div className="col-md-10">
                        <PageHeader onBack={() => window.history.back()} title="Basic Information"
                                    style={{padding: "10px 0px"}}/>
                    </div>
                    <div className="col-md-2">
                        <button
                            type="button"
                            className="btn btn-outline-success"
                            data-backdrop="static"
                            data-keyboard="false"
                            data-toggle="modal"
                            data-target="#update_basic"
                            style={{width: "100%", padding: "5px 0px", marginTop: "7px"}}>
                            Edit
                        </button>
                    </div>
                </div>
            </div>

            <div className="mb-0">
                <div className="details-area">
                    <div className="pt-4">
                        <div className="single">
                            <span className="info-name">Teacher Name</span>
                            <span className="info-desc">{teacherDetails?.fullName}</span>
                        </div>
                        <div className="single">
                            <span className="info-name">Mobile Number</span>
                            <span className="info-desc">{teacherDetails?.mobileNumber}</span>
                        </div>
                        <div className="single">
                            <span className="info-name">Email</span>
                            <span className="info-desc">{teacherDetails?.email}</span>
                        </div>
                        <div className="single">
                            <span className="info-name">Blood Group</span>
                            <span className="info-desc">{teacherDetails?.teacherDetails?.dateOfBirth}</span>
                        </div>
                        <div className="single">
                            <span className="info-name">Date of Birth</span>
                            <span className="info-desc">{teacherDetails?.teacherDetails?.dateOfBirth}</span>
                        </div>
                        <div className="single">
                            <span className="info-name">Gender</span>
                            <span className="info-desc">{teacherDetails?.teacherDetails?.gender}</span>
                        </div>
                        <div className="single">
                            <span className="info-name">Emergency Contact Number</span>
                            <span className="info-desc">{teacherDetails?.teacherDetails?.emergencyContactNumber}</span>
                        </div>
                        <div className="single">
                            <span className="info-name">NationalId</span>
                            <span className="info-desc">{teacherDetails?.teacherDetails?.nationalId}</span>
                        </div>
                        <div className="single">
                            <span className="info-name">Passport Number</span>
                            <span className="info-desc">{teacherDetails?.teacherDetails?.passport}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="update_basic">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Update Basic Information</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <UpdateBasic id={teacherID}
                                             teacher={teacherDetails}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default TeacherInformation;