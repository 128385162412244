import React from 'react';



import {
    CloseCircleOutlined
} from '@ant-design/icons';



function Inactive(props) {
    return (
        <div className="mb-0">
            <div>
                              <span className="radius-btn inactive-btn">
                                <span style={{fontSize: ""}}>
                                    <CloseCircleOutlined style={{verticalAlign: "text-bottom", marginRight: 3}}/>
                                 <h7>Inactive</h7>
                                </span>
                              </span>
            </div>
        </div>
    );
}

export default Inactive;