import React, {Fragment, useState} from 'react';
import RestApi from "../../api/restApi";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function AddQuestion(props) {
    const [contentId, setContentId] = useState();
    const [courseId, setCourseId] = useState();
    const [programId, setProgramId] = useState();
    const [questionAnswer, setQuestionAnswer] = useState();
    const [questionName, setQuestionName] = useState();
    const [optionOne, setOptionOne] = useState();
    const [optionTwo, setOptionTwo] = useState();
    const [optionThree, setOptionThree] = useState();
    const [optionFour, setOptionFour] = useState();
    const [questionValue, setQuestionValue] = useState();
    const [teacherId, setTeacherId] = useState();
    const [msg, setMsg] = useState("");
    const {content, course, program, teacher} = props;


    const addQuestion = () => {
        let data = {
            contentId: contentId,
            courseId: courseId,
            isActive: "true",
            programId: programId,
            questionAnswer: questionAnswer,
            questionName: questionName,
            questionOption: {
                optionOne: optionOne,
                optionTwo: optionTwo,
                optionThree: optionThree,
                optionFour: optionFour
            },
            questionValue: questionValue,
            teacherId: teacherId,
        };
        let staticURL = AppURL + 'admin/course-question';
        RestApi.postFunction(staticURL, data, token).then(result => {
            if (result.statusCode === 200) {
                setMsg("Question added successfully.");
                setInterval(function () {
                    window.location.replace('program');
                }, 2000);
            }
        });
    }

    return (
        <Fragment>
            <div className="container">
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Content Name:</strong></div>
                        <div className="col-md-5">
                            <select onChange={(e) => setContentId(e.target.value)} className="form-control">
                                <option value="">Select One</option>
                                {content?.contents?.map((v_contents, index) => (
                                    <option key={index} value={v_contents.id}>{v_contents.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Course Name:</strong></div>
                        <div className="col-md-5">
                            <select onChange={(e) => setCourseId(e.target.value)} className="form-control">
                                <option value="">Select One</option>
                                {course?.courses?.map((v_courses, index) => (
                                    <option key={index} value={v_courses.id}>{v_courses.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Program Name:</strong></div>
                        <div className="col-md-5">
                            <select onChange={(e) => (setProgramId(e.target.value))} className="form-control">
                                <option value="">Select One</option>
                                {program?.programs?.map((v_programs, index) => (
                                    <option key={index} value={v_programs.id}>{v_programs.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Teacher Name:</strong></div>
                        <div className="col-md-5">
                            <select onChange={(e) => setTeacherId(e.target.value)} className="form-control">
                                <option value="">Select One</option>
                                {teacher?.teachers?.map((v_teachers, index) => (
                                    <option key={index} value={v_teachers.id}>{v_teachers.fullName}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Question Name:</strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setQuestionName(e.target.value)}
                                   placeholder="Question Name"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Question Answer:</strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setQuestionAnswer(e.target.value)}
                                   placeholder="Question Answer"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Question Value:</strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setQuestionValue(e.target.value)}
                                   placeholder="Question Value"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Option One:</strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setOptionOne(e.target.value)}
                                   placeholder="Option One"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Option Two:</strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setOptionTwo(e.target.value)}
                                   placeholder="Option Two"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Option Three:</strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setOptionThree(e.target.value)}
                                   placeholder="Option Three"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Option Four:</strong></div>
                        <div className="col-md-5">
                            <input type="text" onChange={(e) => setOptionFour(e.target.value)}
                                   placeholder="Option Four"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"></div>
                        <div className="col-md-5">
                            <input type="submit" onClick={addQuestion}
                                   className="form-control btn btn-outline-success"
                                   value="Save"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-5">
                            <div className="text-center">
                                {msg ?
                                    <div className="alert alert-success alert-dismissable text-center">
                                        <button aria-hidden="true" data-dismiss="alert" className="close"
                                                type="button">&times;</button>
                                        {msg}
                                    </div> : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default AddQuestion;