import React, {Fragment, useEffect, useState} from 'react';
import RestApi from "../../../api/restApi";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function UpdateBasic(props) {
    const [fullName, setFullName] = useState();
    const [mobileNumber, setMobileNumber] = useState();
    const [emergencyContactNumber, setEmergencyContactNumber] = useState();
    const [email, setEmail] = useState();
    const [bloodGroup, setBloodGroup] = useState();
    const [dateOfBirth, setDateOfBirth] = useState();
    const [gender, setGender] = useState();
    const [nationalId, setNationalId] = useState();
    const [passportNumber, setPassportNumber] = useState();
    const [profilePictureUrl, setProfilePictureUrl] = useState();
    const [msg, setMsg] = useState("");
    const {teacher} = props;

    useEffect(() => {
        setEmail(teacher?.email);
        setMobileNumber(teacher?.mobileNumber);
        setEmergencyContactNumber(teacher?.emergencyContactNumber);
        setFullName(teacher?.fullName);
        setBloodGroup(teacher?.teacherDetails.bloodGroup);
        setDateOfBirth(teacher?.teacherDetails.dateOfBirth);
        setGender(teacher?.teacherDetails.gender);
        setNationalId(teacher?.teacherDetails.nationalId);
        setPassportNumber(teacher?.teacherDetails.passport);
        setProfilePictureUrl(teacher?.teacherDetails.profilePictureUrl);
    }, [teacher?.email, teacher?.mobileNumber, teacher?.fullName, teacher?.teacherDetails.bloodGroup, teacher?.teacherDetails.dateOfBirth, teacher?.teacherDetails.gender, teacher?.teacherDetails.nationalId, teacher?.teacherDetails.passport, teacher?.teacherDetails.profilePictureUrl]);

    const updateBasicInfo = () => {
        let id = props.id;
        let staticURL = AppURL + 'admin/teacher';
        let data = {
            email: email,
            mobileNumber: mobileNumber,
            fullName: fullName,
            id: id,
            teacherDetails: {
                bloodGroup: bloodGroup,
                emergencyContactNumber: emergencyContactNumber,
                dateOfBirth: dateOfBirth,
                gender: gender,
                nationalId: nationalId,
                passport: passportNumber,
                profilePictureUrl: profilePictureUrl
            }
        };
        RestApi.putFunction(staticURL, data, token).then(result => {
            if (result.statusCode === 200) {
                setMsg("Basic info updated successfully.");
            }
        });
    }


    return (
        <Fragment>
            <div className="container">
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Teacher Name </strong></div>
                        <div className="col-md-5">
                            <input defaultValue={fullName} onChange={(e) => setFullName(e.target.value)} type="text"
                                   placeholder="Teacher Name"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Mobile Number </strong></div>
                        <div className="col-md-5">
                            <input defaultValue={mobileNumber}
                                   onChange={(e) => setMobileNumber(e.target.value)}
                                   type="text"
                                   placeholder="Mobile Number"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Emergency Contact Number</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={emergencyContactNumber}
                                   onChange={(e) => setEmergencyContactNumber(e.target.value)}
                                   type="text"
                                   placeholder="Emergency Contact Number"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Email </strong></div>
                        <div className="col-md-5">
                            <input defaultValue={email} onChange={(e) => setEmail(e.target.value)}
                                   type="text"
                                   placeholder="Email"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Blood Group </strong></div>
                        <div className="col-md-5">
                            <select value={bloodGroup} className="form-control"
                                    onChange={(e) => setBloodGroup(e.target.value)}>
                                <option value="">Blood Group</option>
                                <option value="O+">O+</option>
                                <option value="O-">O-</option>
                                <option value="A+">A+</option>
                                <option value="A-">A-</option>
                                <option value="B+">B+</option>
                                <option value="B-">B-</option>
                                <option value="AB+">AB+</option>
                                <option value="AB-">AB-</option>
                                <option value="N/A">N/A</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Date of Birth</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={dateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)}
                                   type="date"
                                   placeholder="Date of Birth"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Gender </strong></div>
                        <div className="col-md-5">
                            <select value={gender} className="form-control" onChange={(e) => setGender(e.target.value)}>
                                <option value="">Blood Group</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="N/A">N/A</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>NationalId</strong></div>
                        <div className="col-md-5">
                            <input defaultValue={nationalId}
                                   onChange={(e) => setNationalId(e.target.value)}
                                   type="text"
                                   placeholder="NationalId"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"><strong>Passport Number </strong></div>
                        <div className="col-md-5">
                            <input defaultValue={passportNumber}
                                   onChange={(e) => setPassportNumber(e.target.value)}
                                   type="text"
                                   placeholder="Passport Number"
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4 text-right h6"></div>
                        <div className="col-md-5">
                            <input type="submit" onClick={updateBasicInfo}
                                   className="form-control btn btn-outline-success"
                                   value="Save Change"/>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-5">
                            <div className="text-center">
                                {msg ?
                                    <div className="alert alert-success alert-dismissable text-center">
                                        <button aria-hidden="true" data-dismiss="alert" className="close"
                                                type="button">&times;</button>
                                        {msg}
                                    </div> : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default UpdateBasic;