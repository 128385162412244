import React, {Fragment, useEffect, useState} from 'react';
import {PageHeader} from "antd";
import RestApi from "../../../api/restApi";
import {useParams} from "react-router-dom";
import ReactPaginate from "react-paginate";
import AddCourseEnrolled from "../../CourseEnrolled/AddCourseEnrolled";
import UpdateCourseEnrolled from "../../CourseEnrolled/UpdateCourseEnrolled";

let token = sessionStorage.getItem('authentation');
const AppURL = process.env.REACT_APP_BASE_URL;

function EnrolledStudents(props) {
    const {courseEnrolled} = props;
    const [editCourseEnrolled, setEditCourseEnrolled] = useState([]);
    const [childProgram, setChildProgram] = useState([]);
    const [childCourse, setChildCourse] = useState([]);
    const [childStudent, setChildStudent] = useState([]);
    let {programID} = useParams();
    const [id, setId] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const usersPerPage = 5;
    const pagesVisited = pageNumber * usersPerPage;
    const viewStudent = courseEnrolled?.courseEnrolled?.slice(pagesVisited, pagesVisited + usersPerPage);
    const pageCount = Math.ceil(courseEnrolled?.courseEnrolled?.length / usersPerPage);
    const changePage = ({selected}) => {
        setPageNumber(selected);
    };


    useEffect(() => {
        let staticURL = AppURL + "admin/course";
        RestApi.getFunction(staticURL, token).then((result) => {
            setChildCourse(result);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + "student/api/v1/all";
        RestApi.getFunction(staticURL, token).then((result) => {
            setChildStudent(result);
        });
    }, []);

    useEffect(() => {
        let staticURL = AppURL + "program";
        RestApi.getFunction(staticURL, token).then((result) => {
            setChildProgram(result);
        });
    }, []);

    const displayStudent = viewStudent?.map((v_courseEnrolled, index) => {
        return (
            <tr key={index}>
                <td>{index + 1}</td>
                <td>{v_courseEnrolled?.student?.fullName}</td>
                <td>{v_courseEnrolled?.course?.name}</td>
                <td>{v_courseEnrolled?.dueAmount}</td>
                <td>{v_courseEnrolled?.paidAmount}</td>
                <td>{v_courseEnrolled?.paymentMode}</td>
                <td>{v_courseEnrolled?.paymentStatus}</td>
                <td>{v_courseEnrolled?.program?.name}</td>
                <td>{v_courseEnrolled.isActive ? "True" : "False"}</td>
                <td>
                    <button style={{fontSize: 10}}
                            className="btn btn-outline-success"
                            data-backdrop="static"
                            data-keyboard="false" data-toggle="modal"
                            data-target="#update_enroll_student1"
                            onClick={() => getId(v_courseEnrolled.id)}
                            title="Update Enrolled Student">
                        <i className="fas fa-edit"></i>
                    </button>
                </td>
            </tr>
        );
    });


    const getId = (props) => {
        setId(props);
        let staticURL = AppURL + "admin/course-enrolled/enrolled-details?id=" + props;
        RestApi.getFunction(staticURL, token).then((result) => {
            setEditCourseEnrolled(result.enrolled);
        });
    };

    return (
        <Fragment>
            <div className="form-group">
                <div className="row">
                    <div className="col-md-10">
                        <PageHeader onBack={() => window.history.back()} title="Enrolled Students"
                                    style={{padding: "10px 0px"}}/>
                    </div>
                    <div className="col-md-2">
                        <button
                            type="button"
                            className="btn btn-outline-success"
                            data-backdrop="static"
                            data-keyboard="false"
                            data-toggle="modal"
                            data-target="#add_enroll_student"
                            style={{width: "100%", padding: "5px 0px", marginTop: "7px"}}>
                            <i className="fas fa-plus"></i> Enroll Student
                        </button>
                    </div>
                </div>
            </div>

            <div className="mb-0">
                <div className="details-area">
                    <div className="pt-4">
                        <table className="table table-hover">
                            <thead>
                            <tr>
                                <th>SL NO</th>
                                <th>Student Name</th>
                                <th>Course Name</th>
                                <th>Due Amount</th>
                                <th>Paid Amount</th>
                                <th>Payment Mode</th>
                                <th>Payment Status</th>
                                <th>Program Name</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {displayStudent}
                            </tbody>
                        </table>
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        />
                    </div>
                </div>
            </div>
            <div className="modal fade" id="add_enroll_student">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Add Course Enrolled</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <AddCourseEnrolled checkStatus={1} program={childProgram} course={childCourse}
                                                   student={childStudent}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="update_enroll_student1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="title">Update Enrolled Course</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div id="load_from">
                                <UpdateCourseEnrolled checkStatus={1} id={id} program={childProgram}
                                                      course={childCourse}
                                                      student={childStudent} courseEnrolled={editCourseEnrolled}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default EnrolledStudents;